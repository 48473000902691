.side-navigation {
    // Vars

    // Support

    // Module
    & {
        & {
            pointer-events: none;
            z-index: z-index('side-panel');
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            &::after {
                content: '';
                position: absolute;
                top: 100vh;
                right: 0;
                display: none;
                width: 100vw;
                height: 100vh;
                background: $c--monochrome-white;
            }
        }

        .side-navigation--panel {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding-top: $main-header-height;
            background: $c--monochrome-white;
            overflow: auto;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: $main-header-height;
                background: $c--monochrome-white;
                transition: opacity $trs--base;
            }
        }

        .side-navigation--list {
            display: flex;
            flex-direction: column;
        }

        .side-navigation--link {
            display: flex;
            flex-direction: column;
        }

        .side-navigation--accordion-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    // Facets
    & {
        &.side-navigation__is-open {
            pointer-events: auto;

            &::after {
                display: block;
            }
        }

        .side-navigation--list {
            &.side-navigation--list__root {
                @include mq('>xs') {
                    // actually should be 48px but it doesn't work with consistently with the header grid
                    // I am forced to normalize it here hardcoding the padding values
                    padding: 0 40px;
                }
            }
        }

        .side-navigation--link {
            &.side-navigation--link__main {
                padding: map-get($vertical-spacings, 'small') 0;
                color: $c--monochrome-light-black;

                @include type--h4(false);

                @include has-focus {
                    color: $c--monochrome-med-black;
                }
            }

            &.side-navigation--link__child {
                padding: map-get($vertical-spacings, 'x-small') 0 map-get($vertical-spacings, 'x-small') 24px;
                color: $c--monochrome-med-gray;

                @include has-focus {
                    color: $c--monochrome-black;
                }
            }
        }

        .side-navigation--list {
            &.side-navigation--list__children {
                margin-top: map-get($vertical-spacings, 'x-small') * -0.5;
            }

            &.side-navigation--list__child {
                padding-bottom: map-get($vertical-spacings, 'xxx-small');
            }
        }

        .side-navigation--item {
            @each $name, $palette in $themes {
                &.side-navigation--item__#{$name} {
                    .side-navigation--link {
                        $color: map-get($palette, 'dark');

                        @include has-focus {
                            color: $color;
                        }

                        &.side-navigation--link__active {
                            color: $color;
                        }
                    }
                }
            }
        }
    }

    // States
}
