.collapsible {
    // Vars

    // Support
    @mixin active-state() {
        background: $c--monochrome-gray;
    }

    // Module
    & {
        .collapsible--head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid $c--monochrome-gray;
            background: $c--monochrome-light-gray;
            transition: transition-props($trs--base, background, border);

            @include responsive-vertical-spacing('small') using($margin) {
                padding: $margin 16px;
            }

            @include has-focus {
                @include active-state;
            }
        }

        .collapsible--title.collapsible--title {
            @include type--h6(false);
            color: $c--monochrome-med-black;
        }

        .collapsible--content {
            width: 100%;

            img {
                margin-bottom: 19px;

                @include media('<xl') {
                    margin-bottom: 16px;
                }

                @include media('<sm') {
                    margin-bottom: 8px;
                }
            }

            &.collapsible--content-image-small {
                img {
                    max-width: 251px;
                    width: 100%;
                    object-fit: contain;

                    @include media('<md') {
                        max-width: 50%;
                    }
                }
            }

            &.collapsible--content-image-medium {
                img {
                    max-width: 66%;
                    width: 100%;
                    object-fit: contain;

                    @include media('md') {
                        max-width: 100%;
                    }

                    @include media('<md') {
                        max-width: 100%;
                    }
                }
            }

            &.collapsible--content-image-large {
                img {
                    max-width: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .collapsible--icon-wrapper {
            width: 24px;
            height: 24px;
            margin-left: 16px;

            @include mq('<=lg') {
                margin-left: 12px;
            }
        }

        .collapsible--icon {
            transition: transform $trs--base;

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }

        .collapsible--body {
            @include responsive-vertical-spacing('x-small') using($margin) {
                padding: $margin 18px;
                margin-bottom: $margin + 12px;
                margin-top: $margin;
            }

            transition: transition-props($trs--base, background);
        }
    }

    // Facets

    // States
}
