.lead {
    // Vars

    // Support

    // Module
    & {
        .lead--text {
            @include type--lead;
            color: $c--monochrome-dark-gray;
        }
    }

    // Facets

    // States
}
