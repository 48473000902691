.error-page {
    // Vars
    $arrow-icon-size: 12px;

    // Support

    // Module
    & {
        & {
            @include responsive-vertical-spacing('large') using($spacing) {
                margin-top: $spacing;
            }

            @include responsive-vertical-spacing('x-large') using($spacing) {
                margin-bottom: $spacing;
            }
        }

        .error-page--title {
            @include type--h2;
        }

        .error-page--lead {
            color: $c--monochrome-dark-gray;

            @include type--paragraph01;

            @include responsive-vertical-spacing('large') using($spacing) {
                margin-bottom: $spacing;
            }
        }

        .error-page--list-item {
            @include responsive-vertical-spacing('x-small') using($spacing) {
                margin-bottom: $spacing;
            }
        }

        .error-page--link {
            display: flex;
            align-items: center;

            @include type--text-link;
        }

        .error-page--link-text {
            min-width: 160px;
        }

        .error-page--link-arrow {
            @include animated-arrow($arrow-icon-size);
        }
    }

    // Facets
    & {
        .error-page--link__home {
            color: $c--themes-brand;

            @include has-focus {
                color: $c--themes-brand-dark;
            }
        }
    }

    // States
    .error-page--link {
        @include has-focus {
            .error-page--link-arrow {
                @include animated-arrow-hover($arrow-icon-size);
            }
        }
    }
}

