.back-to-top {
    // Vars

    // Support

    // Module
    & {
        & {
            pointer-events: none;
            z-index: z-index(back-to-top);
            position: fixed;
            bottom: 0;
            width: 100%;
            opacity: 0;
            transition: transition-props($trs--base, transform, opacity);
            transform: translate(-6px, 100%);

            @include responsive-vertical-spacing('medium') using($spacing) {
                bottom: $spacing;
            }
        }

        .back-to-top--button {
            pointer-events: fill;
            box-shadow: $shadow-level-1;
        }
    }

    // Facets
    & {
        &.back-to-top__top.back-to-top__top {
            opacity: 0;
        }

        &.back-to-top__not-top {
            display: block;
        }

        &.back-to-top__pinned {
            transform: translate(-6px, 100%);
            opacity: 0;
        }

        &.back-to-top__unpinned {
            transform: translate(-6px, 0);
            opacity: 1;
        }

        &.back-to-top__bottom {
            @include mq('>md') {
                transform: translate(-6px, -48px);
            }

            @include mq('<=md') {
                display: none;
            }
        }
    }

    // States
}
