.anchor-navigation {
    // Vars

    // Support

    // Module
    & {
        pointer-events: none;
        touch-action: none;

        & {
            z-index: z-index('anchor-navigation');
            position: sticky;
            top: 0;
        }

        .anchor-navigation--container {
            width: 100%;
        }

        .anchor-navigation--nav-container {
            position: relative;
        }

        .anchor-navigation--col {
            &::after {
                content: ' ';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $c--monochrome-light-silver;
            }
        }

        .anchor-navigation--nav {
            pointer-events: auto;
            touch-action: auto;
            width: 100%;
            background: $c--monochrome-white;

            @include style-all-root-background-themes using($color) {
                background: $color;
            }
        }

        .anchor-navigation--link {
            display: inline-block;
            margin-right: 24px;
            color: $c--monochrome-light-black;

            @include type--text-link;

            @include responsive-vertical-spacing('x-small') using($sizing) {
                padding: $sizing 0;
            }
        }

        .anchor-navigation--indicator {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            transition: all 200ms ease-in-out;
        }
    }

    // Facets
    & {
        &.anchor-navigation__is-hidden {
            display: none;
        }

        &.anchor-navigation__is-visible {
            display: inherit;
        }

        .anchor-navigation--link {
            @include has-focus {
                color: $c--monochrome-black;
            }

            @include style-all-root-themes using($color) {
                &.anchor-navigation--link__active {
                    color: $color;
                }
            }
        }

        .anchor-navigation--nav-container {
            &.anchor-navigation--nav-container__hidden {
                pointer-events: none;
                position: relative;
                top: 0;
                visibility: hidden;
                height: 0;
            }
        }

        @include style-all-root-themes using($color) {
            .anchor-navigation--indicator {
                background-color: $color;
            }
        }
    }

    // States
}
