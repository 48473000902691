.cookie-monster {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index(cookie-monster);
        bottom: 0;
        left: 0;
        background: $c--monochrome-white;
        box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
        padding: 16px 0;
        width: 100%;

        @include grid--root;

        @each $value in $grid--wide-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--wide-spacing {
            @include grid--space($value);
        }

        .cookie-monster--title {
            display: none;
        }

        .cookie-monster--content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @include type--paragraph02;

            @include mq('<=xs') {
                flex-wrap: wrap;
            }
        }

        .cookie-monster--text {
            a {
                color: $c--themes-brand;

                @include has-focus {
                    text-decoration: underline;
                }
            }
        }

        .cookie-monster--btn-group {
            @include mq('>xs') {
                flex: 1 0 auto;
                padding-left: 16px;
            }

            @include mq('<=xs') {
                margin-top: map-get($vertical-spacings, 'small');
                flex: 1 1 100%;
            }
        }

        .cookie-monster--btn-wrapper {
            display: none;
            justify-content: flex-end;

            @include mq('<=xs') {
                justify-content: center;
            }


            &:nth-child(2n) {
                display: flex;
            }
        }

        .cookie-monster--btn {
            background: $c--themes-brand;
            color: $c--monochrome-white;

            @include btn--all;
            @include btn--size-small;

            @include has-focus {
                background: $c--themes-brand-dark;
            }
        }
    }

    // Facets

    // States
}
