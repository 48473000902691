.intro-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            @include style-all-root-themes('default') using($color) {
                background: $color;
            }
        }

        .intro-teaser--container {
            z-index: z-index(intro-teaser);
            position: relative;
            justify-content: center;

            @include mq('<=md') {
                justify-content: flex-end;
            }
        }

        .intro-teaser--col {
            @include grid--span((ratio: 6 / 12));
        }

        .intro-teaser--parallax-wrapper {
            position: relative;
            overflow: hidden;
        }

        .intro-teaser--row {
            display: flex;
            align-items: center;
        }

        .intro-teaser--title {
            @include type--h1;
            color: $c--monochrome-white;

            @include mq('<=sm') {
                margin-bottom: 0;
            }
        }

        .intro-teaser--lead {
            @include type--lead;

            color: $c--monochrome-white;

            @include mq('<=sm') {
                display: none;
            }
        }

        .intro-teaser--breadcrumbs {
            display: flex;
            align-items: center;
            color: $c--monochrome-white;
            height: 68px;

            @include mq('<=lg') {
                height: 52px;
            }

            @include mq('<=sm') {
                height: 56px;
            }

            @include type--text-link-small;

            .intro-teaser--breadcrumbs-list-item-link {
                color: $c--monochrome-white;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 596px;

                @include mq('lg') {
                    max-width: 444px;
                }

                @include mq('md') {
                    max-width: 340px;
                }

                @include mq('sm') {
                    max-width: 448px;
                }

                @include mq('xs') {
                    max-width: 214px;
                }

                @include has-focus {
                    text-decoration: underline;
                }

                @include mq('<=md') {
                    display: none;

                    &:last-child {
                        display: block;
                    }
                }
            }

            .intro-teaser--breadcrumbs-list-item-link-disabled {
                @include has-focus {
                    text-decoration: none;
                }
            }

            .intro-teaser--breadcrumbs-list-item {
                display: flex;
                align-items: center;
            }

            .intro-teaser--breadcrumbs-list {
                display: flex;
                align-items: center;
            }

            .intro-teaser--breadcrumbs-icon {
                display: none;
                padding: 0 8px;
                transform: translateY(-1px);
                color: $c--monochrome-white;

                @include mq('>md') {
                    display: inline-block;
                }
            }

            .intro-teaser--breadcrumbs-back-icon {
                display: inline-block;
                padding: 0 8px;
                transform: translateY(-1px);
                color: $c--monochrome-white;

                @include mq('>md') {
                    display: none;
                }
            }
        }

        .intro-teaser--circle {
            border-radius: 50%;
            position: absolute;
            left: 64px;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .intro-teaser--image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
        }

        .intro-teaser--figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;

                @include mq('<=md') {
                    @include style-all-root-themes using($color) {
                        background: rgba($color, 0.5);
                    }
                }
            }
        }

        .intro-teaser--arrow-wrapper {
            position: absolute;
            width: 24px;
            transform: rotate(90deg);
        }

        .intro-teaser--arrow {
            color: $c--monochrome-white;
        }
    }

    // Facets
    & {
        &.intro-teaser__expanded {
            & {
                margin-top: -#{$main-header-height};
            }

            .intro-teaser--arrow-wrapper {
                bottom: 124px;
            }

            .intro-teaser--arrow {
                @include animated-arrow(12px, 6);
            }

            .intro-teaser--container {
                @include mq('<=md') {
                    padding-bottom: 196px;
                }
            }

            .intro-teaser--parallax-wrapper,
            .intro-teaser--container {
                min-height: 600px;
                height: 100vh;
            }

            .intro-teaser--circle {
                width: 580px;
                height: 580px;

                @include mq('xl') {
                    width: 520px;
                    height: 520px;
                }

                @include mq('lg') {
                    width: 440px;
                    height: 440px;
                }

                @include mq('<=md') {
                    display: none;
                }
            }
        }

        &.intro-teaser__small {
            & {
                margin-top: -#{$main-header-height};
            }

            .intro-teaser--arrow-wrapper {
                bottom: 108px;
            }

            .intro-teaser--container {
                @include mq('<=md') {
                    padding-bottom: 152px;
                }
            }

            .intro-teaser--arrow {
                @include animated-arrow(12px, 4);
            }

            .intro-teaser--parallax-wrapper,
            .intro-teaser--container {
                /* the use of fixed height values is due to ie11 support */

                height: 600px;

                @include mq('>xl') {
                    height: 40vw;
                }

                @include mq('xl') {
                    height: 500px;
                }

                @include mq('lg') {
                    height: 480px;
                }

                /* notice the use of min-height on mobile in order to avoid overflow issues */

                @include mq('md') {
                    min-height: 700px;
                }

                @include mq('sm') {
                    min-height: 480px;
                }

                @include mq('<=xs') {
                    min-height: 532px;
                }
            }

            .intro-teaser--circle {
                left: 34px;
                top: 48%;
                width: 400px;
                height: 400px;
                transform: translate(-50%, -50%);

                @include mq('xl') {
                    top: 45%;
                    width: 330px;
                    height: 330px;
                }

                @include mq('lg') {
                    top: 45%;
                    width: 260px;
                    height: 260px;
                }

                @include mq('<=md') {
                    display: none;
                }
            }
        }
    }

    // States
}
