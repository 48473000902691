.one-col {
    // Vars

    // Support

    // Module
    & {
        .one-col--row {
            width: 100%;
        }

        .one-col--main {
            @include grid--span((ratio: 12 / 12));

            & {
                .cms-module:not(.cms-module__chart):not(.cms-module__numbers):not(.cms-module__image) {
                    .cms-module--col {
                        @include grid--span((ratio: 8 / 12, mq: '>md'));
                        @include grid--span((ratio: 12 / 12, mq: '<=md'));
                    }
                }
            }
        }
    }

    @include responsive-vertical-spacing('xx-large') using($margin) {
        margin: {
            top: $margin - 10px;
            bottom: $margin + 20px;
        }
    }

    // Facets

    // States
}
