.overlay-recipe-dialog {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        margin: 0 24px;
        width: 580px;
        max-width: 100%;
        padding: 64px 32px 32px;
        background-color: $c--monochrome-white;

        .overlay-recipe-dialog__close {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            padding: 24px;
            color: $c--themes-brand;

            @include has-focus {
                color: $c--themes-brand-dark;
            }
        }

        .overlay-recipe-dialog--actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 32px;
        }
    }

    // Facets

    // States
}
