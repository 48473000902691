.grid-employees-entry {
    // Vars
    $arrow-icon-size: 12px;

    // Support

    // Module
    & {
        & {
            @include grid--root((row: 'list-row', cols: 'list-item'));
            @include grid--space((gutter: 32px, row: 'list-row', cols: 'list-item', mq: '>xs'));
            @include grid--space((gutter: 16px 0, row: 'list-row', cols: 'list-item', mq: 'xs'));
        }

        .grid-employees-entry--list-row {
            display: flex;
            align-items: center;
            flex-flow: row nowrap;

            @include mq('xs') {
                flex-direction: column;
                align-items: flex-start;
            }

            padding-bottom: map-get($vertical-spacings, 'medium');

            @include mq('xs') {
                padding-bottom: map-get($vertical-spacings, 'x-small');
            }
        }

        .grid-employees-entry--list-col {
            display: flex;
            flex-direction: column;
        }

        .grid-employees-entry--list-item {
            @include mq('xs') {
                padding: 6px 0;

                &:first-of-type {
                    padding-top: 0;
                }

                &:last-of-type {
                    //padding-bottom: 0;
                }
            }
        }

        .grid-employees-entry--image {
            border-radius: 100%;
            width: 100%;

            @supports (object-fit: cover) {
                object-fit: cover;

                @include mq('>xs') {
                    width: 172px;
                    height: 172px;
                }

                @include mq('<=xs') {
                    width: 124px;
                    height: 124px;
                }
            }
        }

        .grid-employees-entry--title {
            @include type--h3(false);
        }

        .grid-employees-entry--lead {
            @include type--paragraph01;
            color: $c--monochrome-med-gray;
            padding-bottom: map-get($vertical-spacings, 'xxx-small');

            @include mq('<=lg') {
                padding-bottom: map-get($vertical-spacings, 'xxx-small') / 2;
            }
        }

        .grid-employees-entry--details {
            @include type--paragraph01;
            color: $c--themes-brand;
            margin-bottom: 2px;

            @include mq('>lg') {
                margin-bottom: 4px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @include has-focus {
                color: $c--themes-brand-dark;
            }
        }

        .grid-employees-entry--details-wrapper {
            display: flex;
            flex-direction: column;
        }

        .grid-employees-entry--link {
            color: $c--themes-brand;
            display: flex;
            align-items: center;

            @include has-focus {
                color: $c--themes-brand-dark;
            }

            @include responsive-vertical-spacing('x-small') using($spacing) {
                margin-top: $spacing;
            }
        }

        .grid-employees-entry--protrait-label {
            margin-right: 10px;
        }

        .grid-employees-entry--link-arrow {
            @include animated-arrow($arrow-icon-size);
            margin-left: -8px; // small hack to render the arrow in a position it was never meant to be
        }
    }

    // Facets

    // States
    .grid-employees-entry--link {
        @include has-focus {
            .grid-employees-entry--link-arrow {
                @include animated-arrow-hover($arrow-icon-size);
            }
        }
    }
}
