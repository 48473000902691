.wizard-drop-zone {
    //
}

.wizard-drop-zone--input-container {
    height: 80px;
}

.wizard-drop-zone--input {
    cursor: pointer;
    transition: $trs--common-props;

    // stylelint-disable declaration-no-important
    &:hover, &:active {
        border-color: $c--monochrome-black !important;
        border-style: solid !important;

        ~ .wizard-drop-zone--empty-label {
            color: $c--monochrome-black !important;
        }
    }
    // stylelint-enable declaration-no-important
}

.wizard-drop-zone--empty-label {
    pointer-events: none;
    display: flex;
    align-items: center;
}
