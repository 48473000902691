.chart-legend {
    // Vars

    // Support
    &.chart-legend--donut {
        .chart-legend--item-description {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(0, 280px));
            justify-items: start;
            align-items: flex-start;
            gap: 5px;
            margin-top: 32px;

            @include mq('<=lg') {
                grid-template-columns: 1fr;
            }

            .chart-legend--item-description-wrapper {
                width: auto;
                justify-self: start;
            }

            .chart-legend--item-description-color {
                display: inline-block;
                height: 10px;
                width: 10px;
                border-radius: 50%;
            }

            .chart-legend--item-description-text {
                display: inline-block;
                font-size: 13px;
                margin-left: 10px;
            }
        }
    }

    &.chart-legend--bar-vertical {
        width: 100%;

        & {
            .chart-legend--item {
                line-height: 1.41;
                font-size: 12px;
                letter-spacing: 0.01rem;
                margin-bottom: 5px;
            }

            .chart-legend--text {
                display: inline-block;
                font-size: 12px;
                color: $c--monochrome-dark-gray;
            }

            .chart-legend--text:first-child {
                color: $c--monochrome-black;
            }

            .chart-legend--text:last-child::before {
                content: '=';
            }
        }
    }

    &.chart-legend--bar-vertical-stacked {
        width: 100%;

        & {
            .chart-legend--item-description {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                .chart-legend--item-description-wrapper:not(:first-child) {
                    margin-left: 10px;
                }

                .chart-legend--item-description-color {
                    display: inline-block;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                }

                .chart-legend--item-description-text {
                    display: inline-block;
                    font-size: 13px;
                    margin-left: 10px;
                }
            }

            .chart-legend--item {
                line-height: 1.41;
                font-size: 12px;
                letter-spacing: 0.01rem;
                margin-bottom: 5px;
            }

            .chart-legend--text {
                display: inline-block;
                font-size: 12px;
                color: $c--monochrome-dark-gray;
            }

            .chart-legend--text:first-child {
                color: $c--monochrome-black;
            }

            .chart-legend--text:last-child::before {
                content: '=';
            }
        }
    }

    &.chart-legend--bar-horizontal-stacked {
        width: 100%;
        margin-top: -20px;

        & {
            .chart-legend--item-description {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                .chart-legend--item-description-wrapper:not(:first-child) {
                    margin-left: 10px;
                }

                .chart-legend--item-description-color {
                    display: inline-block;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                }

                .chart-legend--item-description-text {
                    display: inline-block;
                    font-size: 13px;
                    margin-left: 10px;
                }
            }
        }
    }
    // Module

    // Facets

    // States
}
