/* stylelint-disable plugin/selector-bem-pattern */
.parallax-container {
    // Vars

    // Support

    // Module
    & {
        [data-parallax] {
            opacity: 0;
            visibility: hidden;
            transition: opacity 500ms ease-in;
        }
    }

    // Facets
    & {
        &.parallax-container__is-ready {
            [data-parallax] {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    // States
}
