.grid-employees {
    // Vars

    // Support

    // Module
    & {
        .grid-employees--title {
            @include type--h2(false);

            @include responsive-vertical-spacing('large') using($spacing) {
                margin-bottom: $spacing;
            }
        }
    }

    // Facets

    // States
}
