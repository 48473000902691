// this third party library ist not BEM

// stylelint-disable plugin/selector-bem-pattern

$hamburgers-padding-x: 20px;
$hamburgers-padding-y: 16px;
$hamburger-layer-spacing: 3px;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 1px;
$hamburger-hover-transition-duration: 200ms;
$hamburger-hover-opacity: 1;
$hamburger-layer-color: $c--monochrome-white;
$hamburger-types: (spin);

@import '../../../../../../../../node_modules/hamburgers/_sass/hamburgers/hamburgers';

.hamburger {
    // Vars

    // Support

    // Module
    & {
        .hamburger-inner {
            background: rgba($c--monochrome-white, 0);
            transition: transition-props($hamburger-hover-transition-duration $trs--base-fn, background, transform);
        }

        .hamburger-box {
            padding: 0 16px;

            @include mq('<=sm') {
                padding: 16px;
            }
        }
    }

    // Facets
    & {
        &.hamburger__is-active {
            .hamburger-inner {
                background: $c--monochrome-black;

                &::before,
                &::after {
                    background: $c--monochrome-black;
                }
            }
        }

        &.hamburger__dark {
            &.is-active {
                .hamburger-inner {
                    background: $c--monochrome-black;
                }
            }

            .hamburger-inner {
                &::before,
                &::after {
                    background: $c--monochrome-black;
                }
            }
        }
    }

    // States
}
