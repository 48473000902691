.teaser-subscription {
    // Vars

    // Support

    // Module
    & {
        .teaser-subscription--text {
            @include type--paragraph02;
            color: $c--monochrome-med-black;
        }

        .teaser-subscription--list {
            margin-top: map-get($vertical-spacings, 'medium');
        }
    }

    // Facets

    // States
}
