/// Field styles
///
@mixin ui-form-field--primary-checkbox {
    // Vars
    $sz--checkbox-icon: 16px;
    $sz--checkbox-border-radius: $sz--border-radius-base;

    // Module
    & {
        .ui-form-field--input {
            ~ .ui-form-field--label-wrap {
                .ui-form-field--box {
                    &,
                    &::after,
                    &::before {
                        border-radius: $sz--checkbox-border-radius;
                    }
                }

                .ui-form-field--box-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: ($sz--checkbox-icon / -2);
                    margin-top: ($sz--checkbox-icon / -2);
                    width: $sz--checkbox-icon;
                    height: $sz--checkbox-icon;
                }
            }
        }

        .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
            border-radius: 50%;
        }
    }

    // States
    &.ui-form-field__wizard {
        .ui-form-field--label-wrap {
            align-items: center;
        }

        .ui-form-field--label {
            transform: none;
        }
    }
}
