.contact-map {
    // Vars

    // Support

    // Module
    & {
        .contact-map--wrap {
            background: $c--monochrome-light-gray;
            padding-bottom: 24px;

            @include mq('>md') {
                padding: 32px;
            }
        }

        .contact-map--lead {
            color: $c--monochrome-dark-gray;

            @include type--lead;
        }

        .contact-map--row {
            ~ .contact-map--row {
                @include responsive-vertical-spacing('medium') using($spacing) {
                    margin: {
                        top: $spacing;
                    };
                }
            }
        }

        .contact-map--iframe-wrap {
            position: relative;
            width: 100%;
            height: auto;
            padding-bottom: 9 / 16 * 100%; // 16 / 9

            @include mq('xs') {
                padding-bottom: 1 / 1 * 100%;
            }
        }

        .contact-map--iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // Facets
    & {
        .contact-map--row {
            &.contact-map--row__intro {
                @include responsive-vertical-spacing('large') using($spacing) {
                    margin: {
                        bottom: $spacing;
                    };
                }
            }

            &.contact-map--row__text {
                @include mq('<=md') {
                    padding: {
                        left: 24px;
                        right: 24px;
                    }
                }
            }
        }
    }

    // States
}
