.grid-offer {
    // Vars
    $arrow-size: 12px;

    // Support

    // Module
    & {
        & {
            // item grid
            @include grid--root((row: 'list', cols: 'item'));

            @each $value in $grid--base-spacing {
                $gutter: map-get($value, 'gutter');
                $mq: map-get($value, 'mq');

                @include grid--space((gutter: ($gutter $gutter), row: 'list', cols: 'item', mq: $mq));
            }

            // tags grid
            @include grid--root((row: 'tags-list', cols: 'tags-item'));
            @include grid--space((gutter: (8px, 8px), row: 'tags-list', cols: 'tags-item'));
        }

        .grid-offer--item {
            overflow: hidden;
        }

        .grid-offer--title {
            @include type--h2;
            color: $c--monochrome-black;
        }

        .grid-offer--title-wrap {
            @include responsive-vertical-spacing('x-small') using($margin) {
                padding-bottom: $margin;
            }
        }

        .grid-offer--link {
            $responsive-grid-properties: (
                'xs': (min-height: 362px, padding: 24px),
                'sm': (min-height: 286px, padding: 24px),
                'md': (min-height: 340px, padding: 24px),
                'lg': (min-height: 362px, padding: 24px),
                'xl': (min-height: 392px, padding: 32px),
                'xxl': (min-height: 460px, padding: 40px),
            );

            @each $mq, $props in $responsive-grid-properties {
                @include mq($mq) {
                    min-height: map-get($props, 'min-height');
                    padding: map-get($props, 'padding');
                }
            }

            display: flex;
            flex-wrap: wrap;
            height: 100%;
            padding: 24px;
            min-height: 228px;
            background: $c--monochrome-white;

            transition: transition-props($trs--base, background, color, border);

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }

        .grid-offer--link-module {
            $responsive-grid-properties: (
                'xs': (min-height: 240px, padding: 24px),
                'sm': (min-height: 240px, padding: 24px),
                'md': (min-height: 280px, padding: 24px),
                'lg': (min-height: 240px, padding: 24px),
                'xl': (min-height: 280px, padding: 32px),
                'xxl': (min-height: 300px, padding: 40px),
            );

            @each $mq, $props in $responsive-grid-properties {
                @include mq($mq) {
                    min-height: map-get($props, 'min-height');
                    padding: map-get($props, 'padding');
                }
            }

            display: flex;
            flex-wrap: wrap;
            height: 100%;
            padding: 24px;
            min-height: 228px;
            background: $c--monochrome-light-gray;
            border: 1px solid $c--monochrome-gray;

            transition: transition-props($trs--base, background, color, border);

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }

        .grid-offer--item-title,
        .grid-offer--item-subtitle {
            transition: color $trs--base;
        }

        .grid-offer--item-title {
            flex: 0 0 100%;

            @include type--h3(false);
        }

        .grid-offer--item-subtitle {
            flex: 0 0 100%;

            @include type--h3(false);

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }

        .grid-offer--item {
            @include grid--span((ratio: 4 / 8, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .grid-offer--arrow-wrapper {
            display: flex;
            align-items: center;
            flex-basis: 70px;
            flex-shrink: 0;
            height: 19px;
            margin-bottom: 4px;
        }

        .grid-offer--arrow {
            @include animated-arrow($arrow-size, 4);
            margin-left: 0;
        }

        .grid-offer--footer {
            display: flex;
            flex: 0 0 100%;
            align-items: flex-end;
            justify-content: flex-end;

            @include responsive-vertical-spacing('large') using($sizing) {
                padding-top: $sizing;
            }
        }

        .grid-offer--tags {
            flex: 1 1 100%;
        }

        .grid-offer--dark-text {
            color: $c--monochrome-black;
            transition: color $trs--base;
        }

        .grid-offer--tags-tag {
            padding: 4px 12px;
            border-radius: 16px;
            transition: background $trs--base;

            @include style-all-root-themes using($color) {
                background: $color;
            }
        }

        .grid-offer--tags-tag-text {
            color: $c--monochrome-white;
            transition: color $trs--base;

            @include type--paragraph02;
        }
    }

    // Facets
    & {
        &.grid-offer__module {
            .grid-offer--link {
                background: $c--monochrome-light-gray;
                border: 1px solid $c--monochrome-gray;

                @include style-all-root-themes using($color) {
                    color: $color;
                }
            }
        }

        .grid-offer--dark-text {
            &.grid-offer--dark-text__gray {
                color: $c--monochrome-dark-gray;
            }
        }

        // needed to override the global rules
        .grid-offer--link.grid-offer--link {
            @each $theme, $color in $themes-default {
                &.grid-offer--link__#{$theme} {
                    color: $color;
                }
            }
        }
    }

    // States
    .grid-offer--link,
    .grid-offer--link-module {
        @include has-focus {
            @include style-all-root-themes using($color) {
                color: $c--monochrome-white;
                background: $color;
                border-color: $color;

                .grid-offer--tags-tag {
                    background: $c--monochrome-white;
                }

                .grid-offer--tags-tag-text {
                    color: $color;
                }
            }

            .grid-offer--item-subtitle {
                color: $c--monochrome-gray;
            }

            .grid-offer--dark-text {
                color: $c--monochrome-white;
            }

            .grid-offer--arrow {
                @include animated-arrow-hover(15px);
            }
        }

        &.grid-offer--link {
            @each $theme, $color in $themes-default {
                &.grid-offer--link__#{$theme} {
                    .grid-offer--tags-tag {
                        background: $color;
                    }

                    @include has-focus {
                        background: $color;
                        border-color: $color;

                        .grid-offer--tags-tag-text {
                            color: $color;
                        }
                    }
                }
            }
        }
    }
}
