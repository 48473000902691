.big-footer {
    // Vars
    $lateral-row-padding-by-mq: (
        '>sm': 53px,
        '<=sm': 0,
    );

    // Support

    // Module
    & {
        & {
            color: $c--monochrome-med-gray;
            border-bottom: 1px solid $c--monochrome-gray;
            border-top: 1px solid $c--monochrome-gray;

            @include type--text-caption-big;

            @include responsive-vertical-spacing('medium') using($sizing) {
                padding: $sizing 0;
            }
        }

        .big-footer--col {
            @include grid--span((ratio: 3 / 12, mq:'>lg'));
            @include grid--span((ratio: 6 / 12, mq:('lg', 'md')));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            @include mq('>lg') {
                margin-top: map-get($vertical-spacings, 'small');
            }

            @include mq('<=lg') {
                margin-top: map-get($vertical-spacings, 'large') / 2;
            }
        }

        .big-footer--text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 8px 0;

            @include mq('>sm') {
                height: 100%;
            }
        }

        .big-footer--link {
            color: $c--monochrome-dark-gray;

            @include has-focus {
                color: $c--monochrome-black;
            }
        }

        .big-footer--row {
            @each $mq, $padding in $lateral-row-padding-by-mq {
                @include mq($mq) {
                    padding-left: $padding;
                }
            }
        }
    }

    // Facets
    & {
        .big-footer--link {
            &.big-footer--link__menu {
                display: inline-block;
                padding: map-get($vertical-spacings, 'xxx-small') 0;
            }

            &.big-footer--link__menu-in-text {
                @include mq('<=sm') {
                    display: inline-block;
                    padding-top: map-get($vertical-spacings, 'x-small');
                }
            }
        }
    }

    // States
}
