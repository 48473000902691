.main-navigation {
    // Vars

    // Support

    // Module
    & {
        & {
            & {
                z-index: z-index(main-navigation);
                position: relative;

                @include grid--root;
                @include grid--space((gutter: 56px));
            }

            .main-navigation--row {
                justify-content: flex-end;
            }

            .main-navigation--trigger {
                position: relative;
                cursor: pointer;
                list-style-type: none;
                appearance: none;
                padding: 4px 0;

                @include type--h5(false);

                &::-webkit-details-marker {
                    display: none;
                }
            }

            .main-navigation--item {
                display: inline-block;
                width: 100%;
            }

            .main-navigation--link {
                display: inline-block;
                padding: 8px 28px;
            }

            .main-navigation--line {
                position: absolute;
                left: 0;
                width: 0;
                will-change: width, transform;
                height: 2px;
                background: rgba($c--monochrome-black, 0.6);
                margin: 0;
                opacity: 0;

                transition: transition-props($trs--base, opacity);
            }

            .main-navigation--list,
            .main-navigation--panel {
                z-index: z-index(menu-dropdown);
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 264px;
            }

            .main-navigation--list {
                max-width: 240px;
                padding: 16px 0;
            }

            .main-navigation--panel {
                height: 0;
                margin-top: 2px;
                background: $c--monochrome-white;
                overflow: hidden;
                box-shadow: $shadow-level-2;
            }

            .main-navigation--image {
                position: absolute;
                right: 100%;
                top: 2px;
                height: 100%;
                max-width: 278px;
                object-fit: cover;
                opacity: 0;
                transition: $trs--base opacity;
                transition-delay: 200ms;
            }
        }
    }

    // Facets
    & {
        .main-navigation--list {
            transition: opacity $trs--base 100ms;

            &.main-navigation--list__fallback {
                z-index: z-index(menu-dropdown);
                position: absolute;
                width: 100%;
                background: $c--monochrome-white;
            }
        }

        .main-navigation--panel {
            &.main-navigation--panel__active {
                transition: transition-props(110ms ease, width, height, transform, opacity, box-shadow);
            }

            &.main-navigation--panel__hidden {
                transition: transition-props(200ms ease, opacity);
            }
        }

        .main-navigation--line {
            &.main-navigation--line__has-translate-animation {
                transition: transition-props($trs--base, width, transform);
            }
        }

        &.main-navigation__dark {
            .main-navigation--link,
            .main-navigation--trigger {
                color: $c--monochrome-med-black;
            }

            .main-navigation--link {
                @include has-focus {
                    color: $c--monochrome-black;
                }
            }
        }

        &.main-navigation__light {
            .main-navigation--link__parent {
                @include style-all-root-themes('contrast') using($color) {
                    color: $color;
                }

                &.main-navigation--link {
                    @include has-focus {
                        @include style-all-root-themes('contrast') using($color) {
                            color: darken($color, 10%);
                        }
                    }
                }
            }

            .main-navigation--line {
                @include style-all-root-themes('contrast') using($color) {
                    background: rgba($color, 0.6);
                }
            }
        }

        .main-navigation--list {
            &.main-navigation--list__hidden {
                visibility: hidden;
                opacity: 0;
            }

            &.main-navigation--list__visible {
                visibility: visible;
                opacity: 1;
            }
        }

        .main-navigation--link {
            &.main-navigation--link__child {
                color: $c--monochrome-dark-gray;
                padding: 16px 24px;

                @include ff--base-regular;
            }
        }

        .main-navigation--item {
            @each $name, $palette in $themes {
                &.main-navigation--item__#{$name} {
                    .main-navigation--link {
                        @include has-focus {
                            color: map-get($palette, 'default');
                        }

                        &.main-navigation--link__active {
                            color: map-get($palette, 'default');
                        }
                    }
                }
            }
        }
    }

    // States

    & {
        .main-navigation--item {
            @include has-focus {
                .main-navigation--image {
                    opacity: 1;
                    z-index: 1;
                    transition-delay: 0ms;
                }
            }
        }
    }
}
