/// Font weights used throughout the project
///
$fw--light: 300;
$fw--regular: 400;
$fw--medium: 500;
$fw--bold: 700;

/// The project's font families
///
$ff--base: 'SuisseIntl';
$ff--serif: 'Argesta';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
$ff--serif-stack: $ff--serif, 'Times New Roman', Times, serif;
// stylelint-enable value-keyword-case
