.cms-module {
    // Vars

    // Support

    // Module
    & {
        @include responsive-vertical-spacing('medium') using($spacing) {
            padding: {
                top: $spacing;
                bottom: $spacing;
            }
        }

        &:last-child {
            @include responsive-vertical-spacing('medium') using($spacing) {
                padding-bottom: 4 * $spacing;
            }
        }

        .cms-module--col {
            @include grid--span((ratio: 1));
        }
    }

    // Facets
    & {
        &.cms-module__debugger {
            &::before, &::after {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }

            @include responsive-vertical-spacing('large') using($spacing) {
                &::before {
                    content: 'XL Module spacing top';
                    top: 0;
                    height: $spacing;
                    background-color: #f4df70;
                }

                &::after {
                    content: 'XL Module spacing bottom';
                    bottom: 0;
                    height: $spacing;
                    background-color: #f47070;
                }
            }
        }

        &.cms-module__quotes {
            .cms-module--col {
                margin: 0 auto;

                @include grid--span((ratio: 6 / 8, mq: '>md'));
                @include grid--span((ratio: 6 / 6, mq: '<=md'));
            }
        }
    }

    // States
}
