.image {
    // Vars

    // Support

    // Module
    & {
        .image--img {
            display: block;
            width: 100%;
            height: auto;
        }

        .image--title {
            @include type--h4;
            color: $c--monochrome-black;
        }

        .image--figcaption {
            color: $c--monochrome-dark-gray;

            @include type--paragraph02;

            @include responsive-vertical-spacing('x-small') using($margin) {
                padding-top: $margin;
            }
        }

        .image--author {
            display: inline-block;
            width: 100%;

            @include type--text-caption-small;
        }
    }

    // Facets

    // States
}
