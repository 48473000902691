.wizard-form {
    // Vars
    $grid--fieldset-items: (row: ('fieldset-row'), cols: ('fieldset'));
    $grid--field-items: (row: ('field-row'), cols: ('field'));
    $grid--radio-items: (row: ('radio-group'), cols: ('radio'));

    $sz--form-fieldset-gutter: (80px 24px);
    $sz--form-fieldset-gutter-small: (40px 24px);
    $sz--form-field-gutter: (24px 16px);
    $sz--form-field-gutter-small: (16px 16px);

    // Support

    // Module
    & {

        // Fieldset spacing
        & {
            @include grid--root($grid--fieldset-items);
            @include grid--space(map-merge($grid--fieldset-items, (
                gutter: $sz--form-fieldset-gutter,
            )));

            .wizard-form--fieldset {
                @include grid--span((ratio: 1 / 1));
            }
        }

        // Field spacing
        & {
            @include grid--root($grid--field-items);

            @include mq('>lg') {
                @include grid--space(map-merge($grid--field-items, (
                    gutter: $sz--form-field-gutter,
                )));
            }

            @include mq('<=lg') {
                @include grid--space(map-merge($grid--field-items, (
                    gutter: $sz--form-field-gutter-small,
                )));
            }

            .wizard-form--field {
                @include grid--span((ratio: 1 / 1));
                align-self: flex-start;

                &.wizard-form--field__25p {
                    @include grid--span((ratio: 1 / 4, mq: '>xs'));
                }

                &.wizard-form--field__50p {
                    @include grid--span((ratio: 1 / 2, mq: '>xs'));
                }

                &.wizard-form--field__75p {
                    @include grid--span((ratio: 3 / 4, mq: '>xs'));
                }

                .wizard-form--field__text {
                    color: $c--label-text;
                }
            }
        }

        // Fieldset radio spacing
        & {
            @include grid--root($grid--radio-items);
            @include grid--space(map-merge($grid--radio-items, (
                gutter: (4px 24px),
            )));

            .wizard-form--radio-group.wizard-form--radio-group__rows {
                flex-direction: column;
            }

            .wizard-form--radio {
                min-height: 38px;
            }
        }


        .wizard-form--subtitle {
            @include type--h4(false);
            color: $c--monochrome-black;
        }

        .wizard-form--lead {
            color: $c--monochrome-dark-gray;
        }

        .wizard-form--spacing {
            display: block;

            &.wizard-form--spacing__small {
                @include responsive-vertical-spacing('small') using($sizing) {
                    margin-bottom: $sizing;
                }
            }

            &.wizard-form--spacing__xx-small {
                @include responsive-vertical-spacing('xx-small') using($sizing) {
                    margin-bottom: $sizing;
                }
            }
        }

        .wizard-form--caption {
            position: relative;
            display: block;
            margin-top: 4px;
            color: $c--monochrome-light-black;
            font-size: 1.2rem;
            line-height: 1.3;
        }

        // Authorization detail hack to display another checkbox
        .wizard-form--authorization-detail, .wizard-form--previous-education-other {
            display: none;
        }

        // stylelint-disable-next-line plugin/selector-bem-pattern
        .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--authorization-detail, .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--previous-education-other {
            display: block;

            @include responsive-vertical-spacing('xx-small') using($sizing) {
                margin-top: $sizing;
            }
        }
        // stylelint-disable-next-line plugin/selector-bem-pattern
        .ui-form-field--input:checked ~ .ui-form-field--label-wrap.ui-form-field--label-wrap {
            max-height: none;
            line-height: 1.5;
        }
    }

    // Facets
    & {
        .wizard-form--field {
            &.wizard-form--field__subtitle {
                padding-bottom: 0;
            }
        }

        // stylelint-disable plugin/selector-bem-pattern
        .form-submit-recaptcha {
            padding-top: 0;
        }

        .wizard-form--global-messages {
            margin-top: 24px;
            padding: 16px 16px 20px;
            background: rgba(255, 12, 62, 0.1);
        }
    }

    // States
}
