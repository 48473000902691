.teaser-contact {
    // Vars

    // Support

    // Module
    & {
        .teaser-contact--text {
            @include type--paragraph02;
            color: $c--monochrome-med-black;

            @include responsive-vertical-spacing('large') using($spacing) {
                padding: {
                    bottom: $spacing;
                }
            }
        }

        .teaser-contact--figure {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .teaser-contact--figcaption {
            @include type--paragraph02;
            color: $c--monochrome-med-black;
        }

        .teaser-contact--image {
            display: block;
            object-fit: cover;
            border-radius: 100%;
            height: 160px;
            width: 160px;

            @include responsive-vertical-spacing('x-small') using($spacing) {
                margin: {
                    bottom: $spacing;
                }
            }
        }

        .teaser-contact--list {
            margin-top: map-get($vertical-spacings, 'medium');
        }

        .teaser-contact--list-short {
            margin-top: 0;
        }
    }

    // Facets

    // States
}
