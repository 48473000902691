.wizard-course-select {
    width: 100%;

    // Module
    & {
        .wizard-course-select--precourses {
            margin-bottom: 10px;
        }

        .wizard-course-select--precourse-title {
            margin-bottom: 10px;
        }
    }
}
