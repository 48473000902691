&.grid__base {
    // Vars

    // Support

    // Module
    & {
        @include grid--root;

        @each $value in $grid--base-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--base-spacing {
            @include grid--space($value);
        }

        .grid--container.grid--container__fluid {
            max-width: none;
        }
    }

    // Facets

    // States
}
