/// Medium
///
@mixin btn--size-medium {
    padding: 14px 24px;
    font-size: 1.7rem;
    line-height: 1.41;
}

/// Small
///
@mixin btn--size-small {
    padding: 6px 16px;
    font-size: 1.7rem;
    line-height: 1.41;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium {
    padding: 14px;
}

/// Small bubble
///
@mixin btn--size-bubble-small {
    padding: 4px;
}
