$themes-root-el: 'html';

@function theme-name-to-css-selector($theme) {
    @return '[data-juventus-theme="#{$theme}"]';
}

@function background-theme-name-to-css-selector($theme) {
    @return '[data-background-theme="#{$theme}"]';
}

/**
 * Allows the styling of a component according to root theme class selector
 * please check also https://sass-lang.com/documentation/at-rules/mixin#passing-arguments-to-content-blocks
 */
@mixin style-all-root-themes($facet: 'default') {
    @content(map-deep-get($themes, 'brand', $facet));

    @each $name, $palette in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            @content(map-get($palette, $facet));
        }
    }
}

@mixin style-all-root-background-themes() {
    @each $name, $color in $background-colors-themes {
        @include at-root($themes-root-el, background-theme-name-to-css-selector($name)) {
            @content($color);
        }
    }
}
