/// Field styles
///
@mixin ui-form-field--primary--floating-label($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--border-width) {
    // Vars
    $fz--form-field: 1.6rem;
    $lh--form-field: 1.2;

    // Support

    // Module
    & {
        transform: rotateZ(1turn); // Prevent jitter when animating floating-label

        .ui-form-field--input,
        .ui-form-field--floating-label {
            padding-top: $sz--padding-top;
            padding-bottom: $sz--padding-bottom;
        }

        .ui-form-field--floating-label {
            @include text-ellipsis;
            pointer-events: none;
            position: absolute;
            font-size: $fz--form-field;
            line-height: $lh--form-field;
            top: 0;
            left: $sz--padding-x + $sz--border-width;
            visibility: visible;
            backface-visibility: hidden;
            transform-origin: 0 50%;
            transition: transform $trs--base, color $trs--base;
        }

        .ui-form-field--title-label {
            @include hide-visually;
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--primary--floating-label--base;
        }

        // Disabled
        @include ui-form-field--apply-states(('disabled': true, 'focus': default)) {
            @include ui-form-field--primary--floating-label--disabled;
        }

        // Error
        @include ui-form-field--apply-states(('error': true, 'focus': default)) {
            @include ui-form-field--primary--floating-label--error;
        }

        // Required
        @include ui-form-field--apply-states(('required': true)) {
            @include ui-form-field--primary--floating-label--required;
        }

        // Optional
        @include ui-form-field--apply-states(('required': false)) {
            @include ui-form-field--primary--floating-label--optional;
        }

        // Floating
        @include ui-form-field--apply-states(('floating': true)) {
            @include ui-form-field--primary--floating-label--floating;
        }
    }
}

/// Base
///
@mixin ui-form-field--primary--floating-label--base {
    .ui-form-field--floating-label {
        color: $c--label-text;
    }
}

/// Disabled
///
@mixin ui-form-field--primary--floating-label--disabled {
    .ui-form-field--floating-label {
        color: $c--monochrome-silver;
    }
}

/// Error
///
@mixin ui-form-field--primary--floating-label--error {
    .ui-form-field--floating-label {
        color: $c--signal-error;
    }
}

/// Required
///
@mixin ui-form-field--primary--floating-label--required {
    .ui-form-field--floating-label {
        @include ui-form-field--is-required;
    }
}

/// Optional
///
@mixin ui-form-field--primary--floating-label--optional {
    .ui-form-field--floating-label {
        @include ui-form-field--is-optional;
    }
}

/// Floating
///
@mixin ui-form-field--primary--floating-label--floating {
    .ui-form-field--floating-label {
        transform: translate(0, -33%) scale(0.675);
    }
}
