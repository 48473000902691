.faq {
    // Vars

    // Support

    // Module
    & {
        .faq--item {
            ~ .faq--item {
                @include responsive-vertical-spacing('xxx-small') using($margin) {
                    padding: $margin 0 0;
                }
            }
        }
    }

    // Facets

    // States
}
