////
/// Content types (p)
////

@mixin type--paragraph01 {
    font-size: 1.7rem;
    line-height: 1.41;

    @include mq('<=lg') {
        font-size: 1.6rem;
        line-height: 1.375;
    }

    @include ff--base-regular;
}

@mixin type--paragraph02 {
    font-size: 1.5rem;
    line-height: 1.4;

    @include mq('<=lg') {
        font-size: 1.4rem;
        line-height: 1.42;
    }

    @include ff--base-regular;
}

@mixin type--lead {
    font-size: 2.2rem;
    line-height: 1.31;

    @include mq('<=lg') {
        font-size: 2rem;
        line-height: 1.35;
    }

    @include ff--base-regular;
}

@mixin type--text-link {
    font-size: 1.7rem;

    @include ff--base-medium;
}

@mixin type--text-link-small {
    font-size: 1.5rem;

    @include ff--base-medium;
}

@mixin type--text-label {
    font-size: 1.7rem;
    margin-bottom: map-get($vertical-spacings, 'xxx-small');

    @include ff--base-regular;
}

@mixin type--text-caption-big {
    font-size: 1.5rem;

    @include ff--base-regular;
}

@mixin type--text-caption-small {
    font-size: 1.2rem;

    @include ff--base-regular;
}

@mixin type--quotes {
    font-size: 3.2rem;
    line-height: 1.12;

    @include mq('<=lg') {
        font-size: 2.8rem;
        line-height: 1.14;
    }

    @include ff--base-quotes;
}
