.two-cols {
    // Vars

    // Support

    // Module
    & {
        .two-cols--row {
            justify-content: space-between;
        }

        .two-cols--main {
            @include grid--span((ratio: 7 / 12, mq: '>xl'));
            @include grid--span((ratio: 8 / 12, mq: 'xl'));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 7 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .two-cols--sidebar {
            @include grid--span((ratio: 3 / 12, mq: '>lg'));
            @include grid--span((ratio: 4 / 12, mq: 'lg'));
            @include grid--span((ratio: 5 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            @include mq('<=sm') {
                order: -1;
            }
        }
    }

    // Facets

    // States
}
