.teaser-news {
    // Vars

    // Support

    // Module
    & {
        .teaser-news--image {
            display: block;
            width: 100%;

            @include responsive-vertical-spacing('small') using($spacing) {
                padding: {
                    bottom: $spacing;
                }
            }
        }

        .teaser-news--pubdate {
            color: $c--monochrome-med-black;

            @include type--paragraph02;
        }

        .teaser-news--text {
            color: $c--monochrome-med-gray;
        }

        .teaser-news--cta {
            margin-top: map-get($vertical-spacings, 'medium');
        }
    }

    // Facets

    // States
}
