.picture-element {
    // Vars

    // Support

    // Module
    & {
        transition: filter $trs--base;
    }

    // Facets
    & {
        &.picture-element__is-blurry {
            filter: blur(8px);

            // fix odd iOS issue with the blur effect
            -webkit-transform: translate3d(0, 0, 0); // stylelint-disable-line

            @include at-root('.no-js') {
                filter: none;
            }
        }
    }

    // States
}
