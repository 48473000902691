/// Iterate through a states config object to apply a given style to the compiled selectors
/// @param {Map} $states - A state definition object
///
@mixin ui-form-field--apply-states($states: ()) {
    $keys: map-keys($states);
    $has-keys: length($keys) > 0;

    @if ($has-keys) {
        $key: nth($keys, 1);
        $value: map-get($states, $key);
        $next: map-remove($states, $key);

        @include ui-form-field--apply-state($key, $value) {
            @include ui-form-field--apply-states($next) {
                @content;
            }
        }
    } @else {
        @content;
    }
}

/// Iterate through a states config object to apply a given style to the compiled selectors
/// @param {Map} $states - A state definition object
///
@mixin ui-form-field--bool-apply-states($states: ()) {
    $keys: map-keys($states);
    $has-keys: length($keys) > 0;

    @if ($has-keys) {
        $key: nth($keys, 1);
        $value: map-get($states, $key);
        $next: map-remove($states, $key);

        @include ui-form-field--bool-apply-state($key, $value) {
            @include ui-form-field--bool-apply-states($next) {
                @content;
            }
        }
    } @else {
        @include ui-form-field--bool-target-both {
            @content;
        }
    }
}

/// Call a style mixin according to a given key/value pair
/// @param {string} $key - The state name
/// @param {boolean} $value - The state value
///
@mixin ui-form-field--apply-state($key, $value) {
    @if ($key == 'focus') {
        @include ui-form-field--state-focus($value) {
            @content;
        }
    }

    @if ($key == 'disabled') {
        @include ui-form-field--state-disabled($value) {
            @content;
        }
    }

    @if ($key == 'required') {
        @include ui-form-field--state-required($value) {
            @content;
        }
    }

    @if ($key == 'error') {
        @include ui-form-field--state-error($value) {
            @content;
        }
    }

    @if ($key == 'filled') {
        @include ui-form-field--state-filled($value) {
            @content;
        }
    }

    @if ($key == 'floating') {
        @include ui-form-field--state-floating($value) {
            @content;
        }
    }
}

/// Call a style mixin according to a given key/value pair
/// @param {string} $key - The state name
/// @param {boolean} $value - The state value
///
@mixin ui-form-field--bool-apply-state($key, $value) {
    @if ($key == 'hover') {
        @include ui-form-field--bool-state-hover($value) {
            @content;
        }
    }

    @if ($key == 'focus') {
        @include ui-form-field--bool-state-focus($value) {
            @content;
        }
    }

    @if ($key == 'disabled') {
        @include ui-form-field--bool-state-disabled($value) {
            @content;
        }
    }

    @if ($key == 'checked') {
        @include ui-form-field--bool-state-checked($value) {
            @content;
        }
    }
}

/// Target both the input field and the label
///
@mixin ui-form-field--bool-target-both {
    &,
    & ~ .ui-form-field--label-wrap {
        @content;
    }
}

/// Append a hair space and a star sign to discriminate required form fields
///
@mixin ui-form-field--is-required {
    //&::after {
    //    // NOTE: `\200A` defines a hair space.
    //    // @link https://en.wikipedia.org/wiki/Whitespace_character#Spaces_in_Unicode
    //    content: '\200A*';
    //}
}

/// Append a hair space and an "optional" discriminate optional form fields
///
@mixin ui-form-field--is-optional {
    &::after {
        // NOTE: `\200A` defines a hair space.
        // @link https://en.wikipedia.org/wiki/Whitespace_character#Spaces_in_Unicode
        content: '\200A\ (Optional)';
    }
}
