.wizard-sidebar-steps {
    &.wizard-sidebar-steps__aside {
        display: none;

        @include mq('>sm') {
            display: block;
        }

        .wizard-sidebar-steps--path-icon {
            display: none;
        }
    }
}

.wizard-sidebar-steps.wizard-sidebar-steps__mobile {
    z-index: z-index('anchor-navigation');
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    white-space: nowrap;
    background-color: $c--monochrome-white;
    border-bottom: 1px solid $c--monochrome-light-silver;

    @include mq('<=sm') {
        display: block;
    }

    .wizard-sidebar-steps--inner {
        width: 100%;
        padding: 0 24px;
    }

    .wizard-sidebar-steps--inner-inner {
        width: 100%;
        padding: 12px 0;
        overflow-x: scroll;
    }

    .wizard-sidebar-steps--list {
        display: flex;
        flex-direction: row;
    }

    .wizard-sidebar-steps--list-item {
        margin-top: 0;

        ~ .wizard-sidebar-steps--list-item {
            border-left: 12px solid transparent;
        }
    }
}
