.anchor-navigation-dropdown {
    // Vars

    // Support

    // Module
    & {
        & {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: flex-end;

            @include responsive-vertical-spacing('x-small') using($sizing) {
                padding: $sizing 0;
            }
        }

        .anchor-navigation-dropdown--btn {
            display: flex;
            align-items: flex-end;
            cursor: pointer;
            min-width: 32px;
        }

        .anchor-navigation-dropdown--list-wrapper {
            position: absolute;
            right: 0;
            bottom: 0;
            display: block;
            transform: translateY(100%);
            background-color: $c--monochrome-white;
            box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.25);
            padding: 8px 16px;
        }

        .anchor-navigation-dropdown--list-item {
            position: relative;
            display: block;
            cursor: pointer;
            white-space: nowrap;

            @include type--text-link;
        }

        .anchor-navigation-dropdown--list-item-link {
            display: inline-block;
            color: $c--monochrome-light-black;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
            }

            @include responsive-vertical-spacing('xxx-small') using($sizing) {
                padding: $sizing 0;
            }

            @include has-focus {
                color: $c--monochrome-black;
            }
        }
    }

    // Facets
    & {
        .anchor-navigation-dropdown--list-item-link {
            &.anchor-navigation-dropdown--list-item-link__active {
                @include style-all-root-themes using($color) {
                    position: relative;
                    color: $color;
                }
            }
        }
    }

    // States
}
