.sidebar-block {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-bottom: 24px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .sidebar-block--header {
            display: flex;
            align-content: center;
            justify-content: space-between;
            padding: 24px;
            border: {
                left: 1px solid transparent;
                top: 1px solid transparent;
                right: 1px solid transparent;
            };

            @include style-all-root-themes using($color) {
                border-color: $color;
                color: $color;
            }
        }

        .sidebar-block--title {
            padding-right: 24px;

            @include type--h5(false);

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }

        .sidebar-block--icon {
            flex: 0 0 24px;
            align-self: center;
            width: 24px;
        }

        .sidebar-block--body {
            padding: 0 24px 24px;
        }

        .sidebar-block--body-wrap {
            overflow: hidden;
            will-change: height;
            border: {
                left: 1px solid transparent;
                bottom: 1px solid transparent;
                right: 1px solid transparent;
            }

            @include style-all-root-themes using($color) {
                border-color: $color;
            }
        }
    }

    // Facets
    & {
        &.sidebar-block__is-toggleable {
            .sidebar-block--header {
                cursor: pointer;
            }
        }

        &.sidebar-block__has-animated-height {
            .sidebar-block--body-wrap {
                transition: height $trs--base;
            }
        }

        &.sidebar-block__has-animated-height:not(.sidebar-block__is-open) {
            .sidebar-block--header {
                @include style-all-root-themes using($color) {
                    background-color: $color;
                }
            }

            .sidebar-block--title {
                color: $c--monochrome-white;
            }

            .sidebar-block--icon {
                color: $c--monochrome-white;
            }
        }
    }

    // States
}
