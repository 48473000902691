// Vars
$arrow-icon-size: 6px;

// Support

// Module
.contact-detail--legal-guardian-form {
    margin-top: 24px;
    padding: 16px;
    margin-bottom: -56px;

    @include style-all-root-themes using($color) {
        border: 1px solid $color;
        color: $color;
    }
}

.contact-detail--description {
    margin-bottom: 8px;
}

.contact-detail--legal-guardian-upload {
    margin: 0 -8px -8px; // small hack to render the form properly, it was never meant to be
}

// Facets
