.wizard-contact-step {
    //
}

.wizard-contact-step--head {
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 16px;
    background-color: $c--monochrome-light-gray;
    border: 1px solid $c--monochrome-gray;
    transition: $trs--common-props;

    &.wizard-contact-step--head__show-edit {
        .wizard-contact-step--head-icon {
            @include is-visible(true, $trs--base-speed);
        }
    }

    &.wizard-contact-step--head__has-errors {
        background-color: mix($c--signal-error, $c--monochrome-white, 2%);
        border-color: $c--signal-error;
    }

    &.wizard-contact-step--head__is-active {
        background-color: $c--monochrome-light-silver;
        border-color: $c--monochrome-silver;

        &.wizard-contact-step--head__has-errors {
            background-color: mix($c--signal-error, $c--monochrome-white, 10%);
            border-color: $c--signal-error;
        }
    }
}

.wizard-contact-step--collapsible ~ .wizard-contact-step--collapsible {
    margin-top: 8px;
}

.wizard-contact-step--body {
    @include responsive-vertical-spacing('large') using($padding) {
        padding: $padding 0;
    }
}

.wizard-contact-step--head-text {
    @include type--h5($has-margin: false);
    color: $c--monochrome-med-black;
}

.wizard-contact-step--head-icon {
    @include is-visible(false, $trs--base-speed);
}

.wizard-contact-step--head-edit {
    display: block;
    color: $c--themes-brand;

    @include has-focus {
        color: $c--themes-brand-dark;
    }
}

.wizard-contact-step--messages {
    padding-left: 17px;
}
