.text-link {
    // Vars
    $arrow-icon-size: 12px;
    $arrow-width: 93px;

    // Support

    // Module
    & {
        & {
            display: flex;
            align-items: center;
            line-height: $arrow-icon-size * 2;

            @include style-all-root-themes using($color) {
                color: $color;
            }

            @include type--text-link;
        }

        .text-link--arrow {
            @include animated-arrow($arrow-icon-size, 4);
        }

        &--narrow {
            .text-link--arrow {
                @include animated-arrow($arrow-icon-size, 4);
                margin-left: -8px; // small hack to render the arrow in a position it was never meant to be
            }
        }

        .text-link--arrow-wrap {
            display: flex;
            align-items: center;
            width: $arrow-width;
        }
    }

    // Facets

    // States
    & {
        &.text-link__base {
            @include has-focus {
                .text-link--arrow {
                    @include animated-arrow-hover;
                }
            }
        }

        &.text-link__sidebar {
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;

            .text-link--arrow-wrap {
                width: 80px;
            }

            .text-link--text {
                // some dirty hack
                @media screen and (min-width: 1280px) and (max-width: 1556px) {
                    max-width: 136px;
                }

                @include mq('lg') {
                    max-width: 160px;
                }

                @include mq('md') {
                    max-width: 144px;
                }
            }

            @include has-focus {
                @include style-all-root-themes('dark') using($color) {
                    color: $color;
                }

                .text-link--arrow {
                    @include animated-arrow-hover;
                }
            }
        }

        &.text-link__sidebar-text {
            color: $c--monochrome-med-black;
        }

        &.text-link__secondary.text-link__secondary.text-link__secondary {
            color: $c--monochrome-med-black;

            @include has-focus {
                color: $c--monochrome-black;
            }
        }

        &.text-link__top-spacing {
            @include responsive-vertical-spacing('x-small') using($sizing) {
                padding-top: $sizing;
            }

            &:first-of-type {
                @include responsive-vertical-spacing('large') using($sizing) {
                    padding-top: $sizing;
                }
            }
        }

        &.text-link__bottom-spacing {
            @include responsive-vertical-spacing('x-small') using($sizing) {
                padding-bottom: $sizing;
            }
        }
    }
}
