.main-footer {
    // Vars

    // Support
    @mixin active-link-state {
        color: $c--monochrome-black;
    }

    // Module
    & {
        & {
            font-size: 1.5rem;
            line-height: 1.4;

            @include mq('<=md') {
                padding: {
                    top: 13px;
                    bottom: 13px;
                };
            }

            @include mq('>md') {
                padding: {
                    top: 12px;
                    bottom: 12px;
                };
            }

            @include grid--root((row: 'list', cols: 'list-item'));
            @include grid--space((gutter: (8px, 16px), row: 'list', cols: 'list-item'));
        }

        .main-footer--left,
        .main-footer--right {
            display: flex;
            align-items: center;

            @include grid--span((ratio: 6 / 12, mq: '>sm'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .main-footer--left {
            flex: 0 0 auto;
        }

        .main-footer--right {
            flex-grow: 1;

            @include mq('>md') {
                align-items: center;
                justify-content: flex-end;
            }

            @include mq('md') {
                flex-direction: column;
                align-items: flex-end;
            }

            @include mq('<=sm') {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .main-footer--link {
            color: $c--monochrome-dark-gray;
            transition: color $trs--base;

            @include has-focus {
                @include active-link-state;
            }
        }

        .main-footer--copyright {
            color: $c--monochrome-dark-silver;

            @include mq('>md') {
                margin-left: 40px;
            }

            @include mq('<=md') {
                margin-top: map-get($vertical-spacings, 'xx-small');
            }
        }
    }

    // Facets
    & {
        .main-footer--link {
            &.main-footer--link__active {
                @include active-link-state;
            }

            &.main-footer--link__social-icon {
                color: $c--monochrome-med-gray;

                @include has-focus {
                    @include active-link-state;
                }
            }
        }

        .main-footer--list {
            &.main-footer--list__social {
                @include mq('<=sm') {
                    margin-bottom: map-get($vertical-spacings, 'xx-small');
                }
            }
        }
    }

    // States
}
