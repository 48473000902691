////
/// Header styles (hX)
////

@mixin type--h1($has-margin: true) {
    @include ff--base-light;
    font-size: 4rem;
    line-height: 1.1;
    color: $c--monochrome-black;

    @if ($has-margin) {
        @include responsive-vertical-spacing('small') using($spacing) {
            margin-bottom: $spacing;
        }
    }

    @include mq('<=lg') {
        font-size: 3.2rem;
        line-height: 1.125;
    }
}

@mixin type--h2($has-margin: true) {
    @include ff--base-light;
    font-size: 3.2rem;
    line-height: 1.375;
    color: $c--monochrome-black;

    @if ($has-margin) {
        @include responsive-vertical-spacing('small') using($spacing) {
            margin-bottom: $spacing;
        }
    }

    @include mq('<=lg') {
        font-size: 2.8rem;
        line-height: 1.48;
    }
}

@mixin type--h3($has-margin: true) {
    @include ff--base-regular;
    font-size: 2.8rem;
    line-height: 1.28;
    color: $c--monochrome-black;

    @if ($has-margin) {
        @include responsive-vertical-spacing('x-small') using($spacing) {
            margin-bottom: $spacing;
        }
    }

    @include mq('<=lg') {
        font-size: 2.4rem;
        line-height: 1.33;
    }
}

@mixin type--h4($has-margin: true) {
    @include ff--base-regular;
    font-size: 2.2rem;
    line-height: 1.31;

    @if ($has-margin) {
        @include responsive-vertical-spacing('x-small') using($spacing) {
            margin-bottom: $spacing;
        }
    }

    @include mq('<=lg') {
        font-size: 2rem;
    }
}

@mixin type--h5($has-margin: true) {
    @include ff--base-medium;
    font-size: 1.9rem;
    line-height: 1.26;
    color: $c--monochrome-black;

    @if ($has-margin) {
        @include responsive-vertical-spacing('xx-small') using($spacing) {
            margin-bottom: $spacing;
        }
    }

    @include mq('<=lg') {
        font-size: 1.8rem;
    }
}

@mixin type--h6($has-margin: true) {
    @include ff--base-medium;
    font-size: 1.7rem;
    line-height: 1.29;
    color: $c--monochrome-black;

    @if ($has-margin) {
        @include responsive-vertical-spacing('xx-small') using($spacing) {
            margin-bottom: $spacing;
        }
    }

    @include mq('<=lg') {
        font-size: 1.6rem;
    }
}
