@mixin animated-arrow($icon-size, $length-scale: 4) {
    position: relative;
    display: inline-block;
    margin-left: 12px;
    height: 1px;
    width: $icon-size * $length-scale;
    transition: padding-left $trs--base;
    box-sizing: content-box;

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
    }

    &::after {
        transform: translate($icon-size * 0.5, -50%) rotate(45deg);
        border: {
            right: 1px solid currentColor;
            top: 1px solid currentColor;
        };

        @include size($icon-size);
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: currentColor;
        transform: translate($icon-size * 0.5 + 1px, -50%);
    }
}

@mixin animated-arrow-hover($expand-value: 24px) {
    padding-left: $expand-value;
}

@mixin animated-arrow-reverse($icon-size, $length-scale: 4) {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    height: 1px;
    width: $icon-size * $length-scale;
    transition: padding-left $trs--base;
    box-sizing: content-box;

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
    }

    &::after {
        transform: translate(-$icon-size * 0.5, -50%) rotate(-45deg);
        transition: left $trs--base;
        border: {
            left: 1px solid currentColor;
            top: 1px solid currentColor;
        };

        @include size($icon-size);
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: currentColor;
        transform: translate(-$icon-size * 0.5 - 1px, -50%);
        transition: width $trs--base, left $trs--base;
    }
}

@mixin animated-arrow-reverse-hover($expand-value: 24px) {
    &::after {
        left: -$expand-value;
    }

    &::before {
        left: -$expand-value;
        width: calc(100% + #{$expand-value});
    }
}
