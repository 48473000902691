.skel {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        min-height: 100%;
        //overflow: hidden;

        .skel--main {
            z-index: z-index(main);
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            flex: 1 0 auto;
        }

        //.skel--footer {}
        //.skel--root {}
    }

    // Facets

    & {
        .skel--main {
            @include style-all-root-background-themes using($color) {
                background: $color;
            }
        }
    }

    // States
}
