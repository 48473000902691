.video {
    // Vars

    // Support

    // Module
    & {
        & {
            @include grid--span((ratio: 12 / 12, mq:'<=md'));
            @include grid--span((ratio: 8 / 12));
        }

        .video--title {
            @include type--h4;
            color: $c--monochrome-black;
        }

        .video--wrapper {
            position: relative;
            width: 100%;
            height: auto;
            padding-bottom: 9 / 16 * 100%; // 16 / 9
        }

        .video--iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // Facets

    // States
}
