.checkmark-list {
    @include type--ul-check;
    padding-left: 0;
}

.checkmark-list--item {
    color: $c--monochrome-med-gray;

    &.checkmark-list--item__checked {
        color: $c--monochrome-black;
    }

    &.checkmark-list--item__active {
        color: $c--monochrome-black;
    }
}

.checkmark-list--icon {
    position: absolute;
    top: 11px;
    left: 0;
    transform: translateY(-50%);
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    text-align: right;
    color: $c--signal-success;
}

.checkmark-list__sidebar {
    .checkmark-list--icon {
        color: $c--monochrome-dark-gray;
    }
}

.checkmark-list--edit {
    @include type--paragraph02;
    @include ff--base-medium;
}
