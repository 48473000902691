.radio-tags {
    .radio-tags--description {
        display: block;
        color: $c--monochrome-dark-gray;

        @include responsive-vertical-spacing('medium') using($spacing) {
            margin-bottom: $spacing;
        }

        &.radio-tags--description__is-monochrome-black {
            color: $c--monochrome-black;
        }
    }

    .radio-tags--tags-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .radio-tags--tags-item {
        width: 100%;
    }

    .radio-tags--tags-tag {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        column-gap: 12px;
        align-items: center;
        width: 100%;
        padding: 16px;
        border-radius: 6px;
        transition: all $trs--base-speed $trs--base-fn;
        color: $c--themes-brand;
        background: $c--monochrome-white;
        border: 1px solid $c--themes-brand;

        @include has-focus {
            color: $c--monochrome-white;
            background: $c--themes-brand;

            .radio-tags--tags-tag-description {
                color: $c--monochrome-white;
            }
        }

        &:has(> .radio-tags--input:checked) {
            color: $c--monochrome-white;
            background: $c--themes-brand;

            .radio-tags--tags-tag-description {
                color: $c--monochrome-white;
            }
        }
    }

    .radio-tags--input {
        @include ff--base-medium;
        color: $c--themes-brand;
        transition: all $trs--base-speed $trs--base-fn;
        // stylelint-disable-next-line property-no-unknown
        accent-color: $c--themes-brand;
        width: 24px;
        height: 24px;
    }

    .radio-tags--tags-tag-text {
        @include ff--base-medium;
    }

    .radio-tags--tags-tag-description {
        @include ff--base-regular;
        color: $c--monochrome-black;
        width: 100%;
        margin-left: 36px;
        transition: all $trs--base-speed $trs--base-fn;
    }
}
