.grid-jobs {
    // Vars
    $arrow-size: 12px;

    // Support

    // Module
    & {
        & {
            // item grid
            @include grid--root((row: 'list', cols: 'entry'));

            @each $value in $grid--base-spacing {
                $gutter: map-get($value, 'gutter');
                $mq: map-get($value, 'mq');

                @include grid--space((gutter: ($gutter $gutter), row: 'list', cols: 'entry', mq: $mq));
            }
        }

        .grid-jobs--entry {
            overflow: hidden;

            @include grid--span((ratio: 4 / 8, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .grid-jobs--title {
            @include type--h4;
            color: $c--monochrome-black;
        }

        .grid-jobs--dark-text {
            color: $c--monochrome-black;
            transition: color $trs--base;
        }

        .grid-jobs--entry-link {
            $responsive-grid-properties: (
                'xs': (min-height: 240px, padding: 24px),
                'sm': (min-height: 240px, padding: 24px),
                'md': (min-height: 280px, padding: 24px),
                'lg': (min-height: 240px, padding: 24px),
                'xl': (min-height: 280px, padding: 32px),
                'xxl': (min-height: 300px, padding: 40px),
            );

            @each $mq, $props in $responsive-grid-properties {
                @include mq($mq) {
                    min-height: map-get($props, 'min-height');
                    padding: map-get($props, 'padding');
                }
            }

            display: flex;
            flex-wrap: wrap;
            height: 100%;
            padding: 24px;
            min-height: 228px;
            background: $c--monochrome-light-gray;
            overflow: hidden;
            word-break: break-word;
            hyphens: auto;
            border: 1px solid $c--monochrome-gray;

            @include at-root('.ie11') {
                word-break: break-all;
            }

            transition: transition-props($trs--base, background, color, border);

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }

        .grid-jobs--entry-title,
        .grid-jobs--entry-subtitle {
            @include type--h3(false);
            transition: color $trs--base;
            flex: 0 0 100%;
        }

        .grid-jobs--entry-subtitle {
            color: $c--themes-brand;
        }

        .grid-jobs--entry-tag {
            padding: 4px 12px;
            border-radius: 16px;
            transition: background color $trs--base;
            color: $c--monochrome-white;

            @include type--paragraph02;

            @include style-all-root-themes using($color) {
                background: $color;
            }
        }

        .grid-jobs--entry-footer {
            display: flex;
            flex: 0 0 100%;
            align-items: flex-end;
            justify-content: space-between;

            @include responsive-vertical-spacing('large') using($sizing) {
                padding-top: $sizing;
            }
        }

        .grid-jobs--entry-arrow-wrapper {
            display: flex;
            align-items: center;
            flex-basis: 70px;
            flex-shrink: 0;
            height: 19px;
            margin-bottom: 4px;
        }

        .grid-jobs--entry-arrow {
            @include animated-arrow($arrow-size, 4);
            margin-left: 0;
            color: $c--themes-brand;
        }
    }

    // Facets

    // States
    .grid-jobs--entry-link {
        @include has-focus {
            color: $c--monochrome-white;
            background: $c--themes-brand;
            border-color: $c--themes-brand;

            .grid-jobs--entry-tag {
                background: $c--monochrome-white;
                color: $c--themes-brand;
            }

            .grid-jobs--entry-subtitle {
                color: $c--monochrome-gray;
            }

            .grid-jobs--dark-text {
                color: $c--monochrome-white;
            }

            .grid-jobs--entry-arrow {
                @include animated-arrow-hover(15px);
                color: $c--monochrome-white;
            }
        }
    }
}
