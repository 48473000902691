.wizard-sidebar-choice--text {
    color: $c--monochrome-med-gray;

    &.wizard-sidebar-choice--text__small {
        font-size: 1.2rem;
    }
}

.wizard-sidebar-choice--list-item {
    ~ .wizard-sidebar-choice--list-item {
        margin-top: 16px;
    }
}
