.schools-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            @include grid--root((row: 'list', cols: 'item'));
            @include grid--space((gutter: (2px, 2px), row: 'list', cols: 'item'));

            margin-bottom: 36px;

            @include mq('<=xl') {
                margin-bottom: 32px;
            }

            @include mq('<=sm') {
                margin-bottom: 24px;
            }
        }

        .schools-teaser--item {
            position: relative;
            overflow: hidden;

            @include grid--span((ratio: 1 / 5, mq: '>lg'));
            @include grid--span((ratio: 2 / 6, mq: ('lg', 'md')));
            @include grid--span((ratio: 1 / 1, mq: '<=sm'));

            &:nth-child(4n),
            &:nth-child(5n) {
                @include grid--span((ratio: 3 / 6, mq: ('lg', 'md')));
            }

            @include mq('xxl') {
                height: calc(100vh - #{$main-header-height} - #{$schools-teaser-padding-size-big});
            }

            @include mq('>lg') {
                height: calc(100vh - #{$main-header-height} - #{$schools-teaser-padding-size-medium});
                min-height: 600px;
                max-height: 1024px;
            }

            @include mq(('lg', 'md')) {
                height: calc(50vh - #{$main-header-height / 4} - #{$schools-teaser-padding-size-medium});
                min-height: 320px;
                max-height: 500px;
            }
        }

        .schools-teaser--figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($c--monochrome-white, 0.8);
                transition: background $trs--base;
            }
        }

        .schools-teaser--image {
            /* stylelint-disable */
            font-family: 'object-fit: cover;'; // needed only for ie11 support
            /* stylelint-enable */

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            object-fit: cover;

            filter: grayscale(100);
            transition: filter $trs--base;
        }

        .schools-teaser--link {
            display: flex;
            align-items: flex-end;
            height: 100%;
        }

        .schools-teaser--text {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            color: $c--monochrome-black;
            padding: 0 24px 12px;
            width: 100%;

            @include mq(('>xl')) {
                margin-bottom: 80px;
                height: 266px;
                padding: 0 24px 12px;
            }

            @include mq('xl') {
                margin-bottom: 80px;
                height: 310px;
                padding: 0 24px 12px;
            }

            @include mq(('lg', 'md')) {
                height: 198px;
                margin-bottom: 32px;
            }

            @include mq('<=sm') {
                align-content: flex-end;
                padding-bottom: 0;
                height: 128px;
            }
        }

        .schools-teaser--description,
        .schools-teaser--title {
            flex: 0 0 100%;
            width: 100%;
            transition: transform $trs--base;
        }

        .schools-teaser--description {
            color: $c--monochrome-dark-gray;
            margin-bottom: map-get($vertical-spacings, 'medium');

            @include mq('<=sm') {
                display: none;
            }
        }

        .schools-teaser--title {
            @include type--h2(false);
            color: $c--monochrome-black;

            @include mq('xl') {
                display: flex;
                align-items: flex-end;
                min-height: 88px;
                text-align: start;
            }

            @include mq('>sm') {
                margin-bottom: map-get($vertical-spacings, 'x-small');
            }

            @include mq('<=sm') {
                margin-bottom: map-get($vertical-spacings, 'x-small');
            }
        }

        .schools-teaser--arrow {
            @include animated-arrow(8px);

            position: absolute;
            bottom: 8px;

            @include mq('>sm') {
                transform: translate(-20px, 0);
                left: 24px;
                width: 160px;
            }

            @include mq('<=sm') {
                left: calc(100% - 110px);
                bottom: 32px;
                width: 54px;
            }
        }
    }

    // Facets
    & {
        .schools-teaser--link {
            @include has-focus {
                @each $name, $palette in $themes {
                    &.schools-teaser--link__#{$name} {
                        .schools-teaser--figure {
                            &::after {
                                background: rgba(map-get($palette, 'default'), 0.6);
                            }
                        }
                    }
                }
            }
        }
    }

    // States
    & {
        .schools-teaser--link {
            @include has-focus {
                .schools-teaser--title,
                .schools-teaser--description,
                .schools-teaser--text {
                    color: $c--monochrome-white;
                }

                .schools-teaser--image {
                    filter: grayscale(0);
                }


                @include mq('>sm') {
                    .schools-teaser--title {
                        transform: translate(0, map-get($vertical-spacings, 'small') * -1);
                    }

                    .schools-teaser--description {
                        transform: translate(0, map-get($vertical-spacings, 'medium') * -1);
                    }

                    .schools-teaser--arrow {
                        @include animated-arrow-hover(12px);
                    }
                }

                @include mq('<=sm') {
                    .schools-teaser--arrow {
                        @include animated-arrow-hover(18px);
                    }
                }
            }
        }
    }
}
