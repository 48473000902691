.annual-report-numbers {
    // Vars
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }

    // Support

    // Module
    & {
        .annual-report-numbers--wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .annual-report-numbers--detail {
            & {
                .annual-report-numbers--detail-value,
                .annual-report-numbers--detail-description {
                    display: inline-block;
                    width: auto;
                }

                .annual-report-numbers--detail-value {
                    color: $c--themes-brand;
                    font-weight: 500;
                    font-size: calc(90px + (120 - 90) * ((100vw - 320px) / (768 - 320)));

                    @media (max-width: 767px) {
                        font-size: 90px;
                    }

                    @media (min-width: 768px) {
                        font-size: 120px;
                    }
                }

                .annual-report-numbers--detail-description {
                    color: $c--monochrome-dark-gray;
                    font-size: 17px;
                }
            }
        }
    }

    // Facets

    // States
}
