.sticky-panel {
    // Vars

    // Support

    // Module
    & {
        & {
            position: sticky;
            top: 0;

            @include responsive-vertical-spacing('large') using($spacing) {
                padding: {
                    top: $spacing;
                    bottom: $spacing;
                }
            }

            // I am not sure if that's correct but the design of this component changed several times and now I am a bit lost
            @include mq('<=md') {
                padding-bottom: 0;
            }

            // this component position depends on the anchor navigation element
            @include at-root('.has-anchor-navigation') {
                padding-top: 0;
                top: 56px;

                @include mq('<=sm') {
                    margin-top: map-get($vertical-spacings, 'medium');
                }

                @include mq('<=lg') {
                    top: 48px;
                }
            }
        }
    }

    // Facets

    // States
}
