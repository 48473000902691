.teaser-event {
    // Vars

    // Support

    // Module
    & {
        .teaser-event--title {
            @include type--paragraph02;
            color: $c--monochrome-med-black;
        }

        .teaser-event--description {
            color: $c--monochrome-med-gray;
            padding-bottom: map-get($vertical-spacings, 'x-small');

            @include type--paragraph01;

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        .teaser-event--description-value {
            @include type--text-caption-small;
        }

        .teaser-event--list {
            margin-top: 24px;
        }
    }

    // Facets

    // States
}
