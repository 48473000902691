@charset "UTF-8";
/* stylelint-disable scss/declaration-nested-properties */
.u-mq-spy-type::before,
.u-mq-spy-conditions::before,
.u-mq-spy-expressions::before {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

html.u-mq-info .u-mq-spy-conditions::before {
  pointer-events: none;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  max-width: 50%;
  max-height: none;
  padding: 15px;
  margin: 0;
  clip: auto;
  font-family: monospace;
  font-size: 12px;
  line-height: 1.1;
  word-wrap: break-word;
  color: #ffffff;
  background-color: rgba(22, 25, 40, 0.9);
  border-top-right-radius: 5px;
  text-align: left;
}
html.u-mq-info .u-mq-spy-conditions::before {
  transition: visibility 0ms ease 2000ms;
  visibility: hidden;
}

@media screen {
  .u-mq-spy-type::before {
    content: '"screen"';
  }
}

@media print {
  .u-mq-spy-type::before {
    content: '"print"';
  }
}

.u-mq-spy-conditions::before {
  content: '{"width":null,"orientation":null,"resolution":null}';
}

@media (max-width: 575px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":null,"resolution":null}';
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":null,"resolution":null}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":null,"resolution":null}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":null,"resolution":null}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":null,"resolution":null}';
  }
}

@media (min-width: 1556px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":null,"resolution":null}';
  }
}

@media (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"portrait","resolution":null}';
  }
}

@media (max-width: 575px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"portrait","resolution":null}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"portrait","resolution":null}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"portrait","resolution":null}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"portrait","resolution":null}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"portrait","resolution":null}';
  }
}

@media (min-width: 1556px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"portrait","resolution":null}';
  }
}

@media (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"landscape","resolution":null}';
  }
}

@media (max-width: 575px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"landscape","resolution":null}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"landscape","resolution":null}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"landscape","resolution":null}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"landscape","resolution":null}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"landscape","resolution":null}';
  }
}

@media (min-width: 1556px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"landscape","resolution":null}';
  }
}

@media (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":null,"resolution":"res2x"}';
  }
}

@media (max-width: 575px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":null,"resolution":"res2x"}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":null,"resolution":"res2x"}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":null,"resolution":"res2x"}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":null,"resolution":"res2x"}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":null,"resolution":"res2x"}';
  }
}

@media (min-width: 1556px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":null,"resolution":"res2x"}';
  }
}

@media (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"portrait","resolution":"res2x"}';
  }
}

@media (max-width: 575px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"portrait","resolution":"res2x"}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"portrait","resolution":"res2x"}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"portrait","resolution":"res2x"}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"portrait","resolution":"res2x"}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"portrait","resolution":"res2x"}';
  }
}

@media (min-width: 1556px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"portrait","resolution":"res2x"}';
  }
}

@media (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"landscape","resolution":"res2x"}';
  }
}

@media (max-width: 575px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"landscape","resolution":"res2x"}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"landscape","resolution":"res2x"}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"landscape","resolution":"res2x"}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"landscape","resolution":"res2x"}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"landscape","resolution":"res2x"}';
  }
}

@media (min-width: 1556px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"landscape","resolution":"res2x"}';
  }
}

@media (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":null,"resolution":"res3x"}';
  }
}

@media (max-width: 575px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":null,"resolution":"res3x"}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":null,"resolution":"res3x"}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":null,"resolution":"res3x"}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":null,"resolution":"res3x"}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":null,"resolution":"res3x"}';
  }
}

@media (min-width: 1556px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":null,"resolution":"res3x"}';
  }
}

@media (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"portrait","resolution":"res3x"}';
  }
}

@media (max-width: 575px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"portrait","resolution":"res3x"}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"portrait","resolution":"res3x"}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"portrait","resolution":"res3x"}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"portrait","resolution":"res3x"}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"portrait","resolution":"res3x"}';
  }
}

@media (min-width: 1556px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"portrait","resolution":"res3x"}';
  }
}

@media (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"landscape","resolution":"res3x"}';
  }
}

@media (max-width: 575px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"landscape","resolution":"res3x"}';
  }
}

@media (min-width: 576px) and (max-width: 767px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"landscape","resolution":"res3x"}';
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"landscape","resolution":"res3x"}';
  }
}

@media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"landscape","resolution":"res3x"}';
  }
}

@media (min-width: 1280px) and (max-width: 1555px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"landscape","resolution":"res3x"}';
  }
}

@media (min-width: 1556px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"landscape","resolution":"res3x"}';
  }
}

.u-mq-spy-expressions::before {
  content: '[{"name":"screen","query":"screen","type":"type"},{"name":"print","query":"print","type":"type"},{"name":"xs","query":"(max-width: 575px)","type":"width"},{"name":"sm","query":"(min-width: 576px) and (max-width: 767px)","type":"width"},{"name":"md","query":"(min-width: 768px) and (max-width: 1023px)","type":"width"},{"name":"lg","query":"(min-width: 1024px) and (max-width: 1279px)","type":"width"},{"name":"xl","query":"(min-width: 1280px) and (max-width: 1555px)","type":"width"},{"name":"xxl","query":"(min-width: 1556px)","type":"width"},{"name":"portrait","query":"(orientation: portrait)","type":"orientation"},{"name":"landscape","query":"(orientation: landscape)","type":"orientation"},{"name":"res2x","query":"(min-resolution: 2dppx)","type":"resolution"},{"name":"res3x","query":"(min-resolution: 3dppx)","type":"resolution"}]';
}

@font-face {
  font-family: "SuisseIntl";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-Light-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-Light-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-Light-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-LightItalic-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-LightItalic-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-LightItalic-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-Medium-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-MediumItalic-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-MediumItalic-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-MediumItalic-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-Regular-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-RegularItalic-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-RegularItalic-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-RegularItalic-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-Bold-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-Bold-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-Bold-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/SuisseIntl/SuisseIntl-BoldItalic-WebS.woff2") format("woff2"), url("../../fonts/SuisseIntl/SuisseIntl-BoldItalic-WebS.woff") format("woff"), url("../../fonts/SuisseIntl/SuisseIntl-BoldItalic-WebS.ttf") format("truetype");
}
@font-face {
  font-family: "Argesta";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/Argesta/argestadisplay-regularitalic-webfont.woff2") format("woff2"), url("../../fonts/Argesta/argestadisplay-regularitalic-webfont.woff") format("woff"), url("../../fonts/Argesta/argestadisplay-regularitalic-webfont.ttf") format("truetype");
}
/**
 * Mixin allowing a responsive use of the vertical spacings
 *
 * @example
 * @include responsive-vertical-spacing('medium') using ($spacing) {
 *     margin-top: $spacing;
 * }
 */
/**
 * Allows the styling of a component according to root theme class selector
 * please check also https://sass-lang.com/documentation/at-rules/mixin#passing-arguments-to-content-blocks
 */
/* stylelint-disable */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   dreipol fork
   License: none (public domain)
*/
html, body, div, span, iframe, object,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.templates, .template, .controllers, .controller {
  display: none;
}

.u-icon-source, .u-icon-sprite, .u-svg-filter-source {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  text-decoration: none;
}

* {
  -ms-touch-action: pan-y;
  -ms-content-zooming: none;
}

html, body {
  width: 100%;
  height: 100%;
}

html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  overflow: visible;
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 0;
  cursor: auto;
  background-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
body {
  -webkit-touch-callout: text;
  user-select: text;
}
body::selection, body ::selection {
  background-color: #accef7;
}

details *, details *::before, details *::after {
  box-sizing: border-box;
}

main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #222222;
}
@media (max-width: 1279px) {
  body {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}

.u-typography-root h1:not(.u-reset),
.u-typography-root .u-reset__h1 {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 4rem;
  line-height: 1.1;
  color: #000000;
}
@media (max-width: 1279px) {
  .u-typography-root h1:not(.u-reset),
.u-typography-root .u-reset__h1 {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .u-typography-root h1:not(.u-reset),
.u-typography-root .u-reset__h1 {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .u-typography-root h1:not(.u-reset),
.u-typography-root .u-reset__h1 {
    font-size: 3.2rem;
    line-height: 1.125;
  }
}
.u-typography-root h2:not(.u-reset),
.u-typography-root .u-reset__h2 {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
}
@media (max-width: 1279px) {
  .u-typography-root h2:not(.u-reset),
.u-typography-root .u-reset__h2 {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .u-typography-root h2:not(.u-reset),
.u-typography-root .u-reset__h2 {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .u-typography-root h2:not(.u-reset),
.u-typography-root .u-reset__h2 {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
.u-typography-root h3:not(.u-reset),
.u-typography-root .u-reset__h3 {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
}
@media (max-width: 1279px) {
  .u-typography-root h3:not(.u-reset),
.u-typography-root .u-reset__h3 {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .u-typography-root h3:not(.u-reset),
.u-typography-root .u-reset__h3 {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .u-typography-root h3:not(.u-reset),
.u-typography-root .u-reset__h3 {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.u-typography-root h4:not(.u-reset),
.u-typography-root .u-reset__h4 {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
}
@media (max-width: 1279px) {
  .u-typography-root h4:not(.u-reset),
.u-typography-root .u-reset__h4 {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .u-typography-root h4:not(.u-reset),
.u-typography-root .u-reset__h4 {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .u-typography-root h4:not(.u-reset),
.u-typography-root .u-reset__h4 {
    font-size: 2rem;
  }
}
.u-typography-root h5:not(.u-reset),
.u-typography-root .u-reset__h5 {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
  line-height: 1.26;
  color: #000000;
}
@media (max-width: 1279px) {
  .u-typography-root h5:not(.u-reset),
.u-typography-root .u-reset__h5 {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .u-typography-root h5:not(.u-reset),
.u-typography-root .u-reset__h5 {
    margin-bottom: 8px;
  }
}
@media (max-width: 1279px) {
  .u-typography-root h5:not(.u-reset),
.u-typography-root .u-reset__h5 {
    font-size: 1.8rem;
  }
}
.u-typography-root h6:not(.u-reset),
.u-typography-root .u-reset__h6 {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.7rem;
  line-height: 1.29;
  color: #000000;
}
@media (max-width: 1279px) {
  .u-typography-root h6:not(.u-reset),
.u-typography-root .u-reset__h6 {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .u-typography-root h6:not(.u-reset),
.u-typography-root .u-reset__h6 {
    margin-bottom: 8px;
  }
}
@media (max-width: 1279px) {
  .u-typography-root h6:not(.u-reset),
.u-typography-root .u-reset__h6 {
    font-size: 1.6rem;
  }
}
.u-typography-root p:not(.u-reset),
.u-typography-root .u-reset__p {
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .u-typography-root p:not(.u-reset),
.u-typography-root .u-reset__p {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
.u-typography-root ul.u-reset, .u-typography-root ul.u-reset > li, .u-typography-root ol.u-reset, .u-typography-root ol.u-reset > li {
  list-style: none;
}
.u-typography-root ul:not(.u-reset),
.u-typography-root .u-reset__ul {
  padding-left: 12px;
  list-style: none;
  color: #5c5c5c;
}
@media (min-width: 1555px) {
  .u-typography-root ul:not(.u-reset),
.u-typography-root .u-reset__ul {
    padding-left: 16px;
  }
}
.u-typography-root ul:not(.u-reset) > li,
.u-typography-root .u-reset__ul > li {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 2.6rem;
}
.u-typography-root ul:not(.u-reset) > li > *,
.u-typography-root .u-reset__ul > li > * {
  margin-bottom: 0;
}
.u-typography-root ul:not(.u-reset) > li ~ li,
.u-typography-root .u-reset__ul > li ~ li {
  margin-top: 8px;
}
.u-typography-root ul:not(.u-reset) > li > ul, .u-typography-root ul:not(.u-reset) > li ol,
.u-typography-root .u-reset__ul > li > ul,
.u-typography-root .u-reset__ul > li ol {
  margin-top: 8px;
}
.u-typography-root ul:not(.u-reset) > li::before,
.u-typography-root .u-reset__ul > li::before {
  position: absolute;
  top: 0;
  right: 100%;
  display: inline-block;
  width: 8px;
  height: auto;
  font-size: inherit;
  padding: 0;
}
.u-typography-root ul:not(.u-reset) > li,
.u-typography-root .u-reset__ul > li {
  position: relative;
}
.u-typography-root ul:not(.u-reset) > li::before,
.u-typography-root .u-reset__ul > li::before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  transform: translateY(-50%);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  text-align: right;
  background-color: #be1621;
}
html[data-juventus-theme=brand] .u-typography-root ul:not(.u-reset) > li::before,
html[data-juventus-theme=brand] .u-typography-root .u-reset__ul > li::before {
  background-color: #be1621;
}

html[data-juventus-theme=highschool] .u-typography-root ul:not(.u-reset) > li::before,
html[data-juventus-theme=highschool] .u-typography-root .u-reset__ul > li::before {
  background-color: #dd7200;
}

html[data-juventus-theme=economy] .u-typography-root ul:not(.u-reset) > li::before,
html[data-juventus-theme=economy] .u-typography-root .u-reset__ul > li::before {
  background-color: #286ab7;
}

html[data-juventus-theme=medicine] .u-typography-root ul:not(.u-reset) > li::before,
html[data-juventus-theme=medicine] .u-typography-root .u-reset__ul > li::before {
  background-color: #1f9285;
}

html[data-juventus-theme=engineering] .u-typography-root ul:not(.u-reset) > li::before,
html[data-juventus-theme=engineering] .u-typography-root .u-reset__ul > li::before {
  background-color: #948b85;
}

html[data-juventus-theme=medical-leadership] .u-typography-root ul:not(.u-reset) > li::before,
html[data-juventus-theme=medical-leadership] .u-typography-root .u-reset__ul > li::before {
  background-color: #771469;
}

.u-typography-root ol:not(.u-reset),
.u-typography-root .u-reset__ol {
  padding-left: 12px;
  list-style: none;
  color: #5c5c5c;
  counter-reset: li;
}
@media (min-width: 1555px) {
  .u-typography-root ol:not(.u-reset),
.u-typography-root .u-reset__ol {
    padding-left: 16px;
  }
}
.u-typography-root ol:not(.u-reset) > li,
.u-typography-root .u-reset__ol > li {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 2.6rem;
}
.u-typography-root ol:not(.u-reset) > li > *,
.u-typography-root .u-reset__ol > li > * {
  margin-bottom: 0;
}
.u-typography-root ol:not(.u-reset) > li ~ li,
.u-typography-root .u-reset__ol > li ~ li {
  margin-top: 8px;
}
.u-typography-root ol:not(.u-reset) > li > ul, .u-typography-root ol:not(.u-reset) > li ol,
.u-typography-root .u-reset__ol > li > ul,
.u-typography-root .u-reset__ol > li ol {
  margin-top: 8px;
}
.u-typography-root ol:not(.u-reset) > li::before,
.u-typography-root .u-reset__ol > li::before {
  position: absolute;
  top: 0;
  right: 100%;
  display: inline-block;
  width: 8px;
  height: auto;
  font-size: inherit;
  padding: 0;
}
.u-typography-root ol:not(.u-reset) > li::before,
.u-typography-root .u-reset__ol > li::before {
  content: counter(li) ".";
  counter-increment: li;
  text-align: right;
  transform: translateX(100%);
  width: auto;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #be1621;
}
html[data-juventus-theme=brand] .u-typography-root ol:not(.u-reset) > li::before,
html[data-juventus-theme=brand] .u-typography-root .u-reset__ol > li::before {
  color: #be1621;
}

html[data-juventus-theme=highschool] .u-typography-root ol:not(.u-reset) > li::before,
html[data-juventus-theme=highschool] .u-typography-root .u-reset__ol > li::before {
  color: #dd7200;
}

html[data-juventus-theme=economy] .u-typography-root ol:not(.u-reset) > li::before,
html[data-juventus-theme=economy] .u-typography-root .u-reset__ol > li::before {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .u-typography-root ol:not(.u-reset) > li::before,
html[data-juventus-theme=medicine] .u-typography-root .u-reset__ol > li::before {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .u-typography-root ol:not(.u-reset) > li::before,
html[data-juventus-theme=engineering] .u-typography-root .u-reset__ol > li::before {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .u-typography-root ol:not(.u-reset) > li::before,
html[data-juventus-theme=medical-leadership] .u-typography-root .u-reset__ol > li::before {
  color: #771469;
}

.u-typography-root b, .u-typography-root strong {
  font-weight: 500;
}
.u-typography-root i, .u-typography-root em {
  font-style: italic;
}
.u-typography-root sub {
  font-size: 1.2rem;
  vertical-align: sub;
}
.u-typography-root sup {
  font-size: 1.2rem;
  vertical-align: super;
}
.u-typography-root a, .u-typography-root .u-link {
  color: #222222;
  text-decoration: none;
}
.u-typography-root a:not(.u-reset):not(.btn), .u-typography-root .u-link:not(.u-reset):not(.btn) {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=brand] .u-typography-root a:not(.u-reset):not(.btn), html[data-juventus-theme=brand] .u-typography-root .u-link:not(.u-reset):not(.btn) {
  color: #be1621;
}

html[data-juventus-theme=highschool] .u-typography-root a:not(.u-reset):not(.btn), html[data-juventus-theme=highschool] .u-typography-root .u-link:not(.u-reset):not(.btn) {
  color: #dd7200;
}

html[data-juventus-theme=economy] .u-typography-root a:not(.u-reset):not(.btn), html[data-juventus-theme=economy] .u-typography-root .u-link:not(.u-reset):not(.btn) {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .u-typography-root a:not(.u-reset):not(.btn), html[data-juventus-theme=medicine] .u-typography-root .u-link:not(.u-reset):not(.btn) {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .u-typography-root a:not(.u-reset):not(.btn), html[data-juventus-theme=engineering] .u-typography-root .u-link:not(.u-reset):not(.btn) {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .u-typography-root a:not(.u-reset):not(.btn), html[data-juventus-theme=medical-leadership] .u-typography-root .u-link:not(.u-reset):not(.btn) {
  color: #771469;
}

@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .u-typography-root a:not(.u-reset):not(.btn):hover, .u-typography-root a:not(.u-reset):not(.btn):focus, .u-typography-root .u-link:not(.u-reset):not(.btn):hover, .u-typography-root .u-link:not(.u-reset):not(.btn):focus {
    text-decoration: underline;
  }
}
@media (any-hover: none) {
  .u-typography-root a:not(.u-reset):not(.btn):active, .u-typography-root .u-link:not(.u-reset):not(.btn):active {
    text-decoration: underline;
  }
}
.u-typography-root hr {
  display: block;
  border: 0;
  height: 1px;
  color: inherit;
  background-color: currentColor;
}
.u-typography-root pre, .u-typography-root code {
  font-family: monospace;
}

.grid.grid__base .grid--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid.grid__base .grid--row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid.grid__base .grid--row {
  min-height: 1px;
}

.grid.grid__base .grid--col {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .grid.grid__base .grid--container {
    max-width: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid.grid__base .grid--container {
    max-width: 512px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid.grid__base .grid--container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid.grid__base .grid--container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid.grid__base .grid--container {
    max-width: 1280px;
  }
}
@media (min-width: 1556px) {
  .grid.grid__base .grid--container {
    max-width: 1556px;
  }
}
@media (max-width: 575px) {
  .grid.grid__base .grid--container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid.grid__base .grid--row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid.grid__base .grid--col {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid.grid__base .grid--container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid.grid__base .grid--row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid.grid__base .grid--col {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid.grid__base .grid--container {
    padding-left: 34px;
    padding-right: 34px;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid.grid__base .grid--row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid.grid__base .grid--col {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid.grid__base .grid--container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid.grid__base .grid--row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid.grid__base .grid--col {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid.grid__base .grid--container {
    padding-left: 36px;
    padding-right: 36px;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid.grid__base .grid--row {
    margin-left: -16px;
    margin-right: -16px;
  }
  .grid.grid__base .grid--col {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .grid.grid__base .grid--container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid.grid__base .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid.grid__base .grid--row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid.grid__base .grid--col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.grid.grid__base .grid--container.grid--container__fluid {
  max-width: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

select::-ms-expand {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

* {
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

input, textarea, keygen, select, button {
  background: none;
  color: inherit;
  cursor: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  border: 0;
  margin: 0;
}

input[type=text],
input[type=email],
input[type=search],
input[type=password],
input[type=submit],
input[type=reset],
input[type=button],
button,
select {
  -moz-appearance: none;
  /* Firefox mobile */
  -webkit-appearance: none;
  /* iPad */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  border-radius: 0;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .built-form::before {
    content: "";
    display: block;
    height: 84px;
    margin-top: -84px;
    visibility: hidden;
  }
}
@media (min-width: 1280px) {
  .built-form .built-form--col {
    flex: 0 0 58.3333333333%;
  }
  html.ie11 .built-form .built-form--col {
    flex-basis: auto;
    width: 58.3333333333%;
  }

  html.ie10 .built-form .built-form--col {
    flex-basis: auto;
    width: 58.3333333333%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .built-form .built-form--col {
    flex: 0 0 66.6666666667%;
  }
  html.ie11 .built-form .built-form--col {
    flex-basis: auto;
    width: 66.6666666667%;
  }

  html.ie10 .built-form .built-form--col {
    flex-basis: auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .built-form .built-form--col {
    flex: 0 0 100%;
  }
  html.ie11 .built-form .built-form--col {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .built-form .built-form--col {
    flex-basis: auto;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .built-form .built-form--col {
    flex: 0 0 100%;
  }
  html.ie11 .built-form .built-form--col {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .built-form .built-form--col {
    flex-basis: auto;
    width: 100%;
  }
}
.built-form .built-form--lead {
  font-size: 2.2rem;
  line-height: 1.31;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .built-form .built-form--lead {
    font-size: 2rem;
    line-height: 1.35;
  }
}
@media (max-width: 1279px) {
  .built-form .built-form--lead {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .built-form .built-form--lead {
    margin-bottom: 40px;
  }
}
.built-form .built-form--anchor {
  font-size: 1.7rem;
  margin-bottom: 8px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
.built-form .built-form--anchor.built-form--anchor__brand {
  color: #be1621;
}
.built-form .built-form--anchor.built-form--anchor__highschool {
  color: #dd7200;
}
.built-form .built-form--anchor.built-form--anchor__economy {
  color: #286ab7;
}
.built-form .built-form--anchor.built-form--anchor__medicine {
  color: #1f9285;
}
.built-form .built-form--anchor.built-form--anchor__engineering {
  color: #948b85;
}
.built-form .built-form--anchor.built-form--anchor__medical-leadership {
  color: #771469;
}

.checkmark-list {
  padding-left: 12px;
  list-style: none;
  color: #5c5c5c;
  padding-left: 0;
}
@media (min-width: 1555px) {
  .checkmark-list {
    padding-left: 16px;
  }
}
.checkmark-list > li {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 2.6rem;
}
.checkmark-list > li > * {
  margin-bottom: 0;
}
.checkmark-list > li ~ li {
  margin-top: 8px;
}
.checkmark-list > li > ul, .checkmark-list > li ol {
  margin-top: 8px;
}
.checkmark-list > li::before {
  position: absolute;
  top: 0;
  right: 100%;
  display: inline-block;
  width: 8px;
  height: auto;
  font-size: inherit;
  padding: 0;
}
.checkmark-list > li {
  position: relative;
}

.checkmark-list--item {
  color: #7a7a7a;
}
.checkmark-list--item.checkmark-list--item__checked {
  color: #000000;
}
.checkmark-list--item.checkmark-list--item__active {
  color: #000000;
}

.checkmark-list--icon {
  position: absolute;
  top: 11px;
  left: 0;
  transform: translateY(-50%);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  text-align: right;
  color: #4caf62;
}

.checkmark-list__sidebar .checkmark-list--icon {
  color: #5c5c5c;
}

.checkmark-list--edit {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
@media (max-width: 1279px) {
  .checkmark-list--edit {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}

@media (max-width: 1279px) {
  .cms-module {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media (min-width: 1280px) {
  .cms-module {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (max-width: 1279px) {
  .cms-module:last-child {
    padding-bottom: 96px;
  }
}
@media (min-width: 1280px) {
  .cms-module:last-child {
    padding-bottom: 128px;
  }
}
.cms-module .cms-module--col {
  flex: 0 0 100%;
}
html.ie11 .cms-module .cms-module--col {
  flex-basis: auto;
  width: 100%;
}

html.ie10 .cms-module .cms-module--col {
  flex-basis: auto;
  width: 100%;
}

.cms-module.cms-module__debugger::before, .cms-module.cms-module__debugger::after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 1279px) {
  .cms-module.cms-module__debugger::before {
    content: "XL Module spacing top";
    top: 0;
    height: 32px;
    background-color: #f4df70;
  }
  .cms-module.cms-module__debugger::after {
    content: "XL Module spacing bottom";
    bottom: 0;
    height: 32px;
    background-color: #f47070;
  }
}
@media (min-width: 1280px) {
  .cms-module.cms-module__debugger::before {
    content: "XL Module spacing top";
    top: 0;
    height: 40px;
    background-color: #f4df70;
  }
  .cms-module.cms-module__debugger::after {
    content: "XL Module spacing bottom";
    bottom: 0;
    height: 40px;
    background-color: #f47070;
  }
}
.cms-module.cms-module__quotes .cms-module--col {
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .cms-module.cms-module__quotes .cms-module--col {
    flex: 0 0 75%;
  }
  html.ie11 .cms-module.cms-module__quotes .cms-module--col {
    flex-basis: auto;
    width: 75%;
  }

  html.ie10 .cms-module.cms-module__quotes .cms-module--col {
    flex-basis: auto;
    width: 75%;
  }
}
@media (max-width: 1023px) {
  .cms-module.cms-module__quotes .cms-module--col {
    flex: 0 0 100%;
  }
  html.ie11 .cms-module.cms-module__quotes .cms-module--col {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .cms-module.cms-module__quotes .cms-module--col {
    flex-basis: auto;
    width: 100%;
  }
}

@media (max-width: 1279px) {
  .error-page {
    margin-top: 32px;
  }
}
@media (min-width: 1280px) {
  .error-page {
    margin-top: 40px;
  }
}
@media (max-width: 1279px) {
  .error-page {
    margin-bottom: 40px;
  }
}
@media (min-width: 1280px) {
  .error-page {
    margin-bottom: 80px;
  }
}
.error-page .error-page--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
}
@media (max-width: 1279px) {
  .error-page .error-page--title {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .error-page .error-page--title {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .error-page .error-page--title {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
.error-page .error-page--lead {
  color: #5c5c5c;
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .error-page .error-page--lead {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (max-width: 1279px) {
  .error-page .error-page--lead {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .error-page .error-page--lead {
    margin-bottom: 40px;
  }
}
@media (max-width: 1279px) {
  .error-page .error-page--list-item {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .error-page .error-page--list-item {
    margin-bottom: 16px;
  }
}
.error-page .error-page--link {
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
.error-page .error-page--link-text {
  min-width: 160px;
}
.error-page .error-page--link-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.error-page .error-page--link-arrow::after, .error-page .error-page--link-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.error-page .error-page--link-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.error-page .error-page--link-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.error-page .error-page--link__home {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .error-page .error-page--link__home:hover, .error-page .error-page--link__home:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .error-page .error-page--link__home:active {
    color: #98121a;
  }
}
.error-page .error-page--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .error-page .error-page--link:hover .error-page--link-arrow, .error-page .error-page--link:focus .error-page--link-arrow {
    padding-left: 12px;
  }
}
@media (any-hover: none) {
  .error-page .error-page--link:active .error-page--link-arrow {
    padding-left: 12px;
  }
}

.form .form--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.form .form--fieldset-row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .form .form--fieldset-row {
  min-height: 1px;
}

.form .form--fieldset {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.form .form--container {
  padding-top: 22px;
  padding-left: 16px;
  padding-bottom: 22px;
  padding-right: 16px;
}
.form .form--container .form--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.form .form--container .form--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.form .form--fieldset-row {
  margin-top: -11px;
  margin-left: -8px;
  margin-bottom: -11px;
  margin-right: -8px;
}
.form .form--fieldset {
  padding-top: 11px;
  padding-left: 8px;
  padding-bottom: 11px;
  padding-right: 8px;
}
.form .form--fieldset {
  flex: 0 0 100%;
}
html.ie11 .form .form--fieldset {
  flex-basis: auto;
  width: 100%;
}

html.ie10 .form .form--fieldset {
  flex-basis: auto;
  width: 100%;
}

.form .form--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.form .form--field-row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .form .form--field-row {
  min-height: 1px;
}

.form .form--field {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (min-width: 1280px) {
  .form .form--container {
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 24px;
    padding-right: 16px;
  }
  .form .form--container .form--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .form .form--container .form--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .form .form--field-row {
    margin-top: -12px;
    margin-left: -8px;
    margin-bottom: -12px;
    margin-right: -8px;
  }
  .form .form--field {
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 12px;
    padding-right: 8px;
  }
}
@media (max-width: 1279px) {
  .form .form--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .form .form--container .form--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .form .form--container .form--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .form .form--field-row {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .form .form--field {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 1280px) {
  .form .form--field-row ~ .form--field-row {
    padding-top: 24px 16px/2;
  }
}
@media (max-width: 1279px) {
  .form .form--field-row ~ .form--field-row {
    padding-top: 16px 16px/2;
  }
}
.form .form--field {
  flex: 0 0 100%;
  align-self: flex-start;
}
html.ie11 .form .form--field {
  flex-basis: auto;
  width: 100%;
}

html.ie10 .form .form--field {
  flex-basis: auto;
  width: 100%;
}

.form .form--subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .form .form--subtitle {
    font-size: 2rem;
  }
}
.form .form--field.form--field__subtitle {
  padding-bottom: 0;
}

.one-col .one-col--row {
  width: 100%;
}
.one-col .one-col--main {
  flex: 0 0 100%;
}
html.ie11 .one-col .one-col--main {
  flex-basis: auto;
  width: 100%;
}

html.ie10 .one-col .one-col--main {
  flex-basis: auto;
  width: 100%;
}

@media (min-width: 1024px) {
  .one-col .one-col--main .cms-module:not(.cms-module__chart):not(.cms-module__numbers):not(.cms-module__image) .cms-module--col {
    flex: 0 0 66.6666666667%;
  }
  html.ie11 .one-col .one-col--main .cms-module:not(.cms-module__chart):not(.cms-module__numbers):not(.cms-module__image) .cms-module--col {
    flex-basis: auto;
    width: 66.6666666667%;
  }

  html.ie10 .one-col .one-col--main .cms-module:not(.cms-module__chart):not(.cms-module__numbers):not(.cms-module__image) .cms-module--col {
    flex-basis: auto;
    width: 66.6666666667%;
  }
}
@media (max-width: 1023px) {
  .one-col .one-col--main .cms-module:not(.cms-module__chart):not(.cms-module__numbers):not(.cms-module__image) .cms-module--col {
    flex: 0 0 100%;
  }
  html.ie11 .one-col .one-col--main .cms-module:not(.cms-module__chart):not(.cms-module__numbers):not(.cms-module__image) .cms-module--col {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .one-col .one-col--main .cms-module:not(.cms-module__chart):not(.cms-module__numbers):not(.cms-module__image) .cms-module--col {
    flex-basis: auto;
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .one-col {
    margin-top: 70px;
    margin-bottom: 100px;
  }
}
@media (min-width: 1280px) {
  .one-col {
    margin-top: 110px;
    margin-bottom: 140px;
  }
}

.page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 0 auto;
}
.page .page--main {
  flex: 1 0 auto;
}

.radio-tags .radio-tags--description {
  display: block;
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .radio-tags .radio-tags--description {
    margin-bottom: 24px;
  }
}
@media (min-width: 1280px) {
  .radio-tags .radio-tags--description {
    margin-bottom: 32px;
  }
}
.radio-tags .radio-tags--description.radio-tags--description__is-monochrome-black {
  color: #000000;
}
.radio-tags .radio-tags--tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.radio-tags .radio-tags--tags-item {
  width: 100%;
}
.radio-tags .radio-tags--tags-tag {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  transition: all 200ms ease;
  color: #be1621;
  background: #ffffff;
  border: 1px solid #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .radio-tags .radio-tags--tags-tag:hover, .radio-tags .radio-tags--tags-tag:focus {
    color: #ffffff;
    background: #be1621;
  }
  .radio-tags .radio-tags--tags-tag:hover .radio-tags--tags-tag-description, .radio-tags .radio-tags--tags-tag:focus .radio-tags--tags-tag-description {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .radio-tags .radio-tags--tags-tag:active {
    color: #ffffff;
    background: #be1621;
  }
  .radio-tags .radio-tags--tags-tag:active .radio-tags--tags-tag-description {
    color: #ffffff;
  }
}
.radio-tags .radio-tags--tags-tag:has(> .radio-tags--input:checked) {
  color: #ffffff;
  background: #be1621;
}
.radio-tags .radio-tags--tags-tag:has(> .radio-tags--input:checked) .radio-tags--tags-tag-description {
  color: #ffffff;
}
.radio-tags .radio-tags--input {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #be1621;
  transition: all 200ms ease;
  accent-color: #be1621;
  width: 24px;
  height: 24px;
}
.radio-tags .radio-tags--tags-tag-text {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
.radio-tags .radio-tags--tags-tag-description {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  width: 100%;
  margin-left: 36px;
  transition: all 200ms ease;
}

html.scroll-root.scroll-root__is-locked {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
html.scroll-root.scroll-root__is-locked > body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.skel {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 100%;
}
.skel .skel--main {
  z-index: 12;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
}
html[data-background-theme=light] .skel .skel--main {
  background: #ffffff;
}

html[data-background-theme=dark] .skel .skel--main {
  background: #f7f7f7;
}

.two-cols .two-cols--row {
  justify-content: space-between;
}
@media (min-width: 1556px) {
  .two-cols .two-cols--main {
    flex: 0 0 58.3333333333%;
  }
  html.ie11 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 58.3333333333%;
  }

  html.ie10 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 58.3333333333%;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .two-cols .two-cols--main {
    flex: 0 0 66.6666666667%;
  }
  html.ie11 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 66.6666666667%;
  }

  html.ie10 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .two-cols .two-cols--main {
    flex: 0 0 66.6666666667%;
  }
  html.ie11 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 66.6666666667%;
  }

  html.ie10 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .two-cols .two-cols--main {
    flex: 0 0 58.3333333333%;
  }
  html.ie11 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 58.3333333333%;
  }

  html.ie10 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 58.3333333333%;
  }
}
@media (max-width: 767px) {
  .two-cols .two-cols--main {
    flex: 0 0 100%;
  }
  html.ie11 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .two-cols .two-cols--main {
    flex-basis: auto;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .two-cols .two-cols--sidebar {
    flex: 0 0 25%;
  }
  html.ie11 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 25%;
  }

  html.ie10 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 25%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .two-cols .two-cols--sidebar {
    flex: 0 0 33.3333333333%;
  }
  html.ie11 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 33.3333333333%;
  }

  html.ie10 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .two-cols .two-cols--sidebar {
    flex: 0 0 41.6666666667%;
  }
  html.ie11 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 41.6666666667%;
  }

  html.ie10 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 41.6666666667%;
  }
}
@media (max-width: 767px) {
  .two-cols .two-cols--sidebar {
    flex: 0 0 100%;
  }
  html.ie11 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .two-cols .two-cols--sidebar {
    flex-basis: auto;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .two-cols .two-cols--sidebar {
    order: -1;
  }
}

.wizard-finished--header {
  font-size: 3.2rem;
  line-height: 1.12;
  font-family: "Argesta", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 4rem;
  line-height: 1.1;
}
@media (max-width: 1279px) {
  .wizard-finished--header {
    font-size: 2.8rem;
    line-height: 1.14;
  }
}
@media (max-width: 1279px) {
  .wizard-finished--header {
    font-size: 3.2rem;
    line-height: 1.125;
  }
}

.wizard-finished--sub-header {
  display: flex;
  align-items: center;
}

.wizard-finished--image {
  display: block;
  max-width: 100%;
  margin-bottom: 32px;
}

.wizard-finished--sub-header-icon {
  display: block;
  margin-right: 8px;
  color: #4caf62;
}

.wizard-finished--text {
  color: #7a7a7a;
}
@media (max-width: 1279px) {
  .wizard-finished--text {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .wizard-finished--text {
    margin-bottom: 24px;
  }
}

.wizard-form .wizard-form--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.wizard-form .wizard-form--fieldset-row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .wizard-form .wizard-form--fieldset-row {
  min-height: 1px;
}

.wizard-form .wizard-form--fieldset {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.wizard-form .wizard-form--container {
  padding-top: 80px;
  padding-left: 24px;
  padding-bottom: 80px;
  padding-right: 24px;
}
.wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.wizard-form .wizard-form--fieldset-row {
  margin-top: -40px;
  margin-left: -12px;
  margin-bottom: -40px;
  margin-right: -12px;
}
.wizard-form .wizard-form--fieldset {
  padding-top: 40px;
  padding-left: 12px;
  padding-bottom: 40px;
  padding-right: 12px;
}
.wizard-form .wizard-form--fieldset {
  flex: 0 0 100%;
}
html.ie11 .wizard-form .wizard-form--fieldset {
  flex-basis: auto;
  width: 100%;
}

html.ie10 .wizard-form .wizard-form--fieldset {
  flex-basis: auto;
  width: 100%;
}

.wizard-form .wizard-form--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.wizard-form .wizard-form--field-row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .wizard-form .wizard-form--field-row {
  min-height: 1px;
}

.wizard-form .wizard-form--field {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (min-width: 1280px) {
  .wizard-form .wizard-form--container {
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 24px;
    padding-right: 16px;
  }
  .wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .wizard-form .wizard-form--field-row {
    margin-top: -12px;
    margin-left: -8px;
    margin-bottom: -12px;
    margin-right: -8px;
  }
  .wizard-form .wizard-form--field {
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 12px;
    padding-right: 8px;
  }
}
@media (max-width: 1279px) {
  .wizard-form .wizard-form--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .wizard-form .wizard-form--field-row {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .wizard-form .wizard-form--field {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
.wizard-form .wizard-form--field {
  flex: 0 0 100%;
  align-self: flex-start;
}
html.ie11 .wizard-form .wizard-form--field {
  flex-basis: auto;
  width: 100%;
}

html.ie10 .wizard-form .wizard-form--field {
  flex-basis: auto;
  width: 100%;
}

@media (min-width: 576px) {
  .wizard-form .wizard-form--field.wizard-form--field__25p {
    flex: 0 0 25%;
  }
  html.ie11 .wizard-form .wizard-form--field.wizard-form--field__25p {
    flex-basis: auto;
    width: 25%;
  }

  html.ie10 .wizard-form .wizard-form--field.wizard-form--field__25p {
    flex-basis: auto;
    width: 25%;
  }
}
@media (min-width: 576px) {
  .wizard-form .wizard-form--field.wizard-form--field__50p {
    flex: 0 0 50%;
  }
  html.ie11 .wizard-form .wizard-form--field.wizard-form--field__50p {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .wizard-form .wizard-form--field.wizard-form--field__50p {
    flex-basis: auto;
    width: 50%;
  }
}
@media (min-width: 576px) {
  .wizard-form .wizard-form--field.wizard-form--field__75p {
    flex: 0 0 75%;
  }
  html.ie11 .wizard-form .wizard-form--field.wizard-form--field__75p {
    flex-basis: auto;
    width: 75%;
  }

  html.ie10 .wizard-form .wizard-form--field.wizard-form--field__75p {
    flex-basis: auto;
    width: 75%;
  }
}
.wizard-form .wizard-form--field .wizard-form--field__text {
  color: #222222;
}
.wizard-form .wizard-form--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.wizard-form .wizard-form--radio-group {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .wizard-form .wizard-form--radio-group {
  min-height: 1px;
}

.wizard-form .wizard-form--radio {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.wizard-form .wizard-form--container {
  padding-top: 4px;
  padding-left: 24px;
  padding-bottom: 4px;
  padding-right: 24px;
}
.wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.wizard-form .wizard-form--container .wizard-form--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.wizard-form .wizard-form--radio-group {
  margin-top: -2px;
  margin-left: -12px;
  margin-bottom: -2px;
  margin-right: -12px;
}
.wizard-form .wizard-form--radio {
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 2px;
  padding-right: 12px;
}
.wizard-form .wizard-form--radio-group.wizard-form--radio-group__rows {
  flex-direction: column;
}
.wizard-form .wizard-form--radio {
  min-height: 38px;
}
.wizard-form .wizard-form--subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .wizard-form .wizard-form--subtitle {
    font-size: 2rem;
  }
}
.wizard-form .wizard-form--lead {
  color: #5c5c5c;
}
.wizard-form .wizard-form--spacing {
  display: block;
}
@media (max-width: 1279px) {
  .wizard-form .wizard-form--spacing.wizard-form--spacing__small {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .wizard-form .wizard-form--spacing.wizard-form--spacing__small {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .wizard-form .wizard-form--spacing.wizard-form--spacing__xx-small {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .wizard-form .wizard-form--spacing.wizard-form--spacing__xx-small {
    margin-bottom: 8px;
  }
}
.wizard-form .wizard-form--caption {
  position: relative;
  display: block;
  margin-top: 4px;
  color: #3d3d3d;
  font-size: 1.2rem;
  line-height: 1.3;
}
.wizard-form .wizard-form--authorization-detail, .wizard-form .wizard-form--previous-education-other {
  display: none;
}
.wizard-form .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--authorization-detail, .wizard-form .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--previous-education-other {
  display: block;
}
@media (max-width: 1279px) {
  .wizard-form .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--authorization-detail, .wizard-form .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--previous-education-other {
    margin-top: 12px;
  }
}
@media (min-width: 1280px) {
  .wizard-form .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--authorization-detail, .wizard-form .ui-form-field--input:checked ~ .ui-form-field--label-wrap .wizard-form--previous-education-other {
    margin-top: 8px;
  }
}
.wizard-form .ui-form-field--input:checked ~ .ui-form-field--label-wrap.ui-form-field--label-wrap {
  max-height: none;
  line-height: 1.5;
}
.wizard-form .wizard-form--field.wizard-form--field__subtitle {
  padding-bottom: 0;
}
.wizard-form .form-submit-recaptcha {
  padding-top: 0;
}
.wizard-form .wizard-form--global-messages {
  margin-top: 24px;
  padding: 16px 16px 20px;
  background: rgba(255, 12, 62, 0.1);
}

.wizard-sidebar-choice--text {
  color: #7a7a7a;
}
.wizard-sidebar-choice--text.wizard-sidebar-choice--text__small {
  font-size: 1.2rem;
}

.wizard-sidebar-choice--list-item ~ .wizard-sidebar-choice--list-item {
  margin-top: 16px;
}

.wizard-sidebar-steps.wizard-sidebar-steps__aside {
  display: none;
}
@media (min-width: 768px) {
  .wizard-sidebar-steps.wizard-sidebar-steps__aside {
    display: block;
  }
}
.wizard-sidebar-steps.wizard-sidebar-steps__aside .wizard-sidebar-steps--path-icon {
  display: none;
}

.wizard-sidebar-steps.wizard-sidebar-steps__mobile {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  white-space: nowrap;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}
@media (max-width: 767px) {
  .wizard-sidebar-steps.wizard-sidebar-steps__mobile {
    display: block;
  }
}
.wizard-sidebar-steps.wizard-sidebar-steps__mobile .wizard-sidebar-steps--inner {
  width: 100%;
  padding: 0 24px;
}
.wizard-sidebar-steps.wizard-sidebar-steps__mobile .wizard-sidebar-steps--inner-inner {
  width: 100%;
  padding: 12px 0;
  overflow-x: scroll;
}
.wizard-sidebar-steps.wizard-sidebar-steps__mobile .wizard-sidebar-steps--list {
  display: flex;
  flex-direction: row;
}
.wizard-sidebar-steps.wizard-sidebar-steps__mobile .wizard-sidebar-steps--list-item {
  margin-top: 0;
}
.wizard-sidebar-steps.wizard-sidebar-steps__mobile .wizard-sidebar-steps--list-item ~ .wizard-sidebar-steps--list-item {
  border-left: 12px solid transparent;
}

.btn {
  border: 2px solid transparent;
  border-radius: 0;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
.btn {
  text-decoration: none;
}
.btn:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn:not(:disabled):hover, .btn:not(:disabled):focus {
    text-decoration: none;
    cursor: pointer;
  }
}
@media (any-hover: none) {
  .btn:not(:disabled):active {
    text-decoration: none;
    cursor: pointer;
  }
}
.btn:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn:not(:disabled):hover:active, .btn:not(:disabled):focus:active {
    text-decoration: none;
    cursor: pointer;
  }
}
@media (any-hover: none) {
  .btn:not(:disabled):active:active {
    text-decoration: none;
    cursor: pointer;
  }
}
.btn:disabled {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn:disabled:hover, .btn:disabled:focus {
    text-decoration: none;
    cursor: default;
  }
}
@media (any-hover: none) {
  .btn:disabled:active {
    text-decoration: none;
    cursor: default;
  }
}
.btn.btn__small {
  padding: 6px 16px;
  font-size: 1.7rem;
  line-height: 1.41;
}
.btn.btn__medium {
  padding: 14px 24px;
  font-size: 1.7rem;
  line-height: 1.41;
}
.btn.btn__primary {
  color: #ffffff;
  border-color: transparent;
  background-color: #be1621;
}
html[data-juventus-theme=brand] .btn.btn__primary {
  background-color: #be1621;
}

html[data-juventus-theme=highschool] .btn.btn__primary {
  background-color: #dd7200;
}

html[data-juventus-theme=economy] .btn.btn__primary {
  background-color: #286ab7;
}

html[data-juventus-theme=medicine] .btn.btn__primary {
  background-color: #1f9285;
}

html[data-juventus-theme=engineering] .btn.btn__primary {
  background-color: #948b85;
}

html[data-juventus-theme=medical-leadership] .btn.btn__primary {
  background-color: #771469;
}

.btn.btn__primary:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__primary:not(:disabled):hover, .btn.btn__primary:not(:disabled):focus {
    background-color: #98121a;
  }
  html[data-juventus-theme=brand] .btn.btn__primary:not(:disabled):hover, html[data-juventus-theme=brand] .btn.btn__primary:not(:disabled):focus {
    background-color: #98121a;
  }

  html[data-juventus-theme=highschool] .btn.btn__primary:not(:disabled):hover, html[data-juventus-theme=highschool] .btn.btn__primary:not(:disabled):focus {
    background-color: #b15b00;
  }

  html[data-juventus-theme=economy] .btn.btn__primary:not(:disabled):hover, html[data-juventus-theme=economy] .btn.btn__primary:not(:disabled):focus {
    background-color: #286ab7;
  }

  html[data-juventus-theme=medicine] .btn.btn__primary:not(:disabled):hover, html[data-juventus-theme=medicine] .btn.btn__primary:not(:disabled):focus {
    background-color: #19756a;
  }

  html[data-juventus-theme=engineering] .btn.btn__primary:not(:disabled):hover, html[data-juventus-theme=engineering] .btn.btn__primary:not(:disabled):focus {
    background-color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .btn.btn__primary:not(:disabled):hover, html[data-juventus-theme=medical-leadership] .btn.btn__primary:not(:disabled):focus {
    background-color: #570f4d;
  }
}
@media (any-hover: none) {
  .btn.btn__primary:not(:disabled):active {
    background-color: #98121a;
  }
  html[data-juventus-theme=brand] .btn.btn__primary:not(:disabled):active {
    background-color: #98121a;
  }

  html[data-juventus-theme=highschool] .btn.btn__primary:not(:disabled):active {
    background-color: #b15b00;
  }

  html[data-juventus-theme=economy] .btn.btn__primary:not(:disabled):active {
    background-color: #286ab7;
  }

  html[data-juventus-theme=medicine] .btn.btn__primary:not(:disabled):active {
    background-color: #19756a;
  }

  html[data-juventus-theme=engineering] .btn.btn__primary:not(:disabled):active {
    background-color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .btn.btn__primary:not(:disabled):active {
    background-color: #570f4d;
  }
}
.btn.btn__primary:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.btn.btn__primary:disabled {
  color: #bfbfbf;
  background-color: #ededed;
}
html[data-juventus-theme=brand] .btn.btn__primary:disabled {
  color: #bfbfbf;
  background-color: #ededed;
}

html[data-juventus-theme=highschool] .btn.btn__primary:disabled {
  color: #bfbfbf;
  background-color: #ededed;
}

html[data-juventus-theme=economy] .btn.btn__primary:disabled {
  color: #bfbfbf;
  background-color: #ededed;
}

html[data-juventus-theme=medicine] .btn.btn__primary:disabled {
  color: #bfbfbf;
  background-color: #ededed;
}

html[data-juventus-theme=engineering] .btn.btn__primary:disabled {
  color: #bfbfbf;
  background-color: #ededed;
}

html[data-juventus-theme=medical-leadership] .btn.btn__primary:disabled {
  color: #bfbfbf;
  background-color: #ededed;
}

.btn.btn__secondary {
  background-color: transparent;
  color: #be1621;
  border-color: #be1621;
}
html[data-juventus-theme=brand] .btn.btn__secondary {
  color: #be1621;
  border-color: #be1621;
}

html[data-juventus-theme=highschool] .btn.btn__secondary {
  color: #dd7200;
  border-color: #dd7200;
}

html[data-juventus-theme=economy] .btn.btn__secondary {
  color: #286ab7;
  border-color: #286ab7;
}

html[data-juventus-theme=medicine] .btn.btn__secondary {
  color: #1f9285;
  border-color: #1f9285;
}

html[data-juventus-theme=engineering] .btn.btn__secondary {
  color: #948b85;
  border-color: #948b85;
}

html[data-juventus-theme=medical-leadership] .btn.btn__secondary {
  color: #771469;
  border-color: #771469;
}

.btn.btn__secondary:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__secondary:not(:disabled):hover, .btn.btn__secondary:not(:disabled):focus {
    background: #f7f7f7;
    color: #98121a;
    border-color: #98121a;
  }
  html[data-juventus-theme=brand] .btn.btn__secondary:not(:disabled):hover, html[data-juventus-theme=brand] .btn.btn__secondary:not(:disabled):focus {
    color: #98121a;
    border-color: #98121a;
  }

  html[data-juventus-theme=highschool] .btn.btn__secondary:not(:disabled):hover, html[data-juventus-theme=highschool] .btn.btn__secondary:not(:disabled):focus {
    color: #b15b00;
    border-color: #b15b00;
  }

  html[data-juventus-theme=economy] .btn.btn__secondary:not(:disabled):hover, html[data-juventus-theme=economy] .btn.btn__secondary:not(:disabled):focus {
    color: #286ab7;
    border-color: #286ab7;
  }

  html[data-juventus-theme=medicine] .btn.btn__secondary:not(:disabled):hover, html[data-juventus-theme=medicine] .btn.btn__secondary:not(:disabled):focus {
    color: #19756a;
    border-color: #19756a;
  }

  html[data-juventus-theme=engineering] .btn.btn__secondary:not(:disabled):hover, html[data-juventus-theme=engineering] .btn.btn__secondary:not(:disabled):focus {
    color: #766f6a;
    border-color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .btn.btn__secondary:not(:disabled):hover, html[data-juventus-theme=medical-leadership] .btn.btn__secondary:not(:disabled):focus {
    color: #570f4d;
    border-color: #570f4d;
  }
}
@media (any-hover: none) {
  .btn.btn__secondary:not(:disabled):active {
    background: #f7f7f7;
    color: #98121a;
    border-color: #98121a;
  }
  html[data-juventus-theme=brand] .btn.btn__secondary:not(:disabled):active {
    color: #98121a;
    border-color: #98121a;
  }

  html[data-juventus-theme=highschool] .btn.btn__secondary:not(:disabled):active {
    color: #b15b00;
    border-color: #b15b00;
  }

  html[data-juventus-theme=economy] .btn.btn__secondary:not(:disabled):active {
    color: #286ab7;
    border-color: #286ab7;
  }

  html[data-juventus-theme=medicine] .btn.btn__secondary:not(:disabled):active {
    color: #19756a;
    border-color: #19756a;
  }

  html[data-juventus-theme=engineering] .btn.btn__secondary:not(:disabled):active {
    color: #766f6a;
    border-color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .btn.btn__secondary:not(:disabled):active {
    color: #570f4d;
    border-color: #570f4d;
  }
}
.btn.btn__secondary:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.btn.btn__secondary:disabled {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__secondary:disabled:hover, .btn.btn__secondary:disabled:focus {
    color: #bfbfbf;
    border-color: #ededed;
  }
  html[data-juventus-theme=brand] .btn.btn__secondary:disabled:hover, html[data-juventus-theme=brand] .btn.btn__secondary:disabled:focus {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=highschool] .btn.btn__secondary:disabled:hover, html[data-juventus-theme=highschool] .btn.btn__secondary:disabled:focus {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=economy] .btn.btn__secondary:disabled:hover, html[data-juventus-theme=economy] .btn.btn__secondary:disabled:focus {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=medicine] .btn.btn__secondary:disabled:hover, html[data-juventus-theme=medicine] .btn.btn__secondary:disabled:focus {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=engineering] .btn.btn__secondary:disabled:hover, html[data-juventus-theme=engineering] .btn.btn__secondary:disabled:focus {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=medical-leadership] .btn.btn__secondary:disabled:hover, html[data-juventus-theme=medical-leadership] .btn.btn__secondary:disabled:focus {
    color: #bfbfbf;
    border-color: #ededed;
  }
}
@media (any-hover: none) {
  .btn.btn__secondary:disabled:active {
    color: #bfbfbf;
    border-color: #ededed;
  }
  html[data-juventus-theme=brand] .btn.btn__secondary:disabled:active {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=highschool] .btn.btn__secondary:disabled:active {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=economy] .btn.btn__secondary:disabled:active {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=medicine] .btn.btn__secondary:disabled:active {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=engineering] .btn.btn__secondary:disabled:active {
    color: #bfbfbf;
    border-color: #ededed;
  }

  html[data-juventus-theme=medical-leadership] .btn.btn__secondary:disabled:active {
    color: #bfbfbf;
    border-color: #ededed;
  }
}
.btn.btn__has-grid {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn.btn__has-grid .btn--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.btn.btn__has-grid .btn--wrap {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .btn.btn__has-grid .btn--wrap {
  min-height: 1px;
}

.btn.btn__has-grid .btn--icon, .btn.btn__has-grid .btn--text {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.btn.btn__has-grid .btn--container {
  padding-left: 8px;
  padding-right: 8px;
}
.btn.btn__has-grid .btn--container .btn--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.btn.btn__has-grid .btn--container .btn--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.btn.btn__has-grid .btn--wrap {
  margin-left: -4px;
  margin-right: -4px;
}
.btn.btn__has-grid .btn--icon, .btn.btn__has-grid .btn--text {
  padding-left: 4px;
  padding-right: 4px;
}
.btn.btn__has-grid .btn--wrap {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.btn.btn__has-grid .btn--text {
  position: relative;
  transform: translateY(-1px);
  text-align: left;
}
.btn.btn__bubble {
  min-width: 0;
  border-radius: 50%;
}
.btn.btn__bubble .btn--icon {
  margin: 0;
}
.btn.btn__bubble.btn__small {
  padding: 4px;
}
.btn.btn__bubble.btn__medium {
  padding: 14px;
}

.trs-generic-page-fade-enter-active, .trs-generic-page-fade-leave-active {
  transition: opacity 200ms ease;
}
.trs-generic-page-fade-enter, .trs-generic-page-fade-leave-to {
  opacity: 0;
}

.trs-side-panel-enter-active, .trs-side-panel-leave-active {
  transition: transform 200ms ease;
  transform: translate(0, 0);
}
.trs-side-panel-enter, .trs-side-panel-leave-to {
  transform: translate(100%, 0);
}

.trs-simple-fade-enter-active, .trs-simple-fade-leave-active {
  transition: opacity 200ms ease;
}
.trs-simple-fade-enter, .trs-simple-fade-leave-to {
  opacity: 0;
}

.ui-form-field.ui-form-field__base {
  display: flex;
  flex-flow: column nowrap;
}
.ui-form-field.ui-form-field__base .ui-form-field--input-container {
  position: relative;
  display: flex;
}
.ui-form-field.ui-form-field__base .ui-form-field--title-label {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 1.7rem;
  margin-bottom: 8px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  display: flex;
  line-height: 1.41;
}
.ui-form-field.ui-form-field__base .ui-form-field--title-label {
  color: #000000;
}
.ui-form-field.ui-form-field__base:not(.ui-form-field__is-required) .ui-form-field--title-label::after {
  content: "  (Optional)";
}
.ui-form-field.ui-form-field__base.ui-form-field__hidden {
  display: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input-container {
  position: relative;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--title-label {
  cursor: pointer;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
  margin-bottom: 0;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input {
  display: inline-block;
  width: 100%;
  padding: 14px 16px 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  line-height: 1.3;
  cursor: text;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-moz-placeholder {
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input:-ms-input-placeholder {
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-webkit-input-placeholder {
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::placeholder {
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input {
  color: #222222;
  background-color: #ffffff;
  border-color: #bfbfbf;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-moz-placeholder {
  opacity: 1;
  color: #7a7a7a;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input:-ms-input-placeholder {
  opacity: 1;
  color: #7a7a7a;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-webkit-input-placeholder {
  opacity: 1;
  color: #7a7a7a;
}
.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::placeholder {
  opacity: 1;
  color: #7a7a7a;
}
.ui-form-field.ui-form-field__base.ui-form-field__input:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--title-label {
  color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__input:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--input {
  border-color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label {
  cursor: default;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label {
  -webkit-touch-callout: none;
  user-select: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label::selection, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label ::selection {
  background-color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input {
  color: #bfbfbf;
  background-color: #ededed;
  border-color: #f7f7f7;
  cursor: default;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input {
  -webkit-touch-callout: none;
  user-select: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::selection, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input ::selection {
  background-color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::-moz-placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input:-ms-input-placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::-webkit-input-placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__has-error.ui-form-field .ui-form-field--input {
  border-color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__has-error.ui-form-field .ui-form-field--title-label {
  color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__textarea .ui-form-field--input {
  max-width: 100%;
  min-height: 120px;
  resize: vertical;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  overflow: hidden;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--action.ui-form-field--action {
  pointer-events: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input {
  cursor: pointer;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--action {
  color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--action {
  color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select.ui-form-field__is-disabled.ui-form-field .ui-form-field--action {
  color: #5c5c5c;
  cursor: default;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input {
  color: #7a7a7a;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input option, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input optgroup {
  color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input::-ms-value {
  color: #f7f7f7;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--action {
  color: #7a7a7a;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled).ui-form-field__is-disabled .ui-form-field--input {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input-container[data-action-count="1"] .ui-form-field--input {
  padding-right: 44px;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input-container[data-action-count="2"] .ui-form-field--input {
  padding-right: 72px;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input-container[data-action-count="3"] .ui-form-field--input {
  padding-right: 100px;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--actions {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 8px;
  padding-left: 16px;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--action {
  pointer-events: auto;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 100%;
  padding: 14px 0 14px;
  cursor: pointer;
  transition: color 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input-wrapper {
  position: relative;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--empty-label {
  transition: visibility 0ms linear 200ms, opacity 200ms ease 0ms;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  position: relative;
  padding: 16px 16px 14px;
  color: #7a7a7a;
  text-align: center;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--empty-btn {
  transition: visibility 0ms linear 200ms, opacity 200ms ease 0ms;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 20px;
  padding: 12px;
  transform: translate(0, -50%);
  background-color: #ffffff;
  cursor: pointer;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 28px 16px 24.5px 80px;
  cursor: pointer;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input::file-selector-button, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input::-webkit-file-upload-button {
  appearance: none;
  visibility: hidden;
  width: 0;
  padding: 0;
  border: none;
  user-select: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-filled .ui-form-field--empty-btn {
  transition: visibility 0ms linear 0ms, opacity 200ms ease 0ms;
  visibility: inherit;
  opacity: 1;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-filled .ui-form-field--input {
  border-style: solid;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-empty .ui-form-field--empty-label {
  transition: visibility 0ms linear 0ms, opacity 200ms ease 0ms;
  visibility: inherit;
  opacity: 1;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-empty .ui-form-field--input {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input {
  color: #222222;
  background-color: #f7f7f7;
  border-color: #7a7a7a;
  border-style: dashed;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input::-moz-placeholder {
  color: #f7f7f7;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input:-ms-input-placeholder {
  color: #f7f7f7;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input::-webkit-input-placeholder {
  color: #f7f7f7;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file .ui-form-field--input::placeholder {
  color: #f7f7f7;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--title-label {
  color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--empty-label {
  color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--input {
  border-color: #000000;
  border-style: solid;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label {
  cursor: default;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label {
  -webkit-touch-callout: none;
  user-select: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label::selection, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label ::selection {
  background-color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input {
  color: #bfbfbf;
  background-color: #ededed;
  border-color: #f7f7f7;
  border-style: solid;
  cursor: default;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input {
  -webkit-touch-callout: none;
  user-select: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::selection, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input ::selection {
  background-color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::-moz-placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input:-ms-input-placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::-webkit-input-placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::placeholder {
  color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__has-error.ui-form-field .ui-form-field--input {
  border-color: #ff0c3e;
  border-style: solid;
}
.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__file.ui-form-field__has-error.ui-form-field .ui-form-field--title-label {
  color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool {
  font-size: 17px;
  line-height: 0;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool input[type=radio].ui-form-field--input,
.ui-form-field.ui-form-field__base.ui-form-field__bool input[type=checkbox].ui-form-field--input {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input-container {
  display: flex;
  flex: 0 0 auto;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--title-label {
  cursor: auto;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--label-wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  cursor: pointer;
  max-height: 27px;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--label {
  padding: 0 0 0 8px;
  color: #000000;
  transform: translateY(-6.5px);
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 27px;
  height: 27px;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box {
  -webkit-touch-callout: none;
  user-select: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::selection, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box ::selection {
  background-color: transparent;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::before {
  z-index: 1;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::after {
  z-index: 2;
  border-width: 1px;
  border-style: solid;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box-icon {
  pointer-events: none;
  z-index: 3;
  opacity: 0;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__multiline .ui-form-field--label-wrap {
  max-height: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__multiline .ui-form-field--label {
  flex: 0 1 100%;
  line-height: 1.5;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--title-label {
  color: #222222;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  opacity: 0;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box {
  background-color: #ffffff;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #7a7a7a;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  color: #5c5c5c;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):focus .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):focus ~ .ui-form-field--label-wrap .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:focus .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:focus ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  opacity: 1;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):focus .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):focus ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:focus .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:focus ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):active .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):active ~ .ui-form-field--label-wrap .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:active .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:active ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  opacity: 1;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):active .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):active ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:active .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:active ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:not(:hover) .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:not(:hover) ~ .ui-form-field--label-wrap .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:not(:hover) .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:not(:hover) ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  opacity: 1;
  color: #ffffff;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:not(:hover) .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:not(:hover) ~ .ui-form-field--label-wrap .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:not(:hover) .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:not(:hover) ~ .ui-form-field--label-wrap .ui-form-field--box {
  background-color: #5c5c5c;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:not(:hover) .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:not(:hover) ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:not(:hover) .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:not(:hover) ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #5c5c5c;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:hover .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:hover ~ .ui-form-field--label-wrap .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:hover .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:hover ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  opacity: 1;
  color: #ffffff;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:hover .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:hover ~ .ui-form-field--label-wrap .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:hover .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:hover ~ .ui-form-field--label-wrap .ui-form-field--box {
  background-color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:hover .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:hover ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:hover .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:hover ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #000000;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap {
  cursor: default;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--label, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--label {
  cursor: auto;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box {
  background-color: #bfbfbf;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #bfbfbf;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  opacity: 1;
  color: #ffffff;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  color: #d1d1d1;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--title-label {
  color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--label-wrap {
  color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input .ui-form-field--label, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box {
  background-color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap {
  cursor: default;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--label, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--label {
  cursor: auto;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box {
  background-color: #bfbfbf;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #bfbfbf;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  opacity: 1;
  color: #ffffff;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked .ui-form-field--box-icon, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  color: #d1d1d1;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  border-radius: 50%;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: currentColor;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  border-radius: 0;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  border-radius: 50%;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox.ui-form-field__wizard .ui-form-field--label-wrap {
  align-items: center;
}
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox.ui-form-field__wizard .ui-form-field--label {
  transform: none;
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label {
  transform: rotateZ(1turn);
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label .ui-form-field--input,
.ui-form-field.ui-form-field__base.ui-form-field__floating-label .ui-form-field--floating-label {
  padding-top: 21px;
  padding-bottom: 7px;
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label .ui-form-field--floating-label {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  pointer-events: none;
  position: absolute;
  font-size: 1.6rem;
  line-height: 1.2;
  top: 0;
  left: 17px;
  visibility: visible;
  backface-visibility: hidden;
  transform-origin: 0 50%;
  transition: transform 200ms ease, color 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label .ui-form-field--title-label {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label .ui-form-field--floating-label {
  color: #222222;
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label.ui-form-field__is-disabled.ui-form-field .ui-form-field--floating-label {
  color: #d1d1d1;
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label.ui-form-field__has-error.ui-form-field .ui-form-field--floating-label {
  color: #ff0c3e;
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label:not(.ui-form-field__is-required) .ui-form-field--floating-label::after {
  content: "  (Optional)";
}
.ui-form-field.ui-form-field__base.ui-form-field__floating-label.ui-form-field__is-floating .ui-form-field--floating-label {
  transform: translate(0, -33%) scale(0.675);
}
.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--input-container[data-action-count="1"] .ui-form-field--input {
  padding-right: 44px;
}
.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--input-container[data-action-count="2"] .ui-form-field--input {
  padding-right: 72px;
}
.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--input-container[data-action-count="3"] .ui-form-field--input {
  padding-right: 100px;
}
.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--actions {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 8px;
  padding-left: 16px;
}
.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--action {
  pointer-events: auto;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 100%;
  padding: 14px 0 14px;
  cursor: pointer;
  transition: color 200ms ease;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--row {
  min-height: 1px;
}

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--input-container {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container {
  padding-top: 8px;
  padding-left: 20px;
  padding-bottom: 8px;
  padding-right: 20px;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container .ui-form-field--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container .ui-form-field--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--row {
  margin-top: -4px;
  margin-left: -10px;
  margin-bottom: -4px;
  margin-right: -10px;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--input-container {
  padding-top: 4px;
  padding-left: 10px;
  padding-bottom: 4px;
  padding-right: 10px;
}
.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--list-item {
  width: 100%;
}
.ui-form-field.ui-form-field__base.ui-form-field__list.ui-form-field__vertical .ui-form-field--row {
  flex-flow: column nowrap;
}
.ui-form-field.hide-optional.hide-optional .ui-form-field--title-label::after {
  content: "";
}

.ui-icon {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.ui-icon.ui-icon__x-small {
  width: 10px;
  height: 10px;
}
.ui-icon.ui-icon__small {
  width: 16px;
  height: 16px;
}
.ui-icon.ui-icon__medium {
  width: 24px;
  height: 24px;
}
.ui-icon.ui-icon__large {
  width: 40px;
  height: 40px;
}

.ui-overlay .ui-overlay--root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ui-overlay .ui-overlay--backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.ui-overlay .ui-overlay--display {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  height: 100%;
}
.ui-overlay .ui-overlay--backdrop-click-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.ui-overlay .ui-overlay--wrap-outer {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.ui-overlay .ui-overlay--wrap-outer {
  -webkit-touch-callout: text;
  user-select: text;
}
.ui-overlay .ui-overlay--wrap-outer::selection, .ui-overlay .ui-overlay--wrap-outer ::selection {
  background-color: #accef7;
}
.ui-overlay .ui-overlay--wrap-inner {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.ui-overlay .ui-overlay--container {
  z-index: 2;
  position: relative;
  width: 100%;
}
.ui-overlay .ui-overlay--revealer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-align: left;
}
.ui-overlay.ui-overlay__base .ui-overlay--root {
  z-index: 21;
}
.ui-overlay.ui-overlay__choice {
  pointer-events: none;
}
.ui-overlay.ui-overlay__choice .ui-overlay--display {
  overflow: hidden;
}
.ui-overlay.ui-overlay__choice .ui-overlay--component {
  pointer-events: auto;
}
.ui-overlay.ui-overlay__choice .ui-overlay--backdrop-click-area {
  pointer-events: auto;
}
.ui-overlay.ui-overlay__choice .ui-overlay--root {
  z-index: 21;
}

.anchor-placeholder {
  position: relative;
}
.anchor-placeholder .anchor-placeholder--title {
  font-size: 1.7rem;
  margin-bottom: 8px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #be1621;
}
html[data-juventus-theme=brand] .anchor-placeholder .anchor-placeholder--title {
  color: #be1621;
}

html[data-juventus-theme=highschool] .anchor-placeholder .anchor-placeholder--title {
  color: #dd7200;
}

html[data-juventus-theme=economy] .anchor-placeholder .anchor-placeholder--title {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .anchor-placeholder .anchor-placeholder--title {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .anchor-placeholder .anchor-placeholder--title {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .anchor-placeholder .anchor-placeholder--title {
  color: #771469;
}

.anchor-placeholder .anchor-placeholder--hook {
  position: absolute;
  left: 0;
}
@media (max-width: 1279px) {
  .anchor-placeholder .anchor-placeholder--hook {
    top: -62px;
  }
}
@media (min-width: 1280px) {
  .anchor-placeholder .anchor-placeholder--hook {
    top: -70px;
  }
}
.anchor-placeholder.anchor-placeholder__brand .anchor-placeholder--title {
  color: #be1621;
}
.anchor-placeholder.anchor-placeholder__highschool .anchor-placeholder--title {
  color: #dd7200;
}
.anchor-placeholder.anchor-placeholder__economy .anchor-placeholder--title {
  color: #286ab7;
}
.anchor-placeholder.anchor-placeholder__medicine .anchor-placeholder--title {
  color: #1f9285;
}
.anchor-placeholder.anchor-placeholder__engineering .anchor-placeholder--title {
  color: #948b85;
}
.anchor-placeholder.anchor-placeholder__medical-leadership .anchor-placeholder--title {
  color: #771469;
}

.annual-report-numbers {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 1024px) {
  .annual-report-numbers {
    grid-template-columns: 1fr;
  }
}
.annual-report-numbers .annual-report-numbers--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value,
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-description {
  display: inline-block;
  width: auto;
}
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value {
  color: #be1621;
  font-weight: 500;
  font-size: calc(90px + (120 - 90) * ((100vw - 320px) / (768 - 320)));
}
@media (max-width: 767px) {
  .annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value {
    font-size: 90px;
  }
}
@media (min-width: 768px) {
  .annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value {
    font-size: 120px;
  }
}
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-description {
  color: #5c5c5c;
  font-size: 17px;
}

.article-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.article-detail .article-detail--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.article-detail .article-detail--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .article-detail .article-detail--list {
  min-height: 1px;
}

.article-detail .article-detail--tile {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .article-detail .article-detail--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .article-detail .article-detail--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .article-detail .article-detail--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .article-detail .article-detail--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .article-detail .article-detail--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .article-detail .article-detail--tile {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .article-detail .article-detail--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .article-detail .article-detail--tile {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .article-detail .article-detail--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .article-detail .article-detail--tile {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .article-detail .article-detail--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .article-detail .article-detail--tile {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.article-detail .article-detail--two-cols {
  width: 66%;
}
@media (max-width: 1024px) {
  .article-detail .article-detail--two-cols {
    width: 100%;
  }
}
.article-detail .article-detail--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 4rem;
  line-height: 1.1;
  color: #000000;
}
@media (max-width: 1279px) {
  .article-detail .article-detail--title {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .article-detail .article-detail--title {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .article-detail .article-detail--title {
    font-size: 3.2rem;
    line-height: 1.125;
  }
}
.article-detail .article-detail--lead {
  color: #5c5c5c;
}
.article-detail .article-detail--info {
  display: flex;
  align-items: center;
  padding: 18px 0 40px;
}
.article-detail .article-detail--info .article-detail--article-info {
  display: flex;
}
.article-detail .article-detail--info .article-detail--article-info .article-detail--article-author {
  margin-right: 8px;
  color: #1f1f1f;
}
.article-detail .article-detail--info .article-detail--article-info .article-detail--article-author::after {
  content: "•";
  margin: 0 5px;
  color: #be1621;
}
.article-detail .article-detail--info .article-detail--article-info .article-detail--article-published {
  color: #1f1f1f;
}
.article-detail .article-detail--info .article-detail--article-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background color 200ms ease;
  color: #ffffff;
  background: #be1621;
  margin-right: 30px;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .article-detail .article-detail--info .article-detail--article-tag {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.article-detail .article-detail--image-container {
  width: 100%;
}
@media (max-width: 1024px) {
  .article-detail .article-detail--image-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    max-width: none;
    overflow-x: hidden;
  }
}
.article-detail .article-detail--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-detail .article-detail--image-credits {
  margin-top: 10px;
  font-size: 12px;
  color: #7a7a7a;
}
.article-detail .article-detail--richtext {
  margin-top: 65px;
  /* stylelint-disable plugin/selector-bem-pattern */
  /* stylelint-enable plugin/selector-bem-pattern */
}
.article-detail .article-detail--richtext h1,
.article-detail .article-detail--richtext h2,
.article-detail .article-detail--richtext h3 {
  margin-top: 65px;
}
.article-detail .article-detail--richtext .image {
  margin: 65px 0;
}
.article-detail .article-detail--related-articles {
  margin-top: 80px;
}

.article-detail--sub-navigation {
  display: flex;
  margin-top: 30px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.article-detail--sub-navigation .article-detail--exit-arrow-label {
  color: #be1621;
  font-weight: 500;
}
.article-detail--sub-navigation .article-detail--exit-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 20px;
  margin-bottom: 4px;
}
.article-detail--sub-navigation .article-detail--exit-arrow {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  height: 1px;
  width: 32px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  color: #be1621;
}
.article-detail--sub-navigation .article-detail--exit-arrow::after, .article-detail--sub-navigation .article-detail--exit-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
}
.article-detail--sub-navigation .article-detail--exit-arrow::after {
  transform: translate(-4px, -50%) rotate(-45deg);
  transition: left 200ms ease;
  border-left: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 8px;
  height: 8px;
}
.article-detail--sub-navigation .article-detail--exit-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(-5px, -50%);
  transition: width 200ms ease, left 200ms ease;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .article-detail--sub-navigation:hover .article-detail--exit-arrow, .article-detail--sub-navigation:focus .article-detail--exit-arrow {
    color: #98121a;
  }
  .article-detail--sub-navigation:hover .article-detail--exit-arrow::after, .article-detail--sub-navigation:focus .article-detail--exit-arrow::after {
    left: -15px;
  }
  .article-detail--sub-navigation:hover .article-detail--exit-arrow::before, .article-detail--sub-navigation:focus .article-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .article-detail--sub-navigation:hover .article-detail--exit-arrow-label, .article-detail--sub-navigation:focus .article-detail--exit-arrow-label {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .article-detail--sub-navigation:active .article-detail--exit-arrow {
    color: #98121a;
  }
  .article-detail--sub-navigation:active .article-detail--exit-arrow::after {
    left: -15px;
  }
  .article-detail--sub-navigation:active .article-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .article-detail--sub-navigation:active .article-detail--exit-arrow-label {
    color: #98121a;
  }
}

.big-footer {
  color: #7a7a7a;
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  font-size: 1.5rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .big-footer {
    padding: 24px 0;
  }
}
@media (min-width: 1280px) {
  .big-footer {
    padding: 32px 0;
  }
}
@media (min-width: 1280px) {
  .big-footer .big-footer--col {
    flex: 0 0 25%;
  }
  html.ie11 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 25%;
  }

  html.ie10 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 25%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .big-footer .big-footer--col {
    flex: 0 0 50%;
  }
  html.ie11 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .big-footer .big-footer--col {
    flex: 0 0 100%;
  }
  html.ie11 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .big-footer .big-footer--col {
    margin-top: 24px;
  }
}
@media (max-width: 1279px) {
  .big-footer .big-footer--col {
    margin-top: 20px;
  }
}
.big-footer .big-footer--text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
}
@media (min-width: 768px) {
  .big-footer .big-footer--text {
    height: 100%;
  }
}
.big-footer .big-footer--link {
  color: #5c5c5c;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .big-footer .big-footer--link:hover, .big-footer .big-footer--link:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .big-footer .big-footer--link:active {
    color: #000000;
  }
}
@media (min-width: 768px) {
  .big-footer .big-footer--row {
    padding-left: 53px;
  }
}
@media (max-width: 767px) {
  .big-footer .big-footer--row {
    padding-left: 0;
  }
}
.big-footer .big-footer--link.big-footer--link__menu {
  display: inline-block;
  padding: 8px 0;
}
@media (max-width: 767px) {
  .big-footer .big-footer--link.big-footer--link__menu-in-text {
    display: inline-block;
    padding-top: 16px;
  }
}

.chart-legend.chart-legend--donut .chart-legend--item-description {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 280px));
  justify-items: start;
  align-items: flex-start;
  gap: 5px;
  margin-top: 32px;
}
@media (max-width: 1279px) {
  .chart-legend.chart-legend--donut .chart-legend--item-description {
    grid-template-columns: 1fr;
  }
}
.chart-legend.chart-legend--donut .chart-legend--item-description .chart-legend--item-description-wrapper {
  width: auto;
  justify-self: start;
}
.chart-legend.chart-legend--donut .chart-legend--item-description .chart-legend--item-description-color {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.chart-legend.chart-legend--donut .chart-legend--item-description .chart-legend--item-description-text {
  display: inline-block;
  font-size: 13px;
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-vertical {
  width: 100%;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--item {
  line-height: 1.41;
  font-size: 12px;
  letter-spacing: 0.01rem;
  margin-bottom: 5px;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--text {
  display: inline-block;
  font-size: 12px;
  color: #5c5c5c;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--text:first-child {
  color: #000000;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--text:last-child::before {
  content: "=";
}
.chart-legend.chart-legend--bar-vertical-stacked {
  width: 100%;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description .chart-legend--item-description-wrapper:not(:first-child) {
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description .chart-legend--item-description-color {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description .chart-legend--item-description-text {
  display: inline-block;
  font-size: 13px;
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item {
  line-height: 1.41;
  font-size: 12px;
  letter-spacing: 0.01rem;
  margin-bottom: 5px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--text {
  display: inline-block;
  font-size: 12px;
  color: #5c5c5c;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--text:first-child {
  color: #000000;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--text:last-child::before {
  content: "=";
}
.chart-legend.chart-legend--bar-horizontal-stacked {
  width: 100%;
  margin-top: -20px;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description .chart-legend--item-description-wrapper:not(:first-child) {
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description .chart-legend--item-description-color {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description .chart-legend--item-description-text {
  display: inline-block;
  font-size: 13px;
  margin-left: 10px;
}

.chart--container-donut {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
@media (max-width: 1023px) {
  .chart--container-donut {
    grid-template-columns: 1fr;
  }
  .chart--container-donut .chart--container-donut-content:not(:last-child) {
    margin-bottom: 32px;
  }
}

.chart--container-bar-vertical .chart--container-bar-vertical-content {
  overflow-x: scroll;
}

.chart--container-bar-vertical-stacked .chart--container-bar-vertical-stacked-content {
  overflow-x: scroll;
}

.chart--container-bar-horizontal-stacked .chart--container-bar-horizontal-stacked-content {
  overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chart--container-bar-vertical-content::-webkit-scrollbar,
.chart--container-bar-vertical-stacked-content::-webkit-scrollbar,
.chart--container-bar-horizontal-stacked-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.chart--container-bar-vertical-content,
.chart--container-bar-vertical-stacked-content,
.chart--container-bar-horizontal-stacked-content {
  scrollbar-width: none;
}

.contact-detail--legal-guardian-form {
  margin-top: 24px;
  padding: 16px;
  margin-bottom: -56px;
  border: 1px solid #be1621;
  color: #be1621;
}
html[data-juventus-theme=brand] .contact-detail--legal-guardian-form {
  border: 1px solid #be1621;
  color: #be1621;
}

html[data-juventus-theme=highschool] .contact-detail--legal-guardian-form {
  border: 1px solid #dd7200;
  color: #dd7200;
}

html[data-juventus-theme=economy] .contact-detail--legal-guardian-form {
  border: 1px solid #286ab7;
  color: #286ab7;
}

html[data-juventus-theme=medicine] .contact-detail--legal-guardian-form {
  border: 1px solid #1f9285;
  color: #1f9285;
}

html[data-juventus-theme=engineering] .contact-detail--legal-guardian-form {
  border: 1px solid #948b85;
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .contact-detail--legal-guardian-form {
  border: 1px solid #771469;
  color: #771469;
}

.contact-detail--description {
  margin-bottom: 8px;
}

.contact-detail--legal-guardian-upload {
  margin: 0 -8px -8px;
}

.contact-map .contact-map--wrap {
  background: #f7f7f7;
  padding-bottom: 24px;
}
@media (min-width: 1024px) {
  .contact-map .contact-map--wrap {
    padding: 32px;
  }
}
.contact-map .contact-map--lead {
  color: #5c5c5c;
  font-size: 2.2rem;
  line-height: 1.31;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .contact-map .contact-map--lead {
    font-size: 2rem;
    line-height: 1.35;
  }
}
@media (max-width: 1279px) {
  .contact-map .contact-map--row ~ .contact-map--row {
    margin-top: 24px;
  }
}
@media (min-width: 1280px) {
  .contact-map .contact-map--row ~ .contact-map--row {
    margin-top: 32px;
  }
}
.contact-map .contact-map--iframe-wrap {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
}
@media (max-width: 575px) {
  .contact-map .contact-map--iframe-wrap {
    padding-bottom: 100%;
  }
}
.contact-map .contact-map--iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1279px) {
  .contact-map .contact-map--row.contact-map--row__intro {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .contact-map .contact-map--row.contact-map--row__intro {
    margin-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .contact-map .contact-map--row.contact-map--row__text {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.cookie-monster {
  z-index: 20;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
  padding: 16px 0;
  width: 100%;
}
.cookie-monster .cookie-monster--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.cookie-monster .cookie-monster--row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .cookie-monster .cookie-monster--row {
  min-height: 1px;
}

.cookie-monster .cookie-monster--col {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--container {
    max-width: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookie-monster .cookie-monster--container {
    max-width: 512px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-monster .cookie-monster--container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .cookie-monster .cookie-monster--container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .cookie-monster .cookie-monster--container {
    max-width: 1280px;
  }
}
@media (min-width: 1556px) {
  .cookie-monster .cookie-monster--container {
    max-width: 1556px;
  }
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 22px;
    padding-right: 22px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -16px;
    margin-right: -16px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 28px;
    padding-right: 28px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.cookie-monster .cookie-monster--title {
  display: none;
}
.cookie-monster .cookie-monster--content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .cookie-monster .cookie-monster--content {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--content {
    flex-wrap: wrap;
  }
}
.cookie-monster .cookie-monster--text a {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--text a:hover, .cookie-monster .cookie-monster--text a:focus {
    text-decoration: underline;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--text a:active {
    text-decoration: underline;
  }
}
@media (min-width: 576px) {
  .cookie-monster .cookie-monster--btn-group {
    flex: 1 0 auto;
    padding-left: 16px;
  }
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--btn-group {
    margin-top: 24px;
    flex: 1 1 100%;
  }
}
.cookie-monster .cookie-monster--btn-wrapper {
  display: none;
  justify-content: flex-end;
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--btn-wrapper {
    justify-content: center;
  }
}
.cookie-monster .cookie-monster--btn-wrapper:nth-child(2n) {
  display: flex;
}
.cookie-monster .cookie-monster--btn {
  background: #be1621;
  color: #ffffff;
  padding: 6px 16px;
  font-size: 1.7rem;
  line-height: 1.41;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.cookie-monster .cookie-monster--btn {
  border: 2px solid transparent;
  border-radius: 0;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
.cookie-monster .cookie-monster--btn {
  text-decoration: none;
}
.cookie-monster .cookie-monster--btn:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):hover, .cookie-monster .cookie-monster--btn:not(:disabled):focus {
    text-decoration: none;
    cursor: pointer;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):active {
    text-decoration: none;
    cursor: pointer;
  }
}
.cookie-monster .cookie-monster--btn:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):hover:active, .cookie-monster .cookie-monster--btn:not(:disabled):focus:active {
    text-decoration: none;
    cursor: pointer;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):active:active {
    text-decoration: none;
    cursor: pointer;
  }
}
.cookie-monster .cookie-monster--btn:disabled {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:disabled:hover, .cookie-monster .cookie-monster--btn:disabled:focus {
    text-decoration: none;
    cursor: default;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:disabled:active {
    text-decoration: none;
    cursor: default;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:hover, .cookie-monster .cookie-monster--btn:focus {
    background: #98121a;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:active {
    background: #98121a;
  }
}

@media (max-width: 1279px) {
  .faq .faq--item ~ .faq--item {
    padding: 8px 0 0;
  }
}
@media (min-width: 1280px) {
  .faq .faq--item ~ .faq--item {
    padding: 8px 0 0;
  }
}

.grid-annual-report .grid-annual-report--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-annual-report .grid-annual-report--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-annual-report .grid-annual-report--list {
  min-height: 1px;
}

.grid-annual-report .grid-annual-report--item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .grid-annual-report {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.grid-annual-report .grid-annual-report--item {
  height: 100%;
  margin-bottom: 24px;
  max-width: 376px;
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item {
    flex: 0 0 50%;
  }
  html.ie11 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-annual-report .grid-annual-report--item {
    flex: 0 0 100%;
  }
  html.ie11 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 32px;
  }
}
.grid-annual-report .grid-annual-report--link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 228px;
  background: #f7f7f7;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 390px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 400px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 410px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 423px;
  }
}
@media (min-width: 1556px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 423px;
  }
}
html[data-juventus-theme=brand] .grid-annual-report .grid-annual-report--link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-annual-report .grid-annual-report--link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-annual-report .grid-annual-report--link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-annual-report .grid-annual-report--link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-annual-report .grid-annual-report--link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-annual-report .grid-annual-report--link {
  color: #771469;
}

.grid-annual-report .grid-annual-report--item-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item-image {
    max-height: 250px;
  }
}
@media (max-width: 767px) {
  .grid-annual-report .grid-annual-report--item-image {
    max-height: 218px;
  }
}
.grid-annual-report .grid-annual-report--item-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  border: 1px solid #ededed;
  border-top: none;
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item-text {
    min-height: 172px;
  }
}
@media (max-width: 767px) {
  .grid-annual-report .grid-annual-report--item-text {
    min-height: 172px;
  }
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #be1621;
}
@media (max-width: 1279px) {
  .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
    font-size: 2rem;
  }
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  position: relative;
  left: -10px;
  margin-left: 0;
  color: #be1621;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::after, .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-annual-report .grid-annual-report--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-annual-report .grid-annual-report--link:hover, .grid-annual-report .grid-annual-report--link:focus {
    background: #be1621;
  }
  .grid-annual-report .grid-annual-report--link:hover .grid-annual-report--item-text, .grid-annual-report .grid-annual-report--link:focus .grid-annual-report--item-text {
    border: 1px solid transparent;
    border-top: none;
  }
  .grid-annual-report .grid-annual-report--link:hover .grid-annual-report--item-title, .grid-annual-report .grid-annual-report--link:focus .grid-annual-report--item-title {
    color: #ffffff;
  }
  .grid-annual-report .grid-annual-report--link:hover .grid-annual-report--item-arrow, .grid-annual-report .grid-annual-report--link:focus .grid-annual-report--item-arrow {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-annual-report .grid-annual-report--link:active {
    background: #be1621;
  }
  .grid-annual-report .grid-annual-report--link:active .grid-annual-report--item-text {
    border: 1px solid transparent;
    border-top: none;
  }
  .grid-annual-report .grid-annual-report--link:active .grid-annual-report--item-title {
    color: #ffffff;
  }
  .grid-annual-report .grid-annual-report--link:active .grid-annual-report--item-arrow {
    color: #ffffff;
  }
}

@media (min-width: 1556px), (min-width: 1280px) and (max-width: 1555px), (min-width: 1024px) and (max-width: 1279px) {
  .two-cols .grid-articles .grid-articles--tile {
    flex: 0 0 50%;
  }
  html.ie11 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023px), (max-width: 767px) {
  .two-cols .grid-articles .grid-articles--tile {
    flex: 0 0 100%;
  }
  html.ie11 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }
}

.grid-articles {
  width: 100%;
}
.grid-articles .grid-articles--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-articles .grid-articles--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-articles .grid-articles--list {
  min-height: 1px;
}

.grid-articles .grid-articles--tile {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .grid-articles .grid-articles--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-articles .grid-articles--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-articles .grid-articles--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .grid-articles .grid-articles--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.grid-articles .grid-articles--list {
  grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
  grid-auto-rows: 1fr;
}
.grid-articles .grid-articles--tile {
  overflow: hidden;
}
@media (min-width: 1556px), (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile {
    flex: 0 0 33.3333333333%;
  }
  html.ie11 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 33.3333333333%;
  }

  html.ie10 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile {
    flex: 0 0 50%;
  }
  html.ie11 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .grid-articles .grid-articles--tile {
    flex: 0 0 100%;
  }
  html.ie11 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }
}
.grid-articles .grid-articles--tile-title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-articles .grid-articles--tile-title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-title {
    font-size: 2rem;
  }
}
.grid-articles .grid-articles--dark-text {
  color: #000000;
  transition: color 200ms ease;
}
.grid-articles .grid-articles--tile-link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  background: #f7f7f7;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
.ie11 .grid-articles .grid-articles--tile-link {
  word-break: break-all;
}

html[data-juventus-theme=brand] .grid-articles .grid-articles--tile-link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-articles .grid-articles--tile-link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-articles .grid-articles--tile-link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-articles .grid-articles--tile-link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-articles .grid-articles--tile-link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-articles .grid-articles--tile-link {
  color: #771469;
}

.grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #ededed;
}
@media (max-width: 575px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 40px;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-container {
  height: calc(100% - 250px);
  border-style: none solid solid;
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container {
    height: calc(100% - 214px);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container {
    height: calc(100% - 192px);
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
  height: 100%;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-figure {
  height: 250px;
  width: 100%;
  overflow: hidden;
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-figure {
    height: 214px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-figure {
    height: 192px;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-title,
.grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
  transition: color 200ms ease;
  flex: 0 0 100%;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-title,
.grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #7a7a7a;
  font-weight: 500;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
    font-size: 2rem;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin {
  display: flex;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container .grid-articles--tile-article-info,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin .grid-articles--tile-article-info {
  position: relative;
  color: #1f1f1f;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container .grid-articles--tile-article-info:first-child::after,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin .grid-articles--tile-article-info:first-child::after {
  content: "•";
  position: relative;
  margin-left: 5px;
  color: #be1621;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container .grid-articles--tile-article-info:last-child,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin .grid-articles--tile-article-info:last-child {
  margin-left: 8px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin {
  margin-top: 30px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background color 200ms ease;
  color: #ffffff;
  background: #be1621;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-tag {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-tag:hover {
  background: #ffffff;
  color: #be1621;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-footer {
  display: flex;
  flex: 0 0 100%;
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-footer {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-footer {
    padding-top: 40px;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 70px;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: 0;
  color: #be1621;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::after, .grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-articles .grid-articles--button-load {
  margin-top: 50px;
  padding: 16px 24px;
  border: 1px solid #be1621;
  color: #be1621;
  font-weight: 500;
  transition: color 200ms ease;
  justify-self: center;
  cursor: pointer;
}
.grid-articles .grid-articles--tile-link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-articles .grid-articles--tile-link:hover, .grid-articles .grid-articles--tile-link:focus {
    color: #ffffff;
    background: #be1621;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link:hover .grid-articles--tile-container-without-image, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link:focus .grid-articles--tile-container-without-image {
    border: 1px solid transparent;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-container, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-container {
    border-style: none solid solid;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-tag, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-subtitle, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-subtitle {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--dark-text, .grid-articles .grid-articles--tile-link:focus .grid-articles--dark-text {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-article-info, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-article-info {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-article-info:first-child::after, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-article-info:first-child::after {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-arrow, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-articles .grid-articles--tile-link:active {
    color: #ffffff;
    background: #be1621;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link:active .grid-articles--tile-container-without-image {
    border: 1px solid transparent;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-container {
    border-style: none solid solid;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-subtitle {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--dark-text {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-article-info {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-article-info:first-child::after {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}
.grid-articles .grid-articles--button-load {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-articles .grid-articles--button-load:hover, .grid-articles .grid-articles--button-load:focus {
    background: #be1621;
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-articles .grid-articles--button-load:active {
    background: #be1621;
    color: #ffffff;
  }
}

.grid-employees-entry .grid-employees-entry--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-employees-entry .grid-employees-entry--list-row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-employees-entry .grid-employees-entry--list-row {
  min-height: 1px;
}

.grid-employees-entry .grid-employees-entry--list-item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (min-width: 576px) {
  .grid-employees-entry .grid-employees-entry--container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-employees-entry .grid-employees-entry--list-row {
    margin-left: -16px;
    margin-right: -16px;
  }
  .grid-employees-entry .grid-employees-entry--list-item {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--container {
    padding-top: 16px;
    padding-left: 0;
    padding-bottom: 16px;
    padding-right: 0;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-employees-entry .grid-employees-entry--list-row {
    margin-top: -8px;
    margin-left: 0;
    margin-bottom: -8px;
    margin-right: 0;
  }
  .grid-employees-entry .grid-employees-entry--list-item {
    padding-top: 8px;
    padding-left: 0;
    padding-bottom: 8px;
    padding-right: 0;
  }
}
.grid-employees-entry .grid-employees-entry--list-row {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  padding-bottom: 32px;
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--list-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--list-row {
    padding-bottom: 16px;
  }
}
.grid-employees-entry .grid-employees-entry--list-col {
  display: flex;
  flex-direction: column;
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--list-item {
    padding: 6px 0;
  }
  .grid-employees-entry .grid-employees-entry--list-item:first-of-type {
    padding-top: 0;
  }
}
.grid-employees-entry .grid-employees-entry--image {
  border-radius: 100%;
  width: 100%;
}
@supports (object-fit: cover) {
  .grid-employees-entry .grid-employees-entry--image {
    object-fit: cover;
  }
  @media (min-width: 576px) {
    .grid-employees-entry .grid-employees-entry--image {
      width: 172px;
      height: 172px;
    }
  }
  @media (max-width: 575px) {
    .grid-employees-entry .grid-employees-entry--image {
      width: 124px;
      height: 124px;
    }
  }
}
.grid-employees-entry .grid-employees-entry--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--title {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-employees-entry .grid-employees-entry--lead {
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #7a7a7a;
  padding-bottom: 8px;
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--lead {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--lead {
    padding-bottom: 4px;
  }
}
.grid-employees-entry .grid-employees-entry--details {
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #be1621;
  margin-bottom: 2px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--details {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1280px) {
  .grid-employees-entry .grid-employees-entry--details {
    margin-bottom: 4px;
  }
}
.grid-employees-entry .grid-employees-entry--details:last-child {
  margin-bottom: 0;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-employees-entry .grid-employees-entry--details:hover, .grid-employees-entry .grid-employees-entry--details:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .grid-employees-entry .grid-employees-entry--details:active {
    color: #98121a;
  }
}
.grid-employees-entry .grid-employees-entry--details-wrapper {
  display: flex;
  flex-direction: column;
}
.grid-employees-entry .grid-employees-entry--link {
  color: #be1621;
  display: flex;
  align-items: center;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-employees-entry .grid-employees-entry--link:hover, .grid-employees-entry .grid-employees-entry--link:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .grid-employees-entry .grid-employees-entry--link:active {
    color: #98121a;
  }
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--link {
    margin-top: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-employees-entry .grid-employees-entry--link {
    margin-top: 16px;
  }
}
.grid-employees-entry .grid-employees-entry--protrait-label {
  margin-right: 10px;
}
.grid-employees-entry .grid-employees-entry--link-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: -8px;
}
.grid-employees-entry .grid-employees-entry--link-arrow::after, .grid-employees-entry .grid-employees-entry--link-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-employees-entry .grid-employees-entry--link-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-employees-entry .grid-employees-entry--link-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-employees-entry .grid-employees-entry--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-employees-entry .grid-employees-entry--link:hover .grid-employees-entry--link-arrow, .grid-employees-entry .grid-employees-entry--link:focus .grid-employees-entry--link-arrow {
    padding-left: 12px;
  }
}
@media (any-hover: none) {
  .grid-employees-entry .grid-employees-entry--link:active .grid-employees-entry--link-arrow {
    padding-left: 12px;
  }
}

.grid-employees .grid-employees--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-employees .grid-employees--title {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
@media (max-width: 1279px) {
  .grid-employees .grid-employees--title {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-employees .grid-employees--title {
    margin-bottom: 40px;
  }
}

.grid-jobs .grid-jobs--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-jobs .grid-jobs--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-jobs .grid-jobs--list {
  min-height: 1px;
}

.grid-jobs .grid-jobs--entry {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.grid-jobs .grid-jobs--entry {
  overflow: hidden;
}
@media (min-width: 1024px) {
  .grid-jobs .grid-jobs--entry {
    flex: 0 0 50%;
  }
  html.ie11 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .grid-jobs .grid-jobs--entry {
    flex: 0 0 100%;
  }
  html.ie11 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 100%;
  }
}
.grid-jobs .grid-jobs--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-jobs .grid-jobs--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--title {
    font-size: 2rem;
  }
}
.grid-jobs .grid-jobs--dark-text {
  color: #000000;
  transition: color 200ms ease;
}
.grid-jobs .grid-jobs--entry-link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 24px;
  min-height: 228px;
  background: #f7f7f7;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
  border: 1px solid #ededed;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 280px;
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 280px;
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 300px;
    padding: 40px;
  }
}
.ie11 .grid-jobs .grid-jobs--entry-link {
  word-break: break-all;
}

html[data-juventus-theme=brand] .grid-jobs .grid-jobs--entry-link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-jobs .grid-jobs--entry-link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-jobs .grid-jobs--entry-link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-jobs .grid-jobs--entry-link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-jobs .grid-jobs--entry-link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-jobs .grid-jobs--entry-link {
  color: #771469;
}

.grid-jobs .grid-jobs--entry-title,
.grid-jobs .grid-jobs--entry-subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
  transition: color 200ms ease;
  flex: 0 0 100%;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-title,
.grid-jobs .grid-jobs--entry-subtitle {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-jobs .grid-jobs--entry-subtitle {
  color: #be1621;
}
.grid-jobs .grid-jobs--entry-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background color 200ms ease;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  background: #be1621;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-tag {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
html[data-juventus-theme=brand] .grid-jobs .grid-jobs--entry-tag {
  background: #be1621;
}

html[data-juventus-theme=highschool] .grid-jobs .grid-jobs--entry-tag {
  background: #dd7200;
}

html[data-juventus-theme=economy] .grid-jobs .grid-jobs--entry-tag {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .grid-jobs .grid-jobs--entry-tag {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .grid-jobs .grid-jobs--entry-tag {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-jobs .grid-jobs--entry-tag {
  background: #771469;
}

.grid-jobs .grid-jobs--entry-footer {
  display: flex;
  flex: 0 0 100%;
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-footer {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-jobs .grid-jobs--entry-footer {
    padding-top: 40px;
  }
}
.grid-jobs .grid-jobs--entry-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 70px;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-jobs .grid-jobs--entry-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: 0;
  color: #be1621;
}
.grid-jobs .grid-jobs--entry-arrow::after, .grid-jobs .grid-jobs--entry-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-jobs .grid-jobs--entry-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-jobs .grid-jobs--entry-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-jobs .grid-jobs--entry-link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-jobs .grid-jobs--entry-link:hover, .grid-jobs .grid-jobs--entry-link:focus {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--entry-tag, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--entry-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--entry-subtitle, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--entry-subtitle {
    color: #ededed;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--dark-text, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--dark-text {
    color: #ffffff;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--entry-arrow, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--entry-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-jobs .grid-jobs--entry-link:active {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--entry-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--entry-subtitle {
    color: #ededed;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--dark-text {
    color: #ffffff;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--entry-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}

.grid-offer .grid-offer--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-offer .grid-offer--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-offer .grid-offer--list {
  min-height: 1px;
}

.grid-offer .grid-offer--item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .grid-offer .grid-offer--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-offer .grid-offer--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-offer .grid-offer--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-offer .grid-offer--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-offer .grid-offer--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .grid-offer .grid-offer--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.grid-offer .grid-offer--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-offer .grid-offer--tags-list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-offer .grid-offer--tags-list {
  min-height: 1px;
}

.grid-offer .grid-offer--tags-item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.grid-offer .grid-offer--container {
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
}
.grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.grid-offer .grid-offer--tags-list {
  margin-top: -4px;
  margin-left: -4px;
  margin-bottom: -4px;
  margin-right: -4px;
}
.grid-offer .grid-offer--tags-item {
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
}
.grid-offer .grid-offer--item {
  overflow: hidden;
}
.grid-offer .grid-offer--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--title {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .grid-offer .grid-offer--title {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--title {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--title-wrap {
    padding-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-offer .grid-offer--title-wrap {
    padding-bottom: 16px;
  }
}
.grid-offer .grid-offer--link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 24px;
  min-height: 228px;
  background: #ffffff;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-offer .grid-offer--link {
    min-height: 362px;
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-offer .grid-offer--link {
    min-height: 286px;
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-offer .grid-offer--link {
    min-height: 340px;
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-offer .grid-offer--link {
    min-height: 362px;
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-offer .grid-offer--link {
    min-height: 392px;
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-offer .grid-offer--link {
    min-height: 460px;
    padding: 40px;
  }
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link {
  color: #771469;
}

.grid-offer .grid-offer--link-module {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 24px;
  min-height: 228px;
  background: #f7f7f7;
  border: 1px solid #ededed;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-offer .grid-offer--link-module {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-offer .grid-offer--link-module {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-offer .grid-offer--link-module {
    min-height: 280px;
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-offer .grid-offer--link-module {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-offer .grid-offer--link-module {
    min-height: 280px;
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-offer .grid-offer--link-module {
    min-height: 300px;
    padding: 40px;
  }
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module {
  color: #771469;
}

.grid-offer .grid-offer--item-title,
.grid-offer .grid-offer--item-subtitle {
  transition: color 200ms ease;
}
.grid-offer .grid-offer--item-title {
  flex: 0 0 100%;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--item-title {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-offer .grid-offer--item-subtitle {
  flex: 0 0 100%;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
  color: #be1621;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--item-subtitle {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--item-subtitle {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--item-subtitle {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--item-subtitle {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--item-subtitle {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--item-subtitle {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--item-subtitle {
  color: #771469;
}

@media (min-width: 1024px) {
  .grid-offer .grid-offer--item {
    flex: 0 0 50%;
  }
  html.ie11 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .grid-offer .grid-offer--item {
    flex: 0 0 100%;
  }
  html.ie11 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 100%;
  }
}
.grid-offer .grid-offer--arrow-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 70px;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-offer .grid-offer--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: 0;
}
.grid-offer .grid-offer--arrow::after, .grid-offer .grid-offer--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-offer .grid-offer--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-offer .grid-offer--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-offer .grid-offer--footer {
  display: flex;
  flex: 0 0 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--footer {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-offer .grid-offer--footer {
    padding-top: 40px;
  }
}
.grid-offer .grid-offer--tags {
  flex: 1 1 100%;
}
.grid-offer .grid-offer--dark-text {
  color: #000000;
  transition: color 200ms ease;
}
.grid-offer .grid-offer--tags-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background 200ms ease;
  background: #be1621;
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--tags-tag {
  background: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--tags-tag {
  background: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--tags-tag {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--tags-tag {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--tags-tag {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--tags-tag {
  background: #771469;
}

.grid-offer .grid-offer--tags-tag-text {
  color: #ffffff;
  transition: color 200ms ease;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--tags-tag-text {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.grid-offer.grid-offer__module .grid-offer--link {
  background: #f7f7f7;
  border: 1px solid #ededed;
  color: #be1621;
}
html[data-juventus-theme=brand] .grid-offer.grid-offer__module .grid-offer--link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer.grid-offer__module .grid-offer--link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer.grid-offer__module .grid-offer--link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer.grid-offer__module .grid-offer--link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer.grid-offer__module .grid-offer--link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer.grid-offer__module .grid-offer--link {
  color: #771469;
}

.grid-offer .grid-offer--dark-text.grid-offer--dark-text__gray {
  color: #5c5c5c;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand {
  color: #be1621;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool {
  color: #dd7200;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy {
  color: #286ab7;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine {
  color: #1f9285;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering {
  color: #948b85;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership {
  color: #771469;
}
.grid-offer .grid-offer--link,
.grid-offer .grid-offer--link-module {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link:hover, .grid-offer .grid-offer--link:focus,
.grid-offer .grid-offer--link-module:hover,
.grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=brand] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=brand] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=brand] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #be1621;
  }

  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #dd7200;
    border-color: #dd7200;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #dd7200;
  }

  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=economy] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #286ab7;
    border-color: #286ab7;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=economy] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=economy] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #1f9285;
    border-color: #1f9285;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #1f9285;
  }

  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #948b85;
    border-color: #948b85;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #948b85;
  }

  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #771469;
    border-color: #771469;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #771469;
  }

  .grid-offer .grid-offer--link:hover .grid-offer--item-subtitle, .grid-offer .grid-offer--link:focus .grid-offer--item-subtitle,
.grid-offer .grid-offer--link-module:hover .grid-offer--item-subtitle,
.grid-offer .grid-offer--link-module:focus .grid-offer--item-subtitle {
    color: #ededed;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--dark-text, .grid-offer .grid-offer--link:focus .grid-offer--dark-text,
.grid-offer .grid-offer--link-module:hover .grid-offer--dark-text,
.grid-offer .grid-offer--link-module:focus .grid-offer--dark-text {
    color: #ffffff;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--arrow, .grid-offer .grid-offer--link:focus .grid-offer--arrow,
.grid-offer .grid-offer--link-module:hover .grid-offer--arrow,
.grid-offer .grid-offer--link-module:focus .grid-offer--arrow {
    padding-left: 15px;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link:active,
.grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #be1621;
  }

  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #dd7200;
    border-color: #dd7200;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #dd7200;
  }

  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #286ab7;
    border-color: #286ab7;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #1f9285;
    border-color: #1f9285;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #1f9285;
  }

  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #948b85;
    border-color: #948b85;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #948b85;
  }

  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #771469;
    border-color: #771469;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #771469;
  }

  .grid-offer .grid-offer--link:active .grid-offer--item-subtitle,
.grid-offer .grid-offer--link-module:active .grid-offer--item-subtitle {
    color: #ededed;
  }
  .grid-offer .grid-offer--link:active .grid-offer--dark-text,
.grid-offer .grid-offer--link-module:active .grid-offer--dark-text {
    color: #ffffff;
  }
  .grid-offer .grid-offer--link:active .grid-offer--arrow,
.grid-offer .grid-offer--link-module:active .grid-offer--arrow {
    padding-left: 15px;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand .grid-offer--tags-tag {
  background: #be1621;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:focus {
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:focus .grid-offer--tags-tag-text {
    color: #be1621;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:active {
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:active .grid-offer--tags-tag-text {
    color: #be1621;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool .grid-offer--tags-tag {
  background: #dd7200;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:focus {
    background: #dd7200;
    border-color: #dd7200;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:focus .grid-offer--tags-tag-text {
    color: #dd7200;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:active {
    background: #dd7200;
    border-color: #dd7200;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:active .grid-offer--tags-tag-text {
    color: #dd7200;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy .grid-offer--tags-tag {
  background: #286ab7;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:focus {
    background: #286ab7;
    border-color: #286ab7;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:focus .grid-offer--tags-tag-text {
    color: #286ab7;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:active {
    background: #286ab7;
    border-color: #286ab7;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:active .grid-offer--tags-tag-text {
    color: #286ab7;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine .grid-offer--tags-tag {
  background: #1f9285;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:focus {
    background: #1f9285;
    border-color: #1f9285;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:focus .grid-offer--tags-tag-text {
    color: #1f9285;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:active {
    background: #1f9285;
    border-color: #1f9285;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:active .grid-offer--tags-tag-text {
    color: #1f9285;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering .grid-offer--tags-tag {
  background: #948b85;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:focus {
    background: #948b85;
    border-color: #948b85;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:focus .grid-offer--tags-tag-text {
    color: #948b85;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:active {
    background: #948b85;
    border-color: #948b85;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:active .grid-offer--tags-tag-text {
    color: #948b85;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership .grid-offer--tags-tag {
  background: #771469;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:focus {
    background: #771469;
    border-color: #771469;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:focus .grid-offer--tags-tag-text {
    color: #771469;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:active {
    background: #771469;
    border-color: #771469;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:active .grid-offer--tags-tag-text {
    color: #771469;
  }
}

.image .image--img {
  display: block;
  width: 100%;
  height: auto;
}
.image .image--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .image .image--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .image .image--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .image .image--title {
    font-size: 2rem;
  }
}
.image .image--figcaption {
  color: #5c5c5c;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .image .image--figcaption {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
@media (max-width: 1279px) {
  .image .image--figcaption {
    padding-top: 12px;
  }
}
@media (min-width: 1280px) {
  .image .image--figcaption {
    padding-top: 16px;
  }
}
.image .image--author {
  display: inline-block;
  width: 100%;
  font-size: 1.2rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}

.info-events .info-events--school-name {
  font-size: 1.7rem;
  margin-bottom: 8px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .info-events .info-events--item:not(:first-child) {
    margin-top: 24px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--item:not(:first-child) {
    margin-top: 32px;
  }
}
.info-events .info-events--link {
  display: block;
  padding: 24px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (min-width: 1280px) {
  .info-events .info-events--link {
    padding: 32px;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link:hover, .info-events .info-events--link:focus {
    color: #ffffff;
  }
  .info-events .info-events--link:hover .info-events--heading, .info-events .info-events--link:focus .info-events--heading {
    color: #ffffff;
  }
  .info-events .info-events--link:hover .info-events--bullet-list, .info-events .info-events--link:focus .info-events--bullet-list {
    color: #f7f7f7;
  }
  .info-events .info-events--link:hover .info-events--arrow, .info-events .info-events--link:focus .info-events--arrow {
    padding-left: 24px;
  }
  .info-events .info-events--link:hover .info-events--wrapper-item, .info-events .info-events--link:focus .info-events--wrapper-item {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link:active {
    color: #ffffff;
  }
  .info-events .info-events--link:active .info-events--heading {
    color: #ffffff;
  }
  .info-events .info-events--link:active .info-events--bullet-list {
    color: #f7f7f7;
  }
  .info-events .info-events--link:active .info-events--arrow {
    padding-left: 24px;
  }
  .info-events .info-events--link:active .info-events--wrapper-item {
    color: #ffffff;
  }
}
.info-events .info-events--wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  color: #000000;
}
.info-events .info-events--wrapper .info-events--wrapper-item {
  display: flex;
  align-items: center;
}
@media (max-width: 1279px) {
  .info-events .info-events--wrapper .info-events--wrapper-item {
    margin-bottom: 8px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--wrapper .info-events--wrapper-item {
    margin-bottom: 8px;
  }
}
@media (min-width: 1024px) {
  .info-events .info-events--wrapper .info-events--wrapper-item {
    padding: 0 8px;
  }
  .info-events .info-events--wrapper .info-events--wrapper-item:first-of-type {
    padding-left: 0;
  }
  .info-events .info-events--wrapper .info-events--wrapper-item:last-of-type {
    padding-right: 0;
  }
}
.info-events .info-events--wrapper .info-events--date {
  min-width: 208px;
}
.info-events .info-events--wrapper .info-events--time {
  min-width: 88px;
}
@media (max-width: 1279px) {
  .info-events .info-events--wrapper .info-events--time-icon, .info-events .info-events--wrapper .info-events--date-icon {
    margin-right: 8px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--wrapper .info-events--time-icon, .info-events .info-events--wrapper .info-events--date-icon {
    margin-right: 8px;
  }
}
@media (min-width: 1024px) {
  .info-events .info-events--wrapper {
    align-items: center;
    flex-flow: row;
  }
  .info-events .info-events--wrapper .info-events--date-wrapper {
    margin-bottom: 0;
  }
}
.info-events .info-events--bullet-list {
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .info-events .info-events--bullet-list {
    margin-top: 24px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--bullet-list {
    margin-top: 32px;
  }
}
.info-events .info-events--bullet-list .info-events--bullet-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  padding-left: 2rem;
}
.info-events .info-events--bullet-list .info-events--bullet-item::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 6px;
  display: inline-block;
  margin-right: 12px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.info-events .info-events--footer {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1279px) {
  .info-events .info-events--footer {
    margin-top: 32px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--footer {
    margin-top: 40px;
  }
}
.info-events .info-events--arrow-wrapper {
  display: flex;
  align-items: center;
  width: 93px;
}
.info-events .info-events--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.info-events .info-events--arrow::after, .info-events .info-events--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.info-events .info-events--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.info-events .info-events--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.info-events {
  color: #be1621;
}
.info-events .info-events--school-name {
  color: #be1621;
}
.info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link.info-events--link:hover, .info-events .info-events--link.info-events--link:focus {
    background: #be1621;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link.info-events--link:active {
    background: #be1621;
  }
}
.info-events .info-events--link .info-events--bullet-item::before {
  background-color: #be1621;
}
.info-events .info-events--link .info-events--footer {
  color: #be1621;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link:hover .info-events--footer, .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  .info-events .info-events--link:hover .info-events--date-time-wrapper, .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link:hover .info-events--bullet-item::before, .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
html[data-juventus-theme=brand] .info-events {
  color: #be1621;
}
html[data-juventus-theme=brand] .info-events .info-events--school-name {
  color: #be1621;
}
html[data-juventus-theme=brand] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link:focus {
    background: #be1621;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link:active {
    background: #be1621;
  }
}
html[data-juventus-theme=brand] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #be1621;
}
html[data-juventus-theme=brand] .info-events .info-events--link .info-events--footer {
  color: #be1621;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=brand] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=brand] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=brand] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=brand] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=brand] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=highschool] .info-events {
  color: #dd7200;
}
html[data-juventus-theme=highschool] .info-events .info-events--school-name {
  color: #dd7200;
}
html[data-juventus-theme=highschool] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link:focus {
    background: #dd7200;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link:active {
    background: #dd7200;
  }
}
html[data-juventus-theme=highschool] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #dd7200;
}
html[data-juventus-theme=highschool] .info-events .info-events--link .info-events--footer {
  color: #dd7200;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=highschool] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=highschool] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=highschool] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=highschool] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=highschool] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=economy] .info-events {
  color: #286ab7;
}
html[data-juventus-theme=economy] .info-events .info-events--school-name {
  color: #286ab7;
}
html[data-juventus-theme=economy] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link:focus {
    background: #286ab7;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link:active {
    background: #286ab7;
  }
}
html[data-juventus-theme=economy] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #286ab7;
}
html[data-juventus-theme=economy] .info-events .info-events--link .info-events--footer {
  color: #286ab7;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=economy] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=economy] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=economy] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=economy] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=economy] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=medicine] .info-events {
  color: #1f9285;
}
html[data-juventus-theme=medicine] .info-events .info-events--school-name {
  color: #1f9285;
}
html[data-juventus-theme=medicine] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link:focus {
    background: #1f9285;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link:active {
    background: #1f9285;
  }
}
html[data-juventus-theme=medicine] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #1f9285;
}
html[data-juventus-theme=medicine] .info-events .info-events--link .info-events--footer {
  color: #1f9285;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=medicine] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medicine] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=medicine] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medicine] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=medicine] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=engineering] .info-events {
  color: #948b85;
}
html[data-juventus-theme=engineering] .info-events .info-events--school-name {
  color: #948b85;
}
html[data-juventus-theme=engineering] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link:focus {
    background: #948b85;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link:active {
    background: #948b85;
  }
}
html[data-juventus-theme=engineering] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #948b85;
}
html[data-juventus-theme=engineering] .info-events .info-events--link .info-events--footer {
  color: #948b85;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=engineering] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=engineering] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=engineering] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=engineering] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=engineering] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=medical-leadership] .info-events {
  color: #771469;
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--school-name {
  color: #771469;
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link:focus {
    background: #771469;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link:active {
    background: #771469;
  }
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #771469;
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link .info-events--footer {
  color: #771469;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=medical-leadership] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=medical-leadership] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=medical-leadership] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

.intro-teaser {
  background: #be1621;
}
html[data-juventus-theme=brand] .intro-teaser {
  background: #be1621;
}

html[data-juventus-theme=highschool] .intro-teaser {
  background: #dd7200;
}

html[data-juventus-theme=economy] .intro-teaser {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .intro-teaser {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .intro-teaser {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .intro-teaser {
  background: #771469;
}

.intro-teaser .intro-teaser--container {
  z-index: 0;
  position: relative;
  justify-content: center;
}
@media (max-width: 1023px) {
  .intro-teaser .intro-teaser--container {
    justify-content: flex-end;
  }
}
.intro-teaser .intro-teaser--col {
  flex: 0 0 50%;
}
html.ie11 .intro-teaser .intro-teaser--col {
  flex-basis: auto;
  width: 50%;
}

html.ie10 .intro-teaser .intro-teaser--col {
  flex-basis: auto;
  width: 50%;
}

.intro-teaser .intro-teaser--parallax-wrapper {
  position: relative;
  overflow: hidden;
}
.intro-teaser .intro-teaser--row {
  display: flex;
  align-items: center;
}
.intro-teaser .intro-teaser--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 4rem;
  line-height: 1.1;
  color: #000000;
  color: #ffffff;
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--title {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .intro-teaser .intro-teaser--title {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--title {
    font-size: 3.2rem;
    line-height: 1.125;
  }
}
@media (max-width: 767px) {
  .intro-teaser .intro-teaser--title {
    margin-bottom: 0;
  }
}
.intro-teaser .intro-teaser--lead {
  font-size: 2.2rem;
  line-height: 1.31;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--lead {
    font-size: 2rem;
    line-height: 1.35;
  }
}
@media (max-width: 767px) {
  .intro-teaser .intro-teaser--lead {
    display: none;
  }
}
.intro-teaser .intro-teaser--breadcrumbs {
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 68px;
  font-size: 1.5rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--breadcrumbs {
    height: 52px;
  }
}
@media (max-width: 767px) {
  .intro-teaser .intro-teaser--breadcrumbs {
    height: 56px;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
  color: #ffffff;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 596px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 444px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 340px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 448px;
  }
}
@media (max-width: 575px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 214px;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:hover, .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:focus {
    text-decoration: underline;
  }
}
@media (any-hover: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:active {
    text-decoration: underline;
  }
}
@media (max-width: 1023px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    display: none;
  }
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:last-child {
    display: block;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled:hover, .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled:focus {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled:active {
    text-decoration: none;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item {
  display: flex;
  align-items: center;
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list {
  display: flex;
  align-items: center;
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-icon {
  display: none;
  padding: 0 8px;
  transform: translateY(-1px);
  color: #ffffff;
}
@media (min-width: 1024px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-icon {
    display: inline-block;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-back-icon {
  display: inline-block;
  padding: 0 8px;
  transform: translateY(-1px);
  color: #ffffff;
}
@media (min-width: 1024px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-back-icon {
    display: none;
  }
}
.intro-teaser .intro-teaser--circle {
  border-radius: 50%;
  position: absolute;
  left: 64px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.intro-teaser .intro-teaser--image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}
.intro-teaser .intro-teaser--figure {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.intro-teaser .intro-teaser--figure::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 1023px) {
  .intro-teaser .intro-teaser--figure::after {
    background: rgba(190, 22, 33, 0.5);
  }
  html[data-juventus-theme=brand] .intro-teaser .intro-teaser--figure::after {
    background: rgba(190, 22, 33, 0.5);
  }

  html[data-juventus-theme=highschool] .intro-teaser .intro-teaser--figure::after {
    background: rgba(221, 114, 0, 0.5);
  }

  html[data-juventus-theme=economy] .intro-teaser .intro-teaser--figure::after {
    background: rgba(40, 106, 183, 0.5);
  }

  html[data-juventus-theme=medicine] .intro-teaser .intro-teaser--figure::after {
    background: rgba(31, 146, 133, 0.5);
  }

  html[data-juventus-theme=engineering] .intro-teaser .intro-teaser--figure::after {
    background: rgba(148, 139, 133, 0.5);
  }

  html[data-juventus-theme=medical-leadership] .intro-teaser .intro-teaser--figure::after {
    background: rgba(119, 20, 105, 0.5);
  }
}
.intro-teaser .intro-teaser--arrow-wrapper {
  position: absolute;
  width: 24px;
  transform: rotate(90deg);
}
.intro-teaser .intro-teaser--arrow {
  color: #ffffff;
}
.intro-teaser.intro-teaser__expanded {
  margin-top: -88px;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow-wrapper {
  bottom: 124px;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 72px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow::after, .intro-teaser.intro-teaser__expanded .intro-teaser--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--container {
    padding-bottom: 196px;
  }
}
.intro-teaser.intro-teaser__expanded .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__expanded .intro-teaser--container {
  min-height: 600px;
  height: 100vh;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--circle {
  width: 580px;
  height: 580px;
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--circle {
    width: 520px;
    height: 520px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--circle {
    width: 440px;
    height: 440px;
  }
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--circle {
    display: none;
  }
}
.intro-teaser.intro-teaser__small {
  margin-top: -88px;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow-wrapper {
  bottom: 108px;
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__small .intro-teaser--container {
    padding-bottom: 152px;
  }
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow::after, .intro-teaser.intro-teaser__small .intro-teaser--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
  /* the use of fixed height values is due to ie11 support */
  height: 600px;
  /* notice the use of min-height on mobile in order to avoid overflow issues */
}
@media (min-width: 1556px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    height: 40vw;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    height: 480px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    min-height: 700px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    min-height: 480px;
  }
}
@media (max-width: 575px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    min-height: 532px;
  }
}
.intro-teaser.intro-teaser__small .intro-teaser--circle {
  left: 34px;
  top: 48%;
  width: 400px;
  height: 400px;
  transform: translate(-50%, -50%);
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .intro-teaser.intro-teaser__small .intro-teaser--circle {
    top: 45%;
    width: 330px;
    height: 330px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser.intro-teaser__small .intro-teaser--circle {
    top: 45%;
    width: 260px;
    height: 260px;
  }
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__small .intro-teaser--circle {
    display: none;
  }
}

.lead .lead--text {
  font-size: 2.2rem;
  line-height: 1.31;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .lead .lead--text {
    font-size: 2rem;
    line-height: 1.35;
  }
}

.main-footer {
  font-size: 1.5rem;
  line-height: 1.4;
}
@media (max-width: 1023px) {
  .main-footer {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
@media (min-width: 1024px) {
  .main-footer {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.main-footer .main-footer--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.main-footer .main-footer--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .main-footer .main-footer--list {
  min-height: 1px;
}

.main-footer .main-footer--list-item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.main-footer .main-footer--container {
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  padding-right: 16px;
}
.main-footer .main-footer--container .main-footer--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.main-footer .main-footer--container .main-footer--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.main-footer .main-footer--list {
  margin-top: -4px;
  margin-left: -8px;
  margin-bottom: -4px;
  margin-right: -8px;
}
.main-footer .main-footer--list-item {
  padding-top: 4px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-right: 8px;
}
.main-footer .main-footer--left,
.main-footer .main-footer--right {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .main-footer .main-footer--left,
.main-footer .main-footer--right {
    flex: 0 0 50%;
  }
  html.ie11 .main-footer .main-footer--left,
html.ie11 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .main-footer .main-footer--left,
html.ie10 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .main-footer .main-footer--left,
.main-footer .main-footer--right {
    flex: 0 0 100%;
  }
  html.ie11 .main-footer .main-footer--left,
html.ie11 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .main-footer .main-footer--left,
html.ie10 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 100%;
  }
}
.main-footer .main-footer--left {
  flex: 0 0 auto;
}
.main-footer .main-footer--right {
  flex-grow: 1;
}
@media (min-width: 1024px) {
  .main-footer .main-footer--right {
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .main-footer .main-footer--right {
    flex-direction: column;
    align-items: flex-end;
  }
}
@media (max-width: 767px) {
  .main-footer .main-footer--right {
    flex-direction: column;
    align-items: flex-start;
  }
}
.main-footer .main-footer--link {
  color: #5c5c5c;
  transition: color 200ms ease;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-footer .main-footer--link:hover, .main-footer .main-footer--link:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .main-footer .main-footer--link:active {
    color: #000000;
  }
}
.main-footer .main-footer--copyright {
  color: #bfbfbf;
}
@media (min-width: 1024px) {
  .main-footer .main-footer--copyright {
    margin-left: 40px;
  }
}
@media (max-width: 1023px) {
  .main-footer .main-footer--copyright {
    margin-top: 12px;
  }
}
.main-footer .main-footer--link.main-footer--link__active {
  color: #000000;
}
.main-footer .main-footer--link.main-footer--link__social-icon {
  color: #7a7a7a;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-footer .main-footer--link.main-footer--link__social-icon:hover, .main-footer .main-footer--link.main-footer--link__social-icon:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .main-footer .main-footer--link.main-footer--link__social-icon:active {
    color: #000000;
  }
}
@media (max-width: 767px) {
  .main-footer .main-footer--list.main-footer--list__social {
    margin-bottom: 12px;
  }
}

.main-header {
  z-index: 13;
  position: relative;
}
.main-header .main-header--row {
  align-items: center;
  justify-content: space-between;
  height: 88px;
}
@media (min-width: 1024px) {
  .main-header .main-header--logo {
    flex: 0 0 33.3333333333%;
  }
  html.ie11 .main-header .main-header--logo {
    flex-basis: auto;
    width: 33.3333333333%;
  }

  html.ie10 .main-header .main-header--logo {
    flex-basis: auto;
    width: 33.3333333333%;
  }
}
@media (max-width: 1023px) {
  .main-header .main-header--logo {
    flex: 0 0 66.6666666667%;
  }
  html.ie11 .main-header .main-header--logo {
    flex-basis: auto;
    width: 66.6666666667%;
  }

  html.ie10 .main-header .main-header--logo {
    flex-basis: auto;
    width: 66.6666666667%;
  }
}
.main-header .main-header--menu {
  flex: 0 0 auto;
  margin-right: 0;
  margin-left: auto;
}
@media (max-width: 1023px) {
  .main-header .main-header--menu {
    display: none;
  }
}
.main-header .main-header--hamburger {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 33.3333333333%;
}
html.ie11 .main-header .main-header--hamburger {
  flex-basis: auto;
  width: 33.3333333333%;
}

html.ie10 .main-header .main-header--hamburger {
  flex-basis: auto;
  width: 33.3333333333%;
}

@media (min-width: 1024px) {
  .main-header .main-header--hamburger {
    display: none;
  }
}
.main-header .main-header--contact.main-header--contact.main-header--contact {
  display: flex;
  justify-content: flex-end;
  padding-left: 28px;
}
@media (max-width: 1023px) {
  .main-header .main-header--contact.main-header--contact.main-header--contact {
    display: none;
  }
}
.main-header .main-header--contact-link {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
  line-height: 1.26;
  color: #000000;
}
@media (max-width: 1279px) {
  .main-header .main-header--contact-link {
    font-size: 1.8rem;
  }
}
.main-header.main-header__dark .main-header--wrap {
  background: #ffffff;
}
.main-header.main-header__dark .main-header--wrap::after {
  background: #ffffff;
}
.main-header.main-header__dark .main-header--contact-link {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-header.main-header__dark .main-header--contact-link:hover, .main-header.main-header__dark .main-header--contact-link:focus {
    color: #901119;
  }
}
@media (any-hover: none) {
  .main-header.main-header__dark .main-header--contact-link:active {
    color: #901119;
  }
}
html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #901119;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link:active {
    color: #901119;
  }
}

html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link {
  color: #dd7200;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #aa5800;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link:active {
    color: #aa5800;
  }
}

html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link {
  color: #286ab7;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #1f528d;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link:active {
    color: #1f528d;
  }
}

html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link {
  color: #1f9285;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #16685f;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link:active {
    color: #16685f;
  }
}

html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link {
  color: #948b85;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #7b716b;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link:active {
    color: #7b716b;
  }
}

html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link {
  color: #771469;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #4b0d42;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link:active {
    color: #4b0d42;
  }
}

.main-header.main-header__light .main-header--contact-link {
  color: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-header.main-header__light .main-header--contact-link:hover, .main-header.main-header__light .main-header--contact-link:focus {
    color: #ededed;
  }
}
@media (any-hover: none) {
  .main-header.main-header__light .main-header--contact-link:active {
    color: #ededed;
  }
}

.publication-detail--publication-info-container {
  display: flex;
}
.publication-detail--publication-info-container .publication-detail--publication-info {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .publication-detail--publication-info-container .publication-detail--publication-info {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.publication-detail--publication-info-container .publication-detail--publication-info:first-child::after {
  content: "•";
  position: relative;
  margin-left: 5px;
  color: #be1621;
}
.publication-detail--publication-info-container .publication-detail--publication-info:last-child {
  margin-left: 10px;
}

.publication-detail--author-box {
  display: flex;
  align-items: center;
  border: solid 1px #ededed;
  border-left: none;
  border-right: none;
  padding: 32px 0;
  margin: 20px 0 0;
}
@media (min-width: 1024px) {
  .publication-detail--author-box {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1023px) {
  .publication-detail--author-box {
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.publication-detail--author-box .publication-detail--author-box-image {
  border-radius: 100%;
}
@media (max-width: 1023px) {
  .publication-detail--author-box .publication-detail--author-box-image {
    align-self: flex-start;
  }
}
@supports (object-fit: cover) {
  .publication-detail--author-box .publication-detail--author-box-image {
    object-fit: cover;
  }
  @media (min-width: 576px) {
    .publication-detail--author-box .publication-detail--author-box-image {
      width: 172px;
      height: 172px;
    }
  }
  @media (max-width: 575px) {
    .publication-detail--author-box .publication-detail--author-box-image {
      width: 124px;
      height: 124px;
    }
  }
}
@media (min-width: 1024px) {
  .publication-detail--author-box .publication-detail--author-box-container {
    margin-left: 20px;
  }
}
@media (max-width: 1023px) {
  .publication-detail--author-box .publication-detail--author-box-container {
    margin-top: 32px;
  }
}
.publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author {
    font-size: 2rem;
  }
}
.publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author-description {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #7a7a7a;
}
@media (max-width: 1279px) {
  .publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author-description {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}

.publication-detail--sub-navigation {
  display: flex;
  margin-top: 30px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.publication-detail--sub-navigation .publication-detail--exit-arrow-label {
  color: #be1621;
  font-weight: 500;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 20px;
  margin-bottom: 4px;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  height: 1px;
  width: 32px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  color: #be1621;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow::after, .publication-detail--sub-navigation .publication-detail--exit-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow::after {
  transform: translate(-4px, -50%) rotate(-45deg);
  transition: left 200ms ease;
  border-left: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 8px;
  height: 8px;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(-5px, -50%);
  transition: width 200ms ease, left 200ms ease;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow {
    color: #98121a;
  }
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow::after, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow::after {
    left: -15px;
  }
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow::before, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow-label, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow-label {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow {
    color: #98121a;
  }
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow::after {
    left: -15px;
  }
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow-label {
    color: #98121a;
  }
}

.quotes {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (max-width: 1279px) {
  .quotes {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .quotes {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.quotes::before, .quotes::after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  height: 1px;
  width: 48px;
  transform: translate(-50%, 0);
  background: #be1621;
}
html[data-juventus-theme=brand] .quotes::before, html[data-juventus-theme=brand] .quotes::after {
  background: #be1621;
}

html[data-juventus-theme=highschool] .quotes::before, html[data-juventus-theme=highschool] .quotes::after {
  background: #dd7200;
}

html[data-juventus-theme=economy] .quotes::before, html[data-juventus-theme=economy] .quotes::after {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .quotes::before, html[data-juventus-theme=medicine] .quotes::after {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .quotes::before, html[data-juventus-theme=engineering] .quotes::after {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .quotes::before, html[data-juventus-theme=medical-leadership] .quotes::after {
  background: #771469;
}

.quotes::before {
  top: 0;
}
.quotes::after {
  bottom: 0;
}
.quotes .quotes--quote {
  font-size: 3.2rem;
  line-height: 1.12;
  font-family: "Argesta", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic;
  color: #be1621;
}
@media (max-width: 1279px) {
  .quotes .quotes--quote {
    font-size: 2.8rem;
    line-height: 1.14;
  }
}
html[data-juventus-theme=brand] .quotes .quotes--quote {
  color: #be1621;
}

html[data-juventus-theme=highschool] .quotes .quotes--quote {
  color: #dd7200;
}

html[data-juventus-theme=economy] .quotes .quotes--quote {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .quotes .quotes--quote {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .quotes .quotes--quote {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .quotes .quotes--quote {
  color: #771469;
}

.quotes .quotes--quote::after {
  content: "»";
}
.quotes .quotes--quote::before {
  content: "«";
}
.quotes .quotes--author {
  color: #5c5c5c;
  font-size: 1.5rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .quotes .quotes--author {
    margin-top: 12px;
  }
}
@media (min-width: 1280px) {
  .quotes .quotes--author {
    margin-top: 16px;
  }
}
.quotes .quotes--author-col {
  flex: 0 0 66.6666666667%;
}
html.ie11 .quotes .quotes--author-col {
  flex-basis: auto;
  width: 66.6666666667%;
}

html.ie10 .quotes .quotes--author-col {
  flex-basis: auto;
  width: 66.6666666667%;
}

.richtext b, .richtext strong {
  color: #1f1f1f;
}
.richtext p {
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .richtext > p:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .richtext > p:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (max-width: 1279px) {
  .richtext > p:not(:last-child) + p {
    margin-top: -16px;
  }
}
@media (min-width: 1280px) {
  .richtext > p:not(:last-child) + p {
    margin-top: -24px;
  }
}
@media (max-width: 1279px) {
  .richtext ul:not(:last-child), .richtext ol:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .richtext ul:not(:last-child), .richtext ol:not(:last-child) {
    margin-bottom: 40px;
  }
}
.richtext p:last-child, .richtext ul:last-child, .richtext ol:last-child {
  margin-bottom: 0;
}
@media (min-width: 1555px) {
  .richtext ul:not(.start-dates-entry--list), .richtext ol {
    margin-left: -16px;
  }
}
@media (max-width: 1555px) {
  .richtext ul:not(.start-dates-entry--list), .richtext ol {
    margin-left: -12px;
  }
}

.schools-teaser {
  margin-bottom: 36px;
}
.schools-teaser .schools-teaser--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.schools-teaser .schools-teaser--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .schools-teaser .schools-teaser--list {
  min-height: 1px;
}

.schools-teaser .schools-teaser--item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.schools-teaser .schools-teaser--container {
  padding-top: 2px;
  padding-left: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
}
.schools-teaser .schools-teaser--container .schools-teaser--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.schools-teaser .schools-teaser--container .schools-teaser--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.schools-teaser .schools-teaser--list {
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
}
.schools-teaser .schools-teaser--item {
  padding-top: 1px;
  padding-left: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
}
@media (max-width: 1555px) {
  .schools-teaser {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .schools-teaser {
    margin-bottom: 24px;
  }
}
.schools-teaser .schools-teaser--item {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .schools-teaser .schools-teaser--item {
    flex: 0 0 20%;
  }
  html.ie11 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 20%;
  }

  html.ie10 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 20%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--item {
    flex: 0 0 33.3333333333%;
  }
  html.ie11 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 33.3333333333%;
  }

  html.ie10 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 33.3333333333%;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--item {
    flex: 0 0 100%;
  }
  html.ie11 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--item:nth-child(4n), .schools-teaser .schools-teaser--item:nth-child(5n) {
    flex: 0 0 50%;
  }
  html.ie11 .schools-teaser .schools-teaser--item:nth-child(4n), html.ie11 .schools-teaser .schools-teaser--item:nth-child(5n) {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .schools-teaser .schools-teaser--item:nth-child(4n), html.ie10 .schools-teaser .schools-teaser--item:nth-child(5n) {
    flex-basis: auto;
    width: 50%;
  }
}
@media (min-width: 1556px) {
  .schools-teaser .schools-teaser--item {
    height: calc(100vh - 88px - 36px);
  }
}
@media (min-width: 1280px) {
  .schools-teaser .schools-teaser--item {
    height: calc(100vh - 88px - 32px);
    min-height: 600px;
    max-height: 1024px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--item {
    height: calc(50vh - 22px - 32px);
    min-height: 320px;
    max-height: 500px;
  }
}
.schools-teaser .schools-teaser--figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.schools-teaser .schools-teaser--figure::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  transition: background 200ms ease;
}
.schools-teaser .schools-teaser--image {
  /* stylelint-disable */
  font-family: "object-fit: cover;";
  /* stylelint-enable */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100);
  transition: filter 200ms ease;
}
.schools-teaser .schools-teaser--link {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.schools-teaser .schools-teaser--text {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: #000000;
  padding: 0 24px 12px;
  width: 100%;
}
@media (min-width: 1556px) {
  .schools-teaser .schools-teaser--text {
    margin-bottom: 80px;
    height: 266px;
    padding: 0 24px 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .schools-teaser .schools-teaser--text {
    margin-bottom: 80px;
    height: 310px;
    padding: 0 24px 12px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--text {
    height: 198px;
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--text {
    align-content: flex-end;
    padding-bottom: 0;
    height: 128px;
  }
}
.schools-teaser .schools-teaser--description,
.schools-teaser .schools-teaser--title {
  flex: 0 0 100%;
  width: 100%;
  transition: transform 200ms ease;
}
.schools-teaser .schools-teaser--description {
  color: #5c5c5c;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--description {
    display: none;
  }
}
.schools-teaser .schools-teaser--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
  color: #000000;
}
@media (max-width: 1279px) {
  .schools-teaser .schools-teaser--title {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .schools-teaser .schools-teaser--title {
    display: flex;
    align-items: flex-end;
    min-height: 88px;
    text-align: start;
  }
}
@media (min-width: 768px) {
  .schools-teaser .schools-teaser--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--title {
    margin-bottom: 16px;
  }
}
.schools-teaser .schools-teaser--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 32px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  position: absolute;
  bottom: 8px;
}
.schools-teaser .schools-teaser--arrow::after, .schools-teaser .schools-teaser--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.schools-teaser .schools-teaser--arrow::after {
  transform: translate(4px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 8px;
  height: 8px;
}
.schools-teaser .schools-teaser--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(5px, -50%);
}
@media (min-width: 768px) {
  .schools-teaser .schools-teaser--arrow {
    transform: translate(-20px, 0);
    left: 24px;
    width: 160px;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--arrow {
    left: calc(100% - 110px);
    bottom: 32px;
    width: 54px;
  }
}
.schools-teaser .schools-teaser--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__brand .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__brand .schools-teaser--figure::after {
    background: rgba(190, 22, 33, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__highschool .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__highschool .schools-teaser--figure::after {
    background: rgba(221, 114, 0, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__economy .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__economy .schools-teaser--figure::after {
    background: rgba(40, 106, 183, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__medicine .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__medicine .schools-teaser--figure::after {
    background: rgba(31, 146, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__engineering .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__engineering .schools-teaser--figure::after {
    background: rgba(148, 139, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__medical-leadership .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__medical-leadership .schools-teaser--figure::after {
    background: rgba(119, 20, 105, 0.6);
  }
}
@media (any-hover: none) {
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__brand .schools-teaser--figure::after {
    background: rgba(190, 22, 33, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__highschool .schools-teaser--figure::after {
    background: rgba(221, 114, 0, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__economy .schools-teaser--figure::after {
    background: rgba(40, 106, 183, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__medicine .schools-teaser--figure::after {
    background: rgba(31, 146, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__engineering .schools-teaser--figure::after {
    background: rgba(148, 139, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__medical-leadership .schools-teaser--figure::after {
    background: rgba(119, 20, 105, 0.6);
  }
}
.schools-teaser .schools-teaser--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .schools-teaser .schools-teaser--link:hover .schools-teaser--title,
.schools-teaser .schools-teaser--link:hover .schools-teaser--description,
.schools-teaser .schools-teaser--link:hover .schools-teaser--text, .schools-teaser .schools-teaser--link:focus .schools-teaser--title,
.schools-teaser .schools-teaser--link:focus .schools-teaser--description,
.schools-teaser .schools-teaser--link:focus .schools-teaser--text {
    color: #ffffff;
  }
  .schools-teaser .schools-teaser--link:hover .schools-teaser--image, .schools-teaser .schools-teaser--link:focus .schools-teaser--image {
    filter: grayscale(0);
  }
}
@media (any-hover: hover) and (min-width: 768px), (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
  .schools-teaser .schools-teaser--link:hover .schools-teaser--title, .schools-teaser .schools-teaser--link:focus .schools-teaser--title {
    transform: translate(0, -24px);
  }
  .schools-teaser .schools-teaser--link:hover .schools-teaser--description, .schools-teaser .schools-teaser--link:focus .schools-teaser--description {
    transform: translate(0, -32px);
  }
  .schools-teaser .schools-teaser--link:hover .schools-teaser--arrow, .schools-teaser .schools-teaser--link:focus .schools-teaser--arrow {
    padding-left: 12px;
  }
}
@media (any-hover: hover) and (max-width: 767px), (-ms-high-contrast: active) and (max-width: 767px), (-ms-high-contrast: none) and (max-width: 767px) {
  .schools-teaser .schools-teaser--link:hover .schools-teaser--arrow, .schools-teaser .schools-teaser--link:focus .schools-teaser--arrow {
    padding-left: 18px;
  }
}
@media (any-hover: none) {
  .schools-teaser .schools-teaser--link:active .schools-teaser--title,
.schools-teaser .schools-teaser--link:active .schools-teaser--description,
.schools-teaser .schools-teaser--link:active .schools-teaser--text {
    color: #ffffff;
  }
  .schools-teaser .schools-teaser--link:active .schools-teaser--image {
    filter: grayscale(0);
  }
}
@media (any-hover: none) and (min-width: 768px) {
  .schools-teaser .schools-teaser--link:active .schools-teaser--title {
    transform: translate(0, -24px);
  }
  .schools-teaser .schools-teaser--link:active .schools-teaser--description {
    transform: translate(0, -32px);
  }
  .schools-teaser .schools-teaser--link:active .schools-teaser--arrow {
    padding-left: 12px;
  }
}
@media (any-hover: none) and (max-width: 767px) {
  .schools-teaser .schools-teaser--link:active .schools-teaser--arrow {
    padding-left: 18px;
  }
}

.start-dates-entry .start-dates-entry--list {
  padding-left: 0;
}
.start-dates-entry .start-dates-entry--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
}
@media (max-width: 1279px) {
  .start-dates-entry .start-dates-entry--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .start-dates-entry .start-dates-entry--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .start-dates-entry .start-dates-entry--title {
    font-size: 2rem;
  }
}

.sticky-panel {
  position: sticky;
  top: 0;
}
@media (max-width: 1279px) {
  .sticky-panel {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .sticky-panel {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .sticky-panel {
    padding-bottom: 0;
  }
}
.has-anchor-navigation .sticky-panel {
  padding-top: 0;
  top: 56px;
}
@media (max-width: 767px) {
  .has-anchor-navigation .sticky-panel {
    margin-top: 32px;
  }
}
@media (max-width: 1279px) {
  .has-anchor-navigation .sticky-panel {
    top: 48px;
  }
}

.teaser-contact .teaser-contact--text {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--text {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--text {
    padding-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .teaser-contact .teaser-contact--text {
    padding-bottom: 40px;
  }
}
.teaser-contact .teaser-contact--figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teaser-contact .teaser-contact--figcaption {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--figcaption {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-contact .teaser-contact--image {
  display: block;
  object-fit: cover;
  border-radius: 100%;
  height: 160px;
  width: 160px;
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--image {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .teaser-contact .teaser-contact--image {
    margin-bottom: 16px;
  }
}
.teaser-contact .teaser-contact--list {
  margin-top: 32px;
}
.teaser-contact .teaser-contact--list-short {
  margin-top: 0;
}

.teaser-event .teaser-event--title {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-event .teaser-event--title {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-event .teaser-event--description {
  color: #7a7a7a;
  padding-bottom: 16px;
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .teaser-event .teaser-event--description {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
.teaser-event .teaser-event--description:last-of-type {
  padding-bottom: 0;
}
.teaser-event .teaser-event--description-value {
  font-size: 1.2rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
.teaser-event .teaser-event--list {
  margin-top: 24px;
}

.teaser-news .teaser-news--image {
  display: block;
  width: 100%;
}
@media (max-width: 1279px) {
  .teaser-news .teaser-news--image {
    padding-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .teaser-news .teaser-news--image {
    padding-bottom: 24px;
  }
}
.teaser-news .teaser-news--pubdate {
  color: #1f1f1f;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .teaser-news .teaser-news--pubdate {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-news .teaser-news--text {
  color: #7a7a7a;
}
.teaser-news .teaser-news--cta {
  margin-top: 32px;
}

.teaser-subscription .teaser-subscription--text {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-subscription .teaser-subscription--text {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-subscription .teaser-subscription--list {
  margin-top: 32px;
}

.text-link {
  display: flex;
  align-items: center;
  line-height: 24px;
  color: #be1621;
  font-size: 1.7rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
html[data-juventus-theme=brand] .text-link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .text-link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .text-link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .text-link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .text-link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .text-link {
  color: #771469;
}

.text-link .text-link--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.text-link .text-link--arrow::after, .text-link .text-link--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.text-link .text-link--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.text-link .text-link--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.text-link--narrow .text-link--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: -8px;
}
.text-link--narrow .text-link--arrow::after, .text-link--narrow .text-link--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.text-link--narrow .text-link--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.text-link--narrow .text-link--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.text-link .text-link--arrow-wrap {
  display: flex;
  align-items: center;
  width: 93px;
}
.text-link.text-link__base {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .text-link.text-link__base:hover .text-link--arrow, .text-link.text-link__base:focus .text-link--arrow {
    padding-left: 24px;
  }
}
@media (any-hover: none) {
  .text-link.text-link__base:active .text-link--arrow {
    padding-left: 24px;
  }
}
.text-link.text-link__sidebar {
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.text-link.text-link__sidebar .text-link--arrow-wrap {
  width: 80px;
}
@media screen and (min-width: 1280px) and (max-width: 1556px) {
  .text-link.text-link__sidebar .text-link--text {
    max-width: 136px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .text-link.text-link__sidebar .text-link--text {
    max-width: 160px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .text-link.text-link__sidebar .text-link--text {
    max-width: 144px;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .text-link.text-link__sidebar:hover, .text-link.text-link__sidebar:focus {
    color: #98121a;
  }
  html[data-juventus-theme=brand] .text-link.text-link__sidebar:hover, html[data-juventus-theme=brand] .text-link.text-link__sidebar:focus {
    color: #98121a;
  }

  html[data-juventus-theme=highschool] .text-link.text-link__sidebar:hover, html[data-juventus-theme=highschool] .text-link.text-link__sidebar:focus {
    color: #b15b00;
  }

  html[data-juventus-theme=economy] .text-link.text-link__sidebar:hover, html[data-juventus-theme=economy] .text-link.text-link__sidebar:focus {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .text-link.text-link__sidebar:hover, html[data-juventus-theme=medicine] .text-link.text-link__sidebar:focus {
    color: #19756a;
  }

  html[data-juventus-theme=engineering] .text-link.text-link__sidebar:hover, html[data-juventus-theme=engineering] .text-link.text-link__sidebar:focus {
    color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .text-link.text-link__sidebar:hover, html[data-juventus-theme=medical-leadership] .text-link.text-link__sidebar:focus {
    color: #570f4d;
  }

  .text-link.text-link__sidebar:hover .text-link--arrow, .text-link.text-link__sidebar:focus .text-link--arrow {
    padding-left: 24px;
  }
}
@media (any-hover: none) {
  .text-link.text-link__sidebar:active {
    color: #98121a;
  }
  html[data-juventus-theme=brand] .text-link.text-link__sidebar:active {
    color: #98121a;
  }

  html[data-juventus-theme=highschool] .text-link.text-link__sidebar:active {
    color: #b15b00;
  }

  html[data-juventus-theme=economy] .text-link.text-link__sidebar:active {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .text-link.text-link__sidebar:active {
    color: #19756a;
  }

  html[data-juventus-theme=engineering] .text-link.text-link__sidebar:active {
    color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .text-link.text-link__sidebar:active {
    color: #570f4d;
  }

  .text-link.text-link__sidebar:active .text-link--arrow {
    padding-left: 24px;
  }
}
.text-link.text-link__sidebar-text {
  color: #1f1f1f;
}
.text-link.text-link__secondary.text-link__secondary.text-link__secondary {
  color: #1f1f1f;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .text-link.text-link__secondary.text-link__secondary.text-link__secondary:hover, .text-link.text-link__secondary.text-link__secondary.text-link__secondary:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .text-link.text-link__secondary.text-link__secondary.text-link__secondary:active {
    color: #000000;
  }
}
@media (max-width: 1279px) {
  .text-link.text-link__top-spacing {
    padding-top: 12px;
  }
}
@media (min-width: 1280px) {
  .text-link.text-link__top-spacing {
    padding-top: 16px;
  }
}
@media (max-width: 1279px) {
  .text-link.text-link__top-spacing:first-of-type {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .text-link.text-link__top-spacing:first-of-type {
    padding-top: 40px;
  }
}
@media (max-width: 1279px) {
  .text-link.text-link__bottom-spacing {
    padding-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .text-link.text-link__bottom-spacing {
    padding-bottom: 16px;
  }
}

.ui-accordion .ui-accordion--head {
  cursor: pointer;
}
.ui-accordion .ui-accordion--body {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 200ms ease;
}
.ui-accordion.ui-accordion__is-open .ui-accordion--body {
  opacity: 1;
}
.ui-accordion.ui-accordion__is-animating .ui-accordion--body {
  transition: opacity 200ms ease, max-height 200ms ease;
}

.video {
  flex: 0 0 66.6666666667%;
}
@media (max-width: 1023px) {
  .video {
    flex: 0 0 100%;
  }
  html.ie11 .video {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .video {
    flex-basis: auto;
    width: 100%;
  }
}
html.ie11 .video {
  flex-basis: auto;
  width: 66.6666666667%;
}

html.ie10 .video {
  flex-basis: auto;
  width: 66.6666666667%;
}

.video .video--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .video .video--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .video .video--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .video .video--title {
    font-size: 2rem;
  }
}
.video .video--wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
}
.video .video--iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader-btn {
  position: relative;
}
.loader-btn .loader-btn--content {
  transition: opacity 200ms ease;
}
.loader-btn .loader-btn--loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.loader-btn.loader-btn__is-loading .loader-btn--content {
  opacity: 0;
}
.mobile-nav-btn {
  cursor: pointer;
}
.mobile-nav-btn .mobile-nav-btn--root {
  position: relative;
  display: block;
  width: 18px;
  height: 12px;
}
.mobile-nav-btn .mobile-nav-btn--bar {
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  opacity: 1;
  transition: transform 200ms ease-out, opacity 200ms ease-out, background-color 200ms ease-out;
}
.mobile-nav-btn .mobile-nav-btn--bar:nth-child(1) {
  transform: translateY(-6px);
}
.mobile-nav-btn .mobile-nav-btn--bar:nth-child(2) {
  transform: translateY(-1px);
}
.mobile-nav-btn .mobile-nav-btn--bar:nth-child(3) {
  transform: translateY(4px);
}
.mobile-nav-btn.mobile-nav-btn__is-disabled {
  cursor: default;
}
.mobile-nav-btn.mobile-nav-btn__is-disabled .mobile-nav-btn--bar {
  background-color: #f7f7f7;
}
.mobile-nav-btn.mobile-nav-btn__is-active .mobile-nav-btn--bar:nth-child(1) {
  transform: rotate(45deg);
}
.mobile-nav-btn.mobile-nav-btn__is-active .mobile-nav-btn--bar:nth-child(2) {
  opacity: 0;
}
.mobile-nav-btn.mobile-nav-btn__is-active .mobile-nav-btn--bar:nth-child(3) {
  transform: rotate(-45deg);
}

.form-fieldset .form-fieldset--label {
  display: block;
  margin-bottom: 16px;
}

.form-general-messages .form-general-messages--item {
  display: flex;
  width: 100%;
  line-height: initial;
}
.form-general-messages .form-general-messages--item ~ .form-general-messages--item {
  margin-top: 4px;
}
.form-general-messages .form-general-messages--text {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.3;
  color: #ff0c3e;
}

@keyframes ani-dot-loader {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dot-loader {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.dot-loader .dot-loader--loader {
  display: flex;
  justify-content: space-between;
  width: 36px;
  height: 8px;
}
.dot-loader .dot-loader--dot {
  opacity: 0;
  border-radius: 50%;
  display: block;
  width: 8px;
  height: 8px;
  animation: ani-dot-loader 1200ms linear infinite;
}
.dot-loader .dot-loader--dot ~ .dot-loader--dot {
  margin-left: 8px;
}
.dot-loader .dot-loader--dot.dot-loader--dot:nth-child(1) {
  animation-delay: 0ms;
}
.dot-loader .dot-loader--dot.dot-loader--dot:nth-child(2) {
  animation-delay: 200ms;
}
.dot-loader .dot-loader--dot.dot-loader--dot:nth-child(3) {
  animation-delay: 400ms;
}
.dot-loader.dot-loader__base {
  background-color: rgba(255, 255, 255, 0.125);
}
.dot-loader.dot-loader__base .dot-loader--dot {
  background-color: rgba(255, 255, 255, 0.5);
}
.dot-loader.dot-loader__main {
  background-color: rgba(255, 255, 255, 0.125);
}
.dot-loader.dot-loader__main .dot-loader--dot {
  background-color: rgba(0, 0, 0, 0.5);
}
.dot-loader {
  transition: visibility 0ms linear 200ms, opacity 200ms ease 0ms;
  visibility: hidden;
  opacity: 0;
}
.dot-loader.dot-loader__is-active {
  transition: visibility 0ms linear 0ms, opacity 200ms ease 0ms;
  visibility: inherit;
  opacity: 1;
}

.spinning-loader {
  display: inline-block;
  margin: auto;
}
.spinning-loader .spinning-loader--svg {
  width: 100%;
  height: 100%;
}
.spinning-loader .spinning-loader--svg .spinning-loader--svg-path {
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
}

.anchor-navigation {
  pointer-events: none;
  touch-action: none;
}
.anchor-navigation {
  z-index: 20;
  position: sticky;
  top: 0;
}
.anchor-navigation .anchor-navigation--container {
  width: 100%;
}
.anchor-navigation .anchor-navigation--nav-container {
  position: relative;
}
.anchor-navigation .anchor-navigation--col::after {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
}
.anchor-navigation .anchor-navigation--nav {
  pointer-events: auto;
  touch-action: auto;
  width: 100%;
  background: #ffffff;
}
html[data-background-theme=light] .anchor-navigation .anchor-navigation--nav {
  background: #ffffff;
}

html[data-background-theme=dark] .anchor-navigation .anchor-navigation--nav {
  background: #f7f7f7;
}

.anchor-navigation .anchor-navigation--link {
  display: inline-block;
  margin-right: 24px;
  color: #3d3d3d;
  font-size: 1.7rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
@media (max-width: 1279px) {
  .anchor-navigation .anchor-navigation--link {
    padding: 12px 0;
  }
}
@media (min-width: 1280px) {
  .anchor-navigation .anchor-navigation--link {
    padding: 16px 0;
  }
}
.anchor-navigation .anchor-navigation--indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: all 200ms ease-in-out;
}
.anchor-navigation.anchor-navigation__is-hidden {
  display: none;
}
.anchor-navigation.anchor-navigation__is-visible {
  display: inherit;
}
.anchor-navigation .anchor-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .anchor-navigation .anchor-navigation--link:hover, .anchor-navigation .anchor-navigation--link:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .anchor-navigation .anchor-navigation--link:active {
    color: #000000;
  }
}
.anchor-navigation .anchor-navigation--link.anchor-navigation--link__active {
  color: #be1621;
}
html[data-juventus-theme=brand] .anchor-navigation .anchor-navigation--link.anchor-navigation--link__active {
  color: #be1621;
}

html[data-juventus-theme=highschool] .anchor-navigation .anchor-navigation--link.anchor-navigation--link__active {
  color: #dd7200;
}

html[data-juventus-theme=economy] .anchor-navigation .anchor-navigation--link.anchor-navigation--link__active {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .anchor-navigation .anchor-navigation--link.anchor-navigation--link__active {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .anchor-navigation .anchor-navigation--link.anchor-navigation--link__active {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .anchor-navigation .anchor-navigation--link.anchor-navigation--link__active {
  color: #771469;
}

.anchor-navigation .anchor-navigation--nav-container.anchor-navigation--nav-container__hidden {
  pointer-events: none;
  position: relative;
  top: 0;
  visibility: hidden;
  height: 0;
}
.anchor-navigation .anchor-navigation--indicator {
  background-color: #be1621;
}
html[data-juventus-theme=brand] .anchor-navigation .anchor-navigation--indicator {
  background-color: #be1621;
}

html[data-juventus-theme=highschool] .anchor-navigation .anchor-navigation--indicator {
  background-color: #dd7200;
}

html[data-juventus-theme=economy] .anchor-navigation .anchor-navigation--indicator {
  background-color: #286ab7;
}

html[data-juventus-theme=medicine] .anchor-navigation .anchor-navigation--indicator {
  background-color: #1f9285;
}

html[data-juventus-theme=engineering] .anchor-navigation .anchor-navigation--indicator {
  background-color: #948b85;
}

html[data-juventus-theme=medical-leadership] .anchor-navigation .anchor-navigation--indicator {
  background-color: #771469;
}

.anchor-navigation-dropdown {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1279px) {
  .anchor-navigation-dropdown {
    padding: 12px 0;
  }
}
@media (min-width: 1280px) {
  .anchor-navigation-dropdown {
    padding: 16px 0;
  }
}
.anchor-navigation-dropdown .anchor-navigation-dropdown--btn {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  min-width: 32px;
}
.anchor-navigation-dropdown .anchor-navigation-dropdown--list-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  transform: translateY(100%);
  background-color: #ffffff;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
}
.anchor-navigation-dropdown .anchor-navigation-dropdown--list-item {
  position: relative;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1.7rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
.anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link {
  display: inline-block;
  color: #3d3d3d;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
}
@media (max-width: 1279px) {
  .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link {
    padding: 8px 0;
  }
}
@media (min-width: 1280px) {
  .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link {
    padding: 8px 0;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link:hover, .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link:active {
    color: #000000;
  }
}
.anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link.anchor-navigation-dropdown--list-item-link__active {
  position: relative;
  color: #be1621;
}
html[data-juventus-theme=brand] .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link.anchor-navigation-dropdown--list-item-link__active {
  position: relative;
  color: #be1621;
}

html[data-juventus-theme=highschool] .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link.anchor-navigation-dropdown--list-item-link__active {
  position: relative;
  color: #dd7200;
}

html[data-juventus-theme=economy] .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link.anchor-navigation-dropdown--list-item-link__active {
  position: relative;
  color: #286ab7;
}

html[data-juventus-theme=medicine] .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link.anchor-navigation-dropdown--list-item-link__active {
  position: relative;
  color: #1f9285;
}

html[data-juventus-theme=engineering] .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link.anchor-navigation-dropdown--list-item-link__active {
  position: relative;
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .anchor-navigation-dropdown .anchor-navigation-dropdown--list-item-link.anchor-navigation-dropdown--list-item-link__active {
  position: relative;
  color: #771469;
}

.back-to-top {
  pointer-events: none;
  z-index: 15;
  position: fixed;
  bottom: 0;
  width: 100%;
  opacity: 0;
  transition: transform 200ms ease, opacity 200ms ease;
  transform: translate(-6px, 100%);
}
@media (max-width: 1279px) {
  .back-to-top {
    bottom: 24px;
  }
}
@media (min-width: 1280px) {
  .back-to-top {
    bottom: 32px;
  }
}
.back-to-top .back-to-top--button {
  pointer-events: fill;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
}
.back-to-top.back-to-top__top.back-to-top__top {
  opacity: 0;
}
.back-to-top.back-to-top__not-top {
  display: block;
}
.back-to-top.back-to-top__pinned {
  transform: translate(-6px, 100%);
  opacity: 0;
}
.back-to-top.back-to-top__unpinned {
  transform: translate(-6px, 0);
  opacity: 1;
}
@media (min-width: 1024px) {
  .back-to-top.back-to-top__bottom {
    transform: translate(-6px, -48px);
  }
}
@media (max-width: 1023px) {
  .back-to-top.back-to-top__bottom {
    display: none;
  }
}

.collapsible .collapsible--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ededed;
  background: #f7f7f7;
  transition: background 200ms ease, border 200ms ease;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (max-width: 1279px) {
  .collapsible .collapsible--head {
    padding: 16px 16px;
  }
}
@media (min-width: 1280px) {
  .collapsible .collapsible--head {
    padding: 24px 16px;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .collapsible .collapsible--head:hover, .collapsible .collapsible--head:focus {
    background: #ededed;
  }
}
@media (any-hover: none) {
  .collapsible .collapsible--head:active {
    background: #ededed;
  }
}
.collapsible .collapsible--title.collapsible--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.7rem;
  line-height: 1.29;
  color: #000000;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .collapsible .collapsible--title.collapsible--title {
    font-size: 1.6rem;
  }
}
.collapsible .collapsible--content {
  width: 100%;
}
.collapsible .collapsible--content img {
  margin-bottom: 19px;
}
@media (max-width: 1554px) {
  .collapsible .collapsible--content img {
    margin-bottom: 16px;
  }
}
@media (max-width: 766px) {
  .collapsible .collapsible--content img {
    margin-bottom: 8px;
  }
}
.collapsible .collapsible--content.collapsible--content-image-small img {
  max-width: 251px;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 1022px) {
  .collapsible .collapsible--content.collapsible--content-image-small img {
    max-width: 50%;
  }
}
.collapsible .collapsible--content.collapsible--content-image-medium img {
  max-width: 66%;
  width: 100%;
  object-fit: contain;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .collapsible .collapsible--content.collapsible--content-image-medium img {
    max-width: 100%;
  }
}
@media (max-width: 1022px) {
  .collapsible .collapsible--content.collapsible--content-image-medium img {
    max-width: 100%;
  }
}
.collapsible .collapsible--content.collapsible--content-image-large img {
  max-width: 100%;
  width: 100%;
  object-fit: contain;
}
.collapsible .collapsible--icon-wrapper {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}
@media (max-width: 1279px) {
  .collapsible .collapsible--icon-wrapper {
    margin-left: 12px;
  }
}
.collapsible .collapsible--icon {
  transition: transform 200ms ease;
  color: #be1621;
}
html[data-juventus-theme=brand] .collapsible .collapsible--icon {
  color: #be1621;
}

html[data-juventus-theme=highschool] .collapsible .collapsible--icon {
  color: #dd7200;
}

html[data-juventus-theme=economy] .collapsible .collapsible--icon {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .collapsible .collapsible--icon {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .collapsible .collapsible--icon {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .collapsible .collapsible--icon {
  color: #771469;
}

.collapsible .collapsible--body {
  transition: background 200ms ease;
}
@media (max-width: 1279px) {
  .collapsible .collapsible--body {
    padding: 12px 18px;
    margin-bottom: 24px;
    margin-top: 12px;
  }
}
@media (min-width: 1280px) {
  .collapsible .collapsible--body {
    padding: 16px 18px;
    margin-bottom: 28px;
    margin-top: 16px;
  }
}

.form-field-messages {
  position: relative;
  margin-top: 4px;
}
.form-field-messages .form-field-messages--message {
  display: block;
  font-size: 1.2rem;
  line-height: 1.3;
}
.form-field-messages .form-field-messages--message ~ .form-field-messages--message {
  margin-top: 3px;
}
.form-field-messages .form-field-messages--message.form-field-messages--message__info {
  color: #3d3d3d;
}
.form-field-messages .form-field-messages--message.form-field-messages--message__error {
  color: #ff0c3e;
}

.form-submit-base {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 100%;
}
.form-submit-base .form-submit-base--wrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  width: 100%;
}

.form-submit-recaptcha {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 100%;
  padding-top: 48px;
}
@media (max-width: 1279px) {
  .form-submit-recaptcha {
    padding-top: 42px;
  }
}
.form-submit-recaptcha .form-submit-recaptcha--wrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  width: 100%;
}
.form-submit-recaptcha .form-submit-recaptcha--notice-text {
  display: block;
  margin-top: 8px;
  font-size: 1.2rem;
  line-height: 1.3;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger.is-active:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff;
}

.hamburger-box {
  width: 24px;
  height: 9px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 24px;
  height: 1px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -4px;
}
.hamburger-inner::after {
  bottom: -4px;
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger .hamburger-inner {
  background: rgba(255, 255, 255, 0);
  transition: background 200ms ease, transform 200ms ease;
}
.hamburger .hamburger-box {
  padding: 0 16px;
}
@media (max-width: 767px) {
  .hamburger .hamburger-box {
    padding: 16px;
  }
}
.hamburger.hamburger__is-active .hamburger-inner {
  background: #000000;
}
.hamburger.hamburger__is-active .hamburger-inner::before, .hamburger.hamburger__is-active .hamburger-inner::after {
  background: #000000;
}
.hamburger.hamburger__dark.is-active .hamburger-inner {
  background: #000000;
}
.hamburger.hamburger__dark .hamburger-inner::before, .hamburger.hamburger__dark .hamburger-inner::after {
  background: #000000;
}

.main-navigation {
  z-index: 15;
  position: relative;
}
.main-navigation .main-navigation--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.main-navigation .main-navigation--row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .main-navigation .main-navigation--row {
  min-height: 1px;
}

.main-navigation .main-navigation--col {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.main-navigation .main-navigation--container {
  padding-left: 56px;
  padding-right: 56px;
}
.main-navigation .main-navigation--container .main-navigation--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.main-navigation .main-navigation--container .main-navigation--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.main-navigation .main-navigation--row {
  margin-left: -28px;
  margin-right: -28px;
}
.main-navigation .main-navigation--col {
  padding-left: 28px;
  padding-right: 28px;
}
.main-navigation .main-navigation--row {
  justify-content: flex-end;
}
.main-navigation .main-navigation--trigger {
  position: relative;
  cursor: pointer;
  list-style-type: none;
  appearance: none;
  padding: 4px 0;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
  line-height: 1.26;
  color: #000000;
}
@media (max-width: 1279px) {
  .main-navigation .main-navigation--trigger {
    font-size: 1.8rem;
  }
}
.main-navigation .main-navigation--trigger::-webkit-details-marker {
  display: none;
}
.main-navigation .main-navigation--item {
  display: inline-block;
  width: 100%;
}
.main-navigation .main-navigation--link {
  display: inline-block;
  padding: 8px 28px;
}
.main-navigation .main-navigation--line {
  position: absolute;
  left: 0;
  width: 0;
  will-change: width, transform;
  height: 2px;
  background: rgba(0, 0, 0, 0.6);
  margin: 0;
  opacity: 0;
  transition: opacity 200ms ease;
}
.main-navigation .main-navigation--list,
.main-navigation .main-navigation--panel {
  z-index: 5;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 264px;
}
.main-navigation .main-navigation--list {
  max-width: 240px;
  padding: 16px 0;
}
.main-navigation .main-navigation--panel {
  height: 0;
  margin-top: 2px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}
.main-navigation .main-navigation--image {
  position: absolute;
  right: 100%;
  top: 2px;
  height: 100%;
  max-width: 278px;
  object-fit: cover;
  opacity: 0;
  transition: 200ms ease opacity;
  transition-delay: 200ms;
}
.main-navigation .main-navigation--list {
  transition: opacity 200ms ease 100ms;
}
.main-navigation .main-navigation--list.main-navigation--list__fallback {
  z-index: 5;
  position: absolute;
  width: 100%;
  background: #ffffff;
}
.main-navigation .main-navigation--panel.main-navigation--panel__active {
  transition: width 110ms ease, height 110ms ease, transform 110ms ease, opacity 110ms ease, box-shadow 110ms ease;
}
.main-navigation .main-navigation--panel.main-navigation--panel__hidden {
  transition: opacity 200ms ease;
}
.main-navigation .main-navigation--line.main-navigation--line__has-translate-animation {
  transition: width 200ms ease, transform 200ms ease;
}
.main-navigation.main-navigation__dark .main-navigation--link,
.main-navigation.main-navigation__dark .main-navigation--trigger {
  color: #1f1f1f;
}
.main-navigation.main-navigation__dark .main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation.main-navigation__dark .main-navigation--link:hover, .main-navigation.main-navigation__dark .main-navigation--link:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .main-navigation.main-navigation__dark .main-navigation--link:active {
    color: #000000;
  }
}
.main-navigation.main-navigation__light .main-navigation--link__parent {
  color: #240507;
}
html[data-juventus-theme=brand] .main-navigation.main-navigation__light .main-navigation--link__parent {
  color: #240507;
}

html[data-juventus-theme=highschool] .main-navigation.main-navigation__light .main-navigation--link__parent {
  color: #522a00;
}

html[data-juventus-theme=economy] .main-navigation.main-navigation__light .main-navigation--link__parent {
  color: #0b1d33;
}

html[data-juventus-theme=medicine] .main-navigation.main-navigation__light .main-navigation--link__parent {
  color: #092a26;
}

html[data-juventus-theme=engineering] .main-navigation.main-navigation__light .main-navigation--link__parent {
  color: #46423f;
}

html[data-juventus-theme=medical-leadership] .main-navigation.main-navigation__light .main-navigation--link__parent {
  color: #090208;
}

.main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:hover, .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:focus {
    color: black;
  }
  html[data-juventus-theme=brand] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:hover, html[data-juventus-theme=brand] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:focus {
    color: black;
  }

  html[data-juventus-theme=highschool] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:hover, html[data-juventus-theme=highschool] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:focus {
    color: #1f1000;
  }

  html[data-juventus-theme=economy] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:hover, html[data-juventus-theme=economy] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:focus {
    color: #020509;
  }

  html[data-juventus-theme=medicine] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:hover, html[data-juventus-theme=medicine] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:focus {
    color: black;
  }

  html[data-juventus-theme=engineering] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:hover, html[data-juventus-theme=engineering] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:focus {
    color: #2b2927;
  }

  html[data-juventus-theme=medical-leadership] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:hover, html[data-juventus-theme=medical-leadership] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:focus {
    color: black;
  }
}
@media (any-hover: none) {
  .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:active {
    color: black;
  }
  html[data-juventus-theme=brand] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:active {
    color: black;
  }

  html[data-juventus-theme=highschool] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:active {
    color: #1f1000;
  }

  html[data-juventus-theme=economy] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:active {
    color: #020509;
  }

  html[data-juventus-theme=medicine] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:active {
    color: black;
  }

  html[data-juventus-theme=engineering] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:active {
    color: #2b2927;
  }

  html[data-juventus-theme=medical-leadership] .main-navigation.main-navigation__light .main-navigation--link__parent.main-navigation--link:active {
    color: black;
  }
}
.main-navigation.main-navigation__light .main-navigation--line {
  background: rgba(36, 5, 7, 0.6);
}
html[data-juventus-theme=brand] .main-navigation.main-navigation__light .main-navigation--line {
  background: rgba(36, 5, 7, 0.6);
}

html[data-juventus-theme=highschool] .main-navigation.main-navigation__light .main-navigation--line {
  background: rgba(82, 42, 0, 0.6);
}

html[data-juventus-theme=economy] .main-navigation.main-navigation__light .main-navigation--line {
  background: rgba(11, 29, 51, 0.6);
}

html[data-juventus-theme=medicine] .main-navigation.main-navigation__light .main-navigation--line {
  background: rgba(9, 42, 38, 0.6);
}

html[data-juventus-theme=engineering] .main-navigation.main-navigation__light .main-navigation--line {
  background: rgba(70, 66, 63, 0.6);
}

html[data-juventus-theme=medical-leadership] .main-navigation.main-navigation__light .main-navigation--line {
  background: rgba(9, 2, 8, 0.6);
}

.main-navigation .main-navigation--list.main-navigation--list__hidden {
  visibility: hidden;
  opacity: 0;
}
.main-navigation .main-navigation--list.main-navigation--list__visible {
  visibility: visible;
  opacity: 1;
}
.main-navigation .main-navigation--link.main-navigation--link__child {
  color: #5c5c5c;
  padding: 16px 24px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
.main-navigation .main-navigation--item.main-navigation--item__brand .main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation .main-navigation--item.main-navigation--item__brand .main-navigation--link:hover, .main-navigation .main-navigation--item.main-navigation--item__brand .main-navigation--link:focus {
    color: #be1621;
  }
}
@media (any-hover: none) {
  .main-navigation .main-navigation--item.main-navigation--item__brand .main-navigation--link:active {
    color: #be1621;
  }
}
.main-navigation .main-navigation--item.main-navigation--item__brand .main-navigation--link.main-navigation--link__active {
  color: #be1621;
}
.main-navigation .main-navigation--item.main-navigation--item__highschool .main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation .main-navigation--item.main-navigation--item__highschool .main-navigation--link:hover, .main-navigation .main-navigation--item.main-navigation--item__highschool .main-navigation--link:focus {
    color: #dd7200;
  }
}
@media (any-hover: none) {
  .main-navigation .main-navigation--item.main-navigation--item__highschool .main-navigation--link:active {
    color: #dd7200;
  }
}
.main-navigation .main-navigation--item.main-navigation--item__highschool .main-navigation--link.main-navigation--link__active {
  color: #dd7200;
}
.main-navigation .main-navigation--item.main-navigation--item__economy .main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation .main-navigation--item.main-navigation--item__economy .main-navigation--link:hover, .main-navigation .main-navigation--item.main-navigation--item__economy .main-navigation--link:focus {
    color: #286ab7;
  }
}
@media (any-hover: none) {
  .main-navigation .main-navigation--item.main-navigation--item__economy .main-navigation--link:active {
    color: #286ab7;
  }
}
.main-navigation .main-navigation--item.main-navigation--item__economy .main-navigation--link.main-navigation--link__active {
  color: #286ab7;
}
.main-navigation .main-navigation--item.main-navigation--item__medicine .main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation .main-navigation--item.main-navigation--item__medicine .main-navigation--link:hover, .main-navigation .main-navigation--item.main-navigation--item__medicine .main-navigation--link:focus {
    color: #1f9285;
  }
}
@media (any-hover: none) {
  .main-navigation .main-navigation--item.main-navigation--item__medicine .main-navigation--link:active {
    color: #1f9285;
  }
}
.main-navigation .main-navigation--item.main-navigation--item__medicine .main-navigation--link.main-navigation--link__active {
  color: #1f9285;
}
.main-navigation .main-navigation--item.main-navigation--item__engineering .main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation .main-navigation--item.main-navigation--item__engineering .main-navigation--link:hover, .main-navigation .main-navigation--item.main-navigation--item__engineering .main-navigation--link:focus {
    color: #948b85;
  }
}
@media (any-hover: none) {
  .main-navigation .main-navigation--item.main-navigation--item__engineering .main-navigation--link:active {
    color: #948b85;
  }
}
.main-navigation .main-navigation--item.main-navigation--item__engineering .main-navigation--link.main-navigation--link__active {
  color: #948b85;
}
.main-navigation .main-navigation--item.main-navigation--item__medical-leadership .main-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation .main-navigation--item.main-navigation--item__medical-leadership .main-navigation--link:hover, .main-navigation .main-navigation--item.main-navigation--item__medical-leadership .main-navigation--link:focus {
    color: #771469;
  }
}
@media (any-hover: none) {
  .main-navigation .main-navigation--item.main-navigation--item__medical-leadership .main-navigation--link:active {
    color: #771469;
  }
}
.main-navigation .main-navigation--item.main-navigation--item__medical-leadership .main-navigation--link.main-navigation--link__active {
  color: #771469;
}
.main-navigation .main-navigation--item {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-navigation .main-navigation--item:hover .main-navigation--image, .main-navigation .main-navigation--item:focus .main-navigation--image {
    opacity: 1;
    z-index: 1;
    transition-delay: 0ms;
  }
}
@media (any-hover: none) {
  .main-navigation .main-navigation--item:active .main-navigation--image {
    opacity: 1;
    z-index: 1;
    transition-delay: 0ms;
  }
}

.multi-logo {
  display: flex;
  align-items: center;
}
.multi-logo .multi-logo--image {
  width: 100%;
  max-width: 220px;
}
@media (max-width: 1279px) {
  .multi-logo .multi-logo--image {
    max-width: 200px;
  }
}

.overlay-recipe-dialog {
  position: relative;
  margin: 0 24px;
  width: 580px;
  max-width: 100%;
  padding: 64px 32px 32px;
  background-color: #ffffff;
}
.overlay-recipe-dialog .overlay-recipe-dialog__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .overlay-recipe-dialog .overlay-recipe-dialog__close:hover, .overlay-recipe-dialog .overlay-recipe-dialog__close:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .overlay-recipe-dialog .overlay-recipe-dialog__close:active {
    color: #98121a;
  }
}
.overlay-recipe-dialog .overlay-recipe-dialog--actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

/* stylelint-disable plugin/selector-bem-pattern */
.parallax-container [data-parallax] {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease-in;
}
.parallax-container.parallax-container__is-ready [data-parallax] {
  visibility: visible;
  opacity: 1;
}

.picture-element {
  transition: filter 200ms ease;
}
.picture-element.picture-element__is-blurry {
  filter: blur(8px);
  -webkit-transform: translate3d(0, 0, 0);
}
.no-js .picture-element.picture-element__is-blurry {
  filter: none;
}

.side-navigation {
  pointer-events: none;
  z-index: 13;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.side-navigation::after {
  content: "";
  position: absolute;
  top: 100vh;
  right: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
}
.side-navigation .side-navigation--panel {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 88px;
  background: #ffffff;
  overflow: auto;
}
.side-navigation .side-navigation--panel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 88px;
  background: #ffffff;
  transition: opacity 200ms ease;
}
.side-navigation .side-navigation--list {
  display: flex;
  flex-direction: column;
}
.side-navigation .side-navigation--link {
  display: flex;
  flex-direction: column;
}
.side-navigation .side-navigation--accordion-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-navigation.side-navigation__is-open {
  pointer-events: auto;
}
.side-navigation.side-navigation__is-open::after {
  display: block;
}
@media (min-width: 576px) {
  .side-navigation .side-navigation--list.side-navigation--list__root {
    padding: 0 40px;
  }
}
.side-navigation .side-navigation--link.side-navigation--link__main {
  padding: 24px 0;
  color: #3d3d3d;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (max-width: 1279px) {
  .side-navigation .side-navigation--link.side-navigation--link__main {
    font-size: 2rem;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--link.side-navigation--link__main:hover, .side-navigation .side-navigation--link.side-navigation--link__main:focus {
    color: #1f1f1f;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--link.side-navigation--link__main:active {
    color: #1f1f1f;
  }
}
.side-navigation .side-navigation--link.side-navigation--link__child {
  padding: 16px 0 16px 24px;
  color: #7a7a7a;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--link.side-navigation--link__child:hover, .side-navigation .side-navigation--link.side-navigation--link__child:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--link.side-navigation--link__child:active {
    color: #000000;
  }
}
.side-navigation .side-navigation--list.side-navigation--list__children {
  margin-top: -8px;
}
.side-navigation .side-navigation--list.side-navigation--list__child {
  padding-bottom: 8px;
}
.side-navigation .side-navigation--item.side-navigation--item__brand .side-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--item.side-navigation--item__brand .side-navigation--link:hover, .side-navigation .side-navigation--item.side-navigation--item__brand .side-navigation--link:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--item.side-navigation--item__brand .side-navigation--link:active {
    color: #98121a;
  }
}
.side-navigation .side-navigation--item.side-navigation--item__brand .side-navigation--link.side-navigation--link__active {
  color: #98121a;
}
.side-navigation .side-navigation--item.side-navigation--item__highschool .side-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--item.side-navigation--item__highschool .side-navigation--link:hover, .side-navigation .side-navigation--item.side-navigation--item__highschool .side-navigation--link:focus {
    color: #b15b00;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--item.side-navigation--item__highschool .side-navigation--link:active {
    color: #b15b00;
  }
}
.side-navigation .side-navigation--item.side-navigation--item__highschool .side-navigation--link.side-navigation--link__active {
  color: #b15b00;
}
.side-navigation .side-navigation--item.side-navigation--item__economy .side-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--item.side-navigation--item__economy .side-navigation--link:hover, .side-navigation .side-navigation--item.side-navigation--item__economy .side-navigation--link:focus {
    color: #286ab7;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--item.side-navigation--item__economy .side-navigation--link:active {
    color: #286ab7;
  }
}
.side-navigation .side-navigation--item.side-navigation--item__economy .side-navigation--link.side-navigation--link__active {
  color: #286ab7;
}
.side-navigation .side-navigation--item.side-navigation--item__medicine .side-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--item.side-navigation--item__medicine .side-navigation--link:hover, .side-navigation .side-navigation--item.side-navigation--item__medicine .side-navigation--link:focus {
    color: #19756a;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--item.side-navigation--item__medicine .side-navigation--link:active {
    color: #19756a;
  }
}
.side-navigation .side-navigation--item.side-navigation--item__medicine .side-navigation--link.side-navigation--link__active {
  color: #19756a;
}
.side-navigation .side-navigation--item.side-navigation--item__engineering .side-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--item.side-navigation--item__engineering .side-navigation--link:hover, .side-navigation .side-navigation--item.side-navigation--item__engineering .side-navigation--link:focus {
    color: #766f6a;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--item.side-navigation--item__engineering .side-navigation--link:active {
    color: #766f6a;
  }
}
.side-navigation .side-navigation--item.side-navigation--item__engineering .side-navigation--link.side-navigation--link__active {
  color: #766f6a;
}
.side-navigation .side-navigation--item.side-navigation--item__medical-leadership .side-navigation--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .side-navigation .side-navigation--item.side-navigation--item__medical-leadership .side-navigation--link:hover, .side-navigation .side-navigation--item.side-navigation--item__medical-leadership .side-navigation--link:focus {
    color: #570f4d;
  }
}
@media (any-hover: none) {
  .side-navigation .side-navigation--item.side-navigation--item__medical-leadership .side-navigation--link:active {
    color: #570f4d;
  }
}
.side-navigation .side-navigation--item.side-navigation--item__medical-leadership .side-navigation--link.side-navigation--link__active {
  color: #570f4d;
}

.sidebar-block {
  margin-bottom: 24px;
}
.sidebar-block:last-of-type {
  margin-bottom: 0;
}
.sidebar-block .sidebar-block--header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 24px;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-color: #be1621;
  color: #be1621;
}
html[data-juventus-theme=brand] .sidebar-block .sidebar-block--header {
  border-color: #be1621;
  color: #be1621;
}

html[data-juventus-theme=highschool] .sidebar-block .sidebar-block--header {
  border-color: #dd7200;
  color: #dd7200;
}

html[data-juventus-theme=economy] .sidebar-block .sidebar-block--header {
  border-color: #286ab7;
  color: #286ab7;
}

html[data-juventus-theme=medicine] .sidebar-block .sidebar-block--header {
  border-color: #1f9285;
  color: #1f9285;
}

html[data-juventus-theme=engineering] .sidebar-block .sidebar-block--header {
  border-color: #948b85;
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .sidebar-block .sidebar-block--header {
  border-color: #771469;
  color: #771469;
}

.sidebar-block .sidebar-block--title {
  padding-right: 24px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
  line-height: 1.26;
  color: #000000;
  color: #be1621;
}
@media (max-width: 1279px) {
  .sidebar-block .sidebar-block--title {
    font-size: 1.8rem;
  }
}
html[data-juventus-theme=brand] .sidebar-block .sidebar-block--title {
  color: #be1621;
}

html[data-juventus-theme=highschool] .sidebar-block .sidebar-block--title {
  color: #dd7200;
}

html[data-juventus-theme=economy] .sidebar-block .sidebar-block--title {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .sidebar-block .sidebar-block--title {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .sidebar-block .sidebar-block--title {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .sidebar-block .sidebar-block--title {
  color: #771469;
}

.sidebar-block .sidebar-block--icon {
  flex: 0 0 24px;
  align-self: center;
  width: 24px;
}
.sidebar-block .sidebar-block--body {
  padding: 0 24px 24px;
}
.sidebar-block .sidebar-block--body-wrap {
  overflow: hidden;
  will-change: height;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  border-color: #be1621;
}
html[data-juventus-theme=brand] .sidebar-block .sidebar-block--body-wrap {
  border-color: #be1621;
}

html[data-juventus-theme=highschool] .sidebar-block .sidebar-block--body-wrap {
  border-color: #dd7200;
}

html[data-juventus-theme=economy] .sidebar-block .sidebar-block--body-wrap {
  border-color: #286ab7;
}

html[data-juventus-theme=medicine] .sidebar-block .sidebar-block--body-wrap {
  border-color: #1f9285;
}

html[data-juventus-theme=engineering] .sidebar-block .sidebar-block--body-wrap {
  border-color: #948b85;
}

html[data-juventus-theme=medical-leadership] .sidebar-block .sidebar-block--body-wrap {
  border-color: #771469;
}

.sidebar-block.sidebar-block__is-toggleable .sidebar-block--header {
  cursor: pointer;
}
.sidebar-block.sidebar-block__has-animated-height .sidebar-block--body-wrap {
  transition: height 200ms ease;
}
.sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--header {
  background-color: #be1621;
}
html[data-juventus-theme=brand] .sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--header {
  background-color: #be1621;
}

html[data-juventus-theme=highschool] .sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--header {
  background-color: #dd7200;
}

html[data-juventus-theme=economy] .sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--header {
  background-color: #286ab7;
}

html[data-juventus-theme=medicine] .sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--header {
  background-color: #1f9285;
}

html[data-juventus-theme=engineering] .sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--header {
  background-color: #948b85;
}

html[data-juventus-theme=medical-leadership] .sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--header {
  background-color: #771469;
}

.sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--title {
  color: #ffffff;
}
.sidebar-block.sidebar-block__has-animated-height:not(.sidebar-block__is-open) .sidebar-block--icon {
  color: #ffffff;
}

.wizard-contact-step--head {
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px;
  background-color: #f7f7f7;
  border: 1px solid #ededed;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.wizard-contact-step--head.wizard-contact-step--head__show-edit .wizard-contact-step--head-icon {
  transition: visibility 0ms linear 0ms, opacity 200ms ease 0ms;
  visibility: inherit;
  opacity: 1;
}
.wizard-contact-step--head.wizard-contact-step--head__has-errors {
  background-color: #fffafb;
  border-color: #ff0c3e;
}
.wizard-contact-step--head.wizard-contact-step--head__is-active {
  background-color: #e0e0e0;
  border-color: #d1d1d1;
}
.wizard-contact-step--head.wizard-contact-step--head__is-active.wizard-contact-step--head__has-errors {
  background-color: #ffe7ec;
  border-color: #ff0c3e;
}

.wizard-contact-step--collapsible ~ .wizard-contact-step--collapsible {
  margin-top: 8px;
}

@media (max-width: 1279px) {
  .wizard-contact-step--body {
    padding: 32px 0;
  }
}
@media (min-width: 1280px) {
  .wizard-contact-step--body {
    padding: 40px 0;
  }
}

.wizard-contact-step--head-text {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
  line-height: 1.26;
  color: #000000;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .wizard-contact-step--head-text {
    font-size: 1.8rem;
  }
}

.wizard-contact-step--head-icon {
  transition: visibility 0ms linear 200ms, opacity 200ms ease 0ms;
  visibility: hidden;
  opacity: 0;
}

.wizard-contact-step--head-edit {
  display: block;
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .wizard-contact-step--head-edit:hover, .wizard-contact-step--head-edit:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .wizard-contact-step--head-edit:active {
    color: #98121a;
  }
}

.wizard-contact-step--messages {
  padding-left: 17px;
}

.wizard-course-select {
  width: 100%;
}
.wizard-course-select .wizard-course-select--precourses {
  margin-bottom: 10px;
}
.wizard-course-select .wizard-course-select--precourse-title {
  margin-bottom: 10px;
}

.wizard-drop-zone--input-container {
  height: 80px;
}

.wizard-drop-zone--input {
  cursor: pointer;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
}
.wizard-drop-zone--input:hover, .wizard-drop-zone--input:active {
  border-color: #000000 !important;
  border-style: solid !important;
}
.wizard-drop-zone--input:hover ~ .wizard-drop-zone--empty-label, .wizard-drop-zone--input:active ~ .wizard-drop-zone--empty-label {
  color: #000000 !important;
}

.wizard-drop-zone--empty-label {
  pointer-events: none;
  display: flex;
  align-items: center;
}

@media (min-width: 1280px) {
  .wizard-radio-tags {
    padding-top: 40px;
  }
}
@media (max-width: 1279px) {
  .wizard-radio-tags {
    padding-top: 20px;
  }
}
.wizard-radio-tags.wizard-radio-tags__no-spacing {
  padding-top: 0;
}
.wizard-radio-tags.wizard-radio-tags__bottom-spacing {
  padding-bottom: 16px;
}

.wizard-radio-tags__messages {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1279px) {
  .wizard-radio-tags__messages {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .wizard-radio-tags__messages {
    margin-bottom: 16px;
  }
}

.wizard-show-more-uploads {
  text-align: right;
  color: #be1621;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

.wizard-submit-wrapper {
  display: flex;
  justify-content: flex-end;
}

.anchor-placeholder {
  position: relative;
}
.anchor-placeholder .anchor-placeholder--title {
  font-size: 1.7rem;
  margin-bottom: 8px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #be1621;
}
html[data-juventus-theme=brand] .anchor-placeholder .anchor-placeholder--title {
  color: #be1621;
}

html[data-juventus-theme=highschool] .anchor-placeholder .anchor-placeholder--title {
  color: #dd7200;
}

html[data-juventus-theme=economy] .anchor-placeholder .anchor-placeholder--title {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .anchor-placeholder .anchor-placeholder--title {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .anchor-placeholder .anchor-placeholder--title {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .anchor-placeholder .anchor-placeholder--title {
  color: #771469;
}

.anchor-placeholder .anchor-placeholder--hook {
  position: absolute;
  left: 0;
}
@media (max-width: 1279px) {
  .anchor-placeholder .anchor-placeholder--hook {
    top: -62px;
  }
}
@media (min-width: 1280px) {
  .anchor-placeholder .anchor-placeholder--hook {
    top: -70px;
  }
}
.anchor-placeholder.anchor-placeholder__brand .anchor-placeholder--title {
  color: #be1621;
}
.anchor-placeholder.anchor-placeholder__highschool .anchor-placeholder--title {
  color: #dd7200;
}
.anchor-placeholder.anchor-placeholder__economy .anchor-placeholder--title {
  color: #286ab7;
}
.anchor-placeholder.anchor-placeholder__medicine .anchor-placeholder--title {
  color: #1f9285;
}
.anchor-placeholder.anchor-placeholder__engineering .anchor-placeholder--title {
  color: #948b85;
}
.anchor-placeholder.anchor-placeholder__medical-leadership .anchor-placeholder--title {
  color: #771469;
}

.annual-report-numbers {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 1024px) {
  .annual-report-numbers {
    grid-template-columns: 1fr;
  }
}
.annual-report-numbers .annual-report-numbers--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value,
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-description {
  display: inline-block;
  width: auto;
}
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value {
  color: #be1621;
  font-weight: 500;
  font-size: calc(90px + (120 - 90) * ((100vw - 320px) / (768 - 320)));
}
@media (max-width: 767px) {
  .annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value {
    font-size: 90px;
  }
}
@media (min-width: 768px) {
  .annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-value {
    font-size: 120px;
  }
}
.annual-report-numbers .annual-report-numbers--detail .annual-report-numbers--detail-description {
  color: #5c5c5c;
  font-size: 17px;
}

.article-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.article-detail .article-detail--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.article-detail .article-detail--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .article-detail .article-detail--list {
  min-height: 1px;
}

.article-detail .article-detail--tile {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .article-detail .article-detail--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .article-detail .article-detail--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .article-detail .article-detail--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .article-detail .article-detail--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .article-detail .article-detail--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .article-detail .article-detail--tile {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .article-detail .article-detail--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .article-detail .article-detail--tile {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .article-detail .article-detail--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .article-detail .article-detail--tile {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .article-detail .article-detail--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .article-detail .article-detail--container .article-detail--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .article-detail .article-detail--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .article-detail .article-detail--tile {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.article-detail .article-detail--two-cols {
  width: 66%;
}
@media (max-width: 1024px) {
  .article-detail .article-detail--two-cols {
    width: 100%;
  }
}
.article-detail .article-detail--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 4rem;
  line-height: 1.1;
  color: #000000;
}
@media (max-width: 1279px) {
  .article-detail .article-detail--title {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .article-detail .article-detail--title {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .article-detail .article-detail--title {
    font-size: 3.2rem;
    line-height: 1.125;
  }
}
.article-detail .article-detail--lead {
  color: #5c5c5c;
}
.article-detail .article-detail--info {
  display: flex;
  align-items: center;
  padding: 18px 0 40px;
}
.article-detail .article-detail--info .article-detail--article-info {
  display: flex;
}
.article-detail .article-detail--info .article-detail--article-info .article-detail--article-author {
  margin-right: 8px;
  color: #1f1f1f;
}
.article-detail .article-detail--info .article-detail--article-info .article-detail--article-author::after {
  content: "•";
  margin: 0 5px;
  color: #be1621;
}
.article-detail .article-detail--info .article-detail--article-info .article-detail--article-published {
  color: #1f1f1f;
}
.article-detail .article-detail--info .article-detail--article-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background color 200ms ease;
  color: #ffffff;
  background: #be1621;
  margin-right: 30px;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .article-detail .article-detail--info .article-detail--article-tag {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.article-detail .article-detail--image-container {
  width: 100%;
}
@media (max-width: 1024px) {
  .article-detail .article-detail--image-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    max-width: none;
    overflow-x: hidden;
  }
}
.article-detail .article-detail--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-detail .article-detail--image-credits {
  margin-top: 10px;
  font-size: 12px;
  color: #7a7a7a;
}
.article-detail .article-detail--richtext {
  margin-top: 65px;
  /* stylelint-disable plugin/selector-bem-pattern */
  /* stylelint-enable plugin/selector-bem-pattern */
}
.article-detail .article-detail--richtext h1,
.article-detail .article-detail--richtext h2,
.article-detail .article-detail--richtext h3 {
  margin-top: 65px;
}
.article-detail .article-detail--richtext .image {
  margin: 65px 0;
}
.article-detail .article-detail--related-articles {
  margin-top: 80px;
}

.article-detail--sub-navigation {
  display: flex;
  margin-top: 30px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.article-detail--sub-navigation .article-detail--exit-arrow-label {
  color: #be1621;
  font-weight: 500;
}
.article-detail--sub-navigation .article-detail--exit-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 20px;
  margin-bottom: 4px;
}
.article-detail--sub-navigation .article-detail--exit-arrow {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  height: 1px;
  width: 32px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  color: #be1621;
}
.article-detail--sub-navigation .article-detail--exit-arrow::after, .article-detail--sub-navigation .article-detail--exit-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
}
.article-detail--sub-navigation .article-detail--exit-arrow::after {
  transform: translate(-4px, -50%) rotate(-45deg);
  transition: left 200ms ease;
  border-left: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 8px;
  height: 8px;
}
.article-detail--sub-navigation .article-detail--exit-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(-5px, -50%);
  transition: width 200ms ease, left 200ms ease;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .article-detail--sub-navigation:hover .article-detail--exit-arrow, .article-detail--sub-navigation:focus .article-detail--exit-arrow {
    color: #98121a;
  }
  .article-detail--sub-navigation:hover .article-detail--exit-arrow::after, .article-detail--sub-navigation:focus .article-detail--exit-arrow::after {
    left: -15px;
  }
  .article-detail--sub-navigation:hover .article-detail--exit-arrow::before, .article-detail--sub-navigation:focus .article-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .article-detail--sub-navigation:hover .article-detail--exit-arrow-label, .article-detail--sub-navigation:focus .article-detail--exit-arrow-label {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .article-detail--sub-navigation:active .article-detail--exit-arrow {
    color: #98121a;
  }
  .article-detail--sub-navigation:active .article-detail--exit-arrow::after {
    left: -15px;
  }
  .article-detail--sub-navigation:active .article-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .article-detail--sub-navigation:active .article-detail--exit-arrow-label {
    color: #98121a;
  }
}

.big-footer {
  color: #7a7a7a;
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  font-size: 1.5rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .big-footer {
    padding: 24px 0;
  }
}
@media (min-width: 1280px) {
  .big-footer {
    padding: 32px 0;
  }
}
@media (min-width: 1280px) {
  .big-footer .big-footer--col {
    flex: 0 0 25%;
  }
  html.ie11 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 25%;
  }

  html.ie10 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 25%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .big-footer .big-footer--col {
    flex: 0 0 50%;
  }
  html.ie11 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .big-footer .big-footer--col {
    flex: 0 0 100%;
  }
  html.ie11 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .big-footer .big-footer--col {
    flex-basis: auto;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .big-footer .big-footer--col {
    margin-top: 24px;
  }
}
@media (max-width: 1279px) {
  .big-footer .big-footer--col {
    margin-top: 20px;
  }
}
.big-footer .big-footer--text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
}
@media (min-width: 768px) {
  .big-footer .big-footer--text {
    height: 100%;
  }
}
.big-footer .big-footer--link {
  color: #5c5c5c;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .big-footer .big-footer--link:hover, .big-footer .big-footer--link:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .big-footer .big-footer--link:active {
    color: #000000;
  }
}
@media (min-width: 768px) {
  .big-footer .big-footer--row {
    padding-left: 53px;
  }
}
@media (max-width: 767px) {
  .big-footer .big-footer--row {
    padding-left: 0;
  }
}
.big-footer .big-footer--link.big-footer--link__menu {
  display: inline-block;
  padding: 8px 0;
}
@media (max-width: 767px) {
  .big-footer .big-footer--link.big-footer--link__menu-in-text {
    display: inline-block;
    padding-top: 16px;
  }
}

.chart-legend.chart-legend--donut .chart-legend--item-description {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 280px));
  justify-items: start;
  align-items: flex-start;
  gap: 5px;
  margin-top: 32px;
}
@media (max-width: 1279px) {
  .chart-legend.chart-legend--donut .chart-legend--item-description {
    grid-template-columns: 1fr;
  }
}
.chart-legend.chart-legend--donut .chart-legend--item-description .chart-legend--item-description-wrapper {
  width: auto;
  justify-self: start;
}
.chart-legend.chart-legend--donut .chart-legend--item-description .chart-legend--item-description-color {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.chart-legend.chart-legend--donut .chart-legend--item-description .chart-legend--item-description-text {
  display: inline-block;
  font-size: 13px;
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-vertical {
  width: 100%;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--item {
  line-height: 1.41;
  font-size: 12px;
  letter-spacing: 0.01rem;
  margin-bottom: 5px;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--text {
  display: inline-block;
  font-size: 12px;
  color: #5c5c5c;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--text:first-child {
  color: #000000;
}
.chart-legend.chart-legend--bar-vertical .chart-legend--text:last-child::before {
  content: "=";
}
.chart-legend.chart-legend--bar-vertical-stacked {
  width: 100%;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description .chart-legend--item-description-wrapper:not(:first-child) {
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description .chart-legend--item-description-color {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item-description .chart-legend--item-description-text {
  display: inline-block;
  font-size: 13px;
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--item {
  line-height: 1.41;
  font-size: 12px;
  letter-spacing: 0.01rem;
  margin-bottom: 5px;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--text {
  display: inline-block;
  font-size: 12px;
  color: #5c5c5c;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--text:first-child {
  color: #000000;
}
.chart-legend.chart-legend--bar-vertical-stacked .chart-legend--text:last-child::before {
  content: "=";
}
.chart-legend.chart-legend--bar-horizontal-stacked {
  width: 100%;
  margin-top: -20px;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description .chart-legend--item-description-wrapper:not(:first-child) {
  margin-left: 10px;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description .chart-legend--item-description-color {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.chart-legend.chart-legend--bar-horizontal-stacked .chart-legend--item-description .chart-legend--item-description-text {
  display: inline-block;
  font-size: 13px;
  margin-left: 10px;
}

.chart--container-donut {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
@media (max-width: 1023px) {
  .chart--container-donut {
    grid-template-columns: 1fr;
  }
  .chart--container-donut .chart--container-donut-content:not(:last-child) {
    margin-bottom: 32px;
  }
}

.chart--container-bar-vertical .chart--container-bar-vertical-content {
  overflow-x: scroll;
}

.chart--container-bar-vertical-stacked .chart--container-bar-vertical-stacked-content {
  overflow-x: scroll;
}

.chart--container-bar-horizontal-stacked .chart--container-bar-horizontal-stacked-content {
  overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chart--container-bar-vertical-content::-webkit-scrollbar,
.chart--container-bar-vertical-stacked-content::-webkit-scrollbar,
.chart--container-bar-horizontal-stacked-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.chart--container-bar-vertical-content,
.chart--container-bar-vertical-stacked-content,
.chart--container-bar-horizontal-stacked-content {
  scrollbar-width: none;
}

.contact-detail--legal-guardian-form {
  margin-top: 24px;
  padding: 16px;
  margin-bottom: -56px;
  border: 1px solid #be1621;
  color: #be1621;
}
html[data-juventus-theme=brand] .contact-detail--legal-guardian-form {
  border: 1px solid #be1621;
  color: #be1621;
}

html[data-juventus-theme=highschool] .contact-detail--legal-guardian-form {
  border: 1px solid #dd7200;
  color: #dd7200;
}

html[data-juventus-theme=economy] .contact-detail--legal-guardian-form {
  border: 1px solid #286ab7;
  color: #286ab7;
}

html[data-juventus-theme=medicine] .contact-detail--legal-guardian-form {
  border: 1px solid #1f9285;
  color: #1f9285;
}

html[data-juventus-theme=engineering] .contact-detail--legal-guardian-form {
  border: 1px solid #948b85;
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .contact-detail--legal-guardian-form {
  border: 1px solid #771469;
  color: #771469;
}

.contact-detail--description {
  margin-bottom: 8px;
}

.contact-detail--legal-guardian-upload {
  margin: 0 -8px -8px;
}

.contact-map .contact-map--wrap {
  background: #f7f7f7;
  padding-bottom: 24px;
}
@media (min-width: 1024px) {
  .contact-map .contact-map--wrap {
    padding: 32px;
  }
}
.contact-map .contact-map--lead {
  color: #5c5c5c;
  font-size: 2.2rem;
  line-height: 1.31;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .contact-map .contact-map--lead {
    font-size: 2rem;
    line-height: 1.35;
  }
}
@media (max-width: 1279px) {
  .contact-map .contact-map--row ~ .contact-map--row {
    margin-top: 24px;
  }
}
@media (min-width: 1280px) {
  .contact-map .contact-map--row ~ .contact-map--row {
    margin-top: 32px;
  }
}
.contact-map .contact-map--iframe-wrap {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
}
@media (max-width: 575px) {
  .contact-map .contact-map--iframe-wrap {
    padding-bottom: 100%;
  }
}
.contact-map .contact-map--iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1279px) {
  .contact-map .contact-map--row.contact-map--row__intro {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .contact-map .contact-map--row.contact-map--row__intro {
    margin-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .contact-map .contact-map--row.contact-map--row__text {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.cookie-monster {
  z-index: 20;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
  padding: 16px 0;
  width: 100%;
}
.cookie-monster .cookie-monster--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.cookie-monster .cookie-monster--row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .cookie-monster .cookie-monster--row {
  min-height: 1px;
}

.cookie-monster .cookie-monster--col {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--container {
    max-width: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookie-monster .cookie-monster--container {
    max-width: 512px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-monster .cookie-monster--container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .cookie-monster .cookie-monster--container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .cookie-monster .cookie-monster--container {
    max-width: 1280px;
  }
}
@media (min-width: 1556px) {
  .cookie-monster .cookie-monster--container {
    max-width: 1556px;
  }
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 22px;
    padding-right: 22px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -16px;
    margin-right: -16px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .cookie-monster .cookie-monster--container {
    padding-left: 28px;
    padding-right: 28px;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .cookie-monster .cookie-monster--container .cookie-monster--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .cookie-monster .cookie-monster--row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .cookie-monster .cookie-monster--col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.cookie-monster .cookie-monster--title {
  display: none;
}
.cookie-monster .cookie-monster--content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .cookie-monster .cookie-monster--content {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--content {
    flex-wrap: wrap;
  }
}
.cookie-monster .cookie-monster--text a {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--text a:hover, .cookie-monster .cookie-monster--text a:focus {
    text-decoration: underline;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--text a:active {
    text-decoration: underline;
  }
}
@media (min-width: 576px) {
  .cookie-monster .cookie-monster--btn-group {
    flex: 1 0 auto;
    padding-left: 16px;
  }
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--btn-group {
    margin-top: 24px;
    flex: 1 1 100%;
  }
}
.cookie-monster .cookie-monster--btn-wrapper {
  display: none;
  justify-content: flex-end;
}
@media (max-width: 575px) {
  .cookie-monster .cookie-monster--btn-wrapper {
    justify-content: center;
  }
}
.cookie-monster .cookie-monster--btn-wrapper:nth-child(2n) {
  display: flex;
}
.cookie-monster .cookie-monster--btn {
  background: #be1621;
  color: #ffffff;
  padding: 6px 16px;
  font-size: 1.7rem;
  line-height: 1.41;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.cookie-monster .cookie-monster--btn {
  border: 2px solid transparent;
  border-radius: 0;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
.cookie-monster .cookie-monster--btn {
  text-decoration: none;
}
.cookie-monster .cookie-monster--btn:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):hover, .cookie-monster .cookie-monster--btn:not(:disabled):focus {
    text-decoration: none;
    cursor: pointer;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):active {
    text-decoration: none;
    cursor: pointer;
  }
}
.cookie-monster .cookie-monster--btn:not(:disabled) {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):hover:active, .cookie-monster .cookie-monster--btn:not(:disabled):focus:active {
    text-decoration: none;
    cursor: pointer;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:not(:disabled):active:active {
    text-decoration: none;
    cursor: pointer;
  }
}
.cookie-monster .cookie-monster--btn:disabled {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:disabled:hover, .cookie-monster .cookie-monster--btn:disabled:focus {
    text-decoration: none;
    cursor: default;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:disabled:active {
    text-decoration: none;
    cursor: default;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-monster .cookie-monster--btn:hover, .cookie-monster .cookie-monster--btn:focus {
    background: #98121a;
  }
}
@media (any-hover: none) {
  .cookie-monster .cookie-monster--btn:active {
    background: #98121a;
  }
}

@media (max-width: 1279px) {
  .faq .faq--item ~ .faq--item {
    padding: 8px 0 0;
  }
}
@media (min-width: 1280px) {
  .faq .faq--item ~ .faq--item {
    padding: 8px 0 0;
  }
}

.grid-annual-report .grid-annual-report--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-annual-report .grid-annual-report--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-annual-report .grid-annual-report--list {
  min-height: 1px;
}

.grid-annual-report .grid-annual-report--item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .grid-annual-report {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.grid-annual-report .grid-annual-report--item {
  height: 100%;
  margin-bottom: 24px;
  max-width: 376px;
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item {
    flex: 0 0 50%;
  }
  html.ie11 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-annual-report .grid-annual-report--item {
    flex: 0 0 100%;
  }
  html.ie11 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-annual-report .grid-annual-report--item {
    flex-basis: auto;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 32px;
  }
}
.grid-annual-report .grid-annual-report--link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 228px;
  background: #f7f7f7;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 390px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 400px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 410px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 423px;
  }
}
@media (min-width: 1556px) {
  .grid-annual-report .grid-annual-report--link {
    min-height: 423px;
  }
}
html[data-juventus-theme=brand] .grid-annual-report .grid-annual-report--link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-annual-report .grid-annual-report--link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-annual-report .grid-annual-report--link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-annual-report .grid-annual-report--link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-annual-report .grid-annual-report--link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-annual-report .grid-annual-report--link {
  color: #771469;
}

.grid-annual-report .grid-annual-report--item-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item-image {
    max-height: 250px;
  }
}
@media (max-width: 767px) {
  .grid-annual-report .grid-annual-report--item-image {
    max-height: 218px;
  }
}
.grid-annual-report .grid-annual-report--item-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  border: 1px solid #ededed;
  border-top: none;
}
@media (min-width: 1024px) {
  .grid-annual-report .grid-annual-report--item-text {
    min-height: 172px;
  }
}
@media (max-width: 767px) {
  .grid-annual-report .grid-annual-report--item-text {
    min-height: 172px;
  }
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #be1621;
}
@media (max-width: 1279px) {
  .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-title {
    font-size: 2rem;
  }
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  position: relative;
  left: -10px;
  margin-left: 0;
  color: #be1621;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::after, .grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-annual-report .grid-annual-report--item-text .grid-annual-report--item-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-annual-report .grid-annual-report--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-annual-report .grid-annual-report--link:hover, .grid-annual-report .grid-annual-report--link:focus {
    background: #be1621;
  }
  .grid-annual-report .grid-annual-report--link:hover .grid-annual-report--item-text, .grid-annual-report .grid-annual-report--link:focus .grid-annual-report--item-text {
    border: 1px solid transparent;
    border-top: none;
  }
  .grid-annual-report .grid-annual-report--link:hover .grid-annual-report--item-title, .grid-annual-report .grid-annual-report--link:focus .grid-annual-report--item-title {
    color: #ffffff;
  }
  .grid-annual-report .grid-annual-report--link:hover .grid-annual-report--item-arrow, .grid-annual-report .grid-annual-report--link:focus .grid-annual-report--item-arrow {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-annual-report .grid-annual-report--link:active {
    background: #be1621;
  }
  .grid-annual-report .grid-annual-report--link:active .grid-annual-report--item-text {
    border: 1px solid transparent;
    border-top: none;
  }
  .grid-annual-report .grid-annual-report--link:active .grid-annual-report--item-title {
    color: #ffffff;
  }
  .grid-annual-report .grid-annual-report--link:active .grid-annual-report--item-arrow {
    color: #ffffff;
  }
}

@media (min-width: 1556px), (min-width: 1280px) and (max-width: 1555px), (min-width: 1024px) and (max-width: 1279px) {
  .two-cols .grid-articles .grid-articles--tile {
    flex: 0 0 50%;
  }
  html.ie11 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023px), (max-width: 767px) {
  .two-cols .grid-articles .grid-articles--tile {
    flex: 0 0 100%;
  }
  html.ie11 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .two-cols .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }
}

.grid-articles {
  width: 100%;
}
.grid-articles .grid-articles--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-articles .grid-articles--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-articles .grid-articles--list {
  min-height: 1px;
}

.grid-articles .grid-articles--tile {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .grid-articles .grid-articles--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-articles .grid-articles--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-articles .grid-articles--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .grid-articles .grid-articles--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-articles .grid-articles--container .grid-articles--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-articles .grid-articles--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .grid-articles .grid-articles--tile {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.grid-articles .grid-articles--list {
  grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
  grid-auto-rows: 1fr;
}
.grid-articles .grid-articles--tile {
  overflow: hidden;
}
@media (min-width: 1556px), (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile {
    flex: 0 0 33.3333333333%;
  }
  html.ie11 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 33.3333333333%;
  }

  html.ie10 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile {
    flex: 0 0 50%;
  }
  html.ie11 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .grid-articles .grid-articles--tile {
    flex: 0 0 100%;
  }
  html.ie11 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-articles .grid-articles--tile {
    flex-basis: auto;
    width: 100%;
  }
}
.grid-articles .grid-articles--tile-title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-articles .grid-articles--tile-title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-title {
    font-size: 2rem;
  }
}
.grid-articles .grid-articles--dark-text {
  color: #000000;
  transition: color 200ms ease;
}
.grid-articles .grid-articles--tile-link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  background: #f7f7f7;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
.ie11 .grid-articles .grid-articles--tile-link {
  word-break: break-all;
}

html[data-juventus-theme=brand] .grid-articles .grid-articles--tile-link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-articles .grid-articles--tile-link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-articles .grid-articles--tile-link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-articles .grid-articles--tile-link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-articles .grid-articles--tile-link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-articles .grid-articles--tile-link {
  color: #771469;
}

.grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #ededed;
}
@media (max-width: 575px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
    padding: 40px;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-container {
  height: calc(100% - 250px);
  border-style: none solid solid;
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container {
    height: calc(100% - 214px);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-container {
    height: calc(100% - 192px);
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-container-without-image {
  height: 100%;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-figure {
  height: 250px;
  width: 100%;
  overflow: hidden;
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-figure {
    height: 214px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-figure {
    height: 192px;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-title,
.grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
  transition: color 200ms ease;
  flex: 0 0 100%;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-title,
.grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #7a7a7a;
  font-weight: 500;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-subtitle {
    font-size: 2rem;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin {
  display: flex;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container .grid-articles--tile-article-info,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin .grid-articles--tile-article-info {
  position: relative;
  color: #1f1f1f;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container .grid-articles--tile-article-info:first-child::after,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin .grid-articles--tile-article-info:first-child::after {
  content: "•";
  position: relative;
  margin-left: 5px;
  color: #be1621;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container .grid-articles--tile-article-info:last-child,
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin .grid-articles--tile-article-info:last-child {
  margin-left: 8px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-article-info-container-with-margin {
  margin-top: 30px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background color 200ms ease;
  color: #ffffff;
  background: #be1621;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-tag {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-tag:hover {
  background: #ffffff;
  color: #be1621;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-footer {
  display: flex;
  flex: 0 0 100%;
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-footer {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-articles .grid-articles--tile-link .grid-articles--tile-footer {
    padding-top: 40px;
  }
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 70px;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: 0;
  color: #be1621;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::after, .grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-articles .grid-articles--tile-link .grid-articles--tile-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-articles .grid-articles--button-load {
  margin-top: 50px;
  padding: 16px 24px;
  border: 1px solid #be1621;
  color: #be1621;
  font-weight: 500;
  transition: color 200ms ease;
  justify-self: center;
  cursor: pointer;
}
.grid-articles .grid-articles--tile-link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-articles .grid-articles--tile-link:hover, .grid-articles .grid-articles--tile-link:focus {
    color: #ffffff;
    background: #be1621;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link:hover .grid-articles--tile-container-without-image, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link:focus .grid-articles--tile-container-without-image {
    border: 1px solid transparent;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-container, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-container {
    border-style: none solid solid;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-tag, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-subtitle, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-subtitle {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--dark-text, .grid-articles .grid-articles--tile-link:focus .grid-articles--dark-text {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-article-info, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-article-info {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-article-info:first-child::after, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-article-info:first-child::after {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:hover .grid-articles--tile-arrow, .grid-articles .grid-articles--tile-link:focus .grid-articles--tile-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-articles .grid-articles--tile-link:active {
    color: #ffffff;
    background: #be1621;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-container,
.grid-articles .grid-articles--tile-link:active .grid-articles--tile-container-without-image {
    border: 1px solid transparent;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-container {
    border-style: none solid solid;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-subtitle {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--dark-text {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-article-info {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-article-info:first-child::after {
    color: #ffffff;
  }
  .grid-articles .grid-articles--tile-link:active .grid-articles--tile-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}
.grid-articles .grid-articles--button-load {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-articles .grid-articles--button-load:hover, .grid-articles .grid-articles--button-load:focus {
    background: #be1621;
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-articles .grid-articles--button-load:active {
    background: #be1621;
    color: #ffffff;
  }
}

.grid-employees-entry .grid-employees-entry--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-employees-entry .grid-employees-entry--list-row {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-employees-entry .grid-employees-entry--list-row {
  min-height: 1px;
}

.grid-employees-entry .grid-employees-entry--list-item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (min-width: 576px) {
  .grid-employees-entry .grid-employees-entry--container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-employees-entry .grid-employees-entry--list-row {
    margin-left: -16px;
    margin-right: -16px;
  }
  .grid-employees-entry .grid-employees-entry--list-item {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--container {
    padding-top: 16px;
    padding-left: 0;
    padding-bottom: 16px;
    padding-right: 0;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-employees-entry .grid-employees-entry--container .grid-employees-entry--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-employees-entry .grid-employees-entry--list-row {
    margin-top: -8px;
    margin-left: 0;
    margin-bottom: -8px;
    margin-right: 0;
  }
  .grid-employees-entry .grid-employees-entry--list-item {
    padding-top: 8px;
    padding-left: 0;
    padding-bottom: 8px;
    padding-right: 0;
  }
}
.grid-employees-entry .grid-employees-entry--list-row {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  padding-bottom: 32px;
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--list-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--list-row {
    padding-bottom: 16px;
  }
}
.grid-employees-entry .grid-employees-entry--list-col {
  display: flex;
  flex-direction: column;
}
@media (max-width: 575px) {
  .grid-employees-entry .grid-employees-entry--list-item {
    padding: 6px 0;
  }
  .grid-employees-entry .grid-employees-entry--list-item:first-of-type {
    padding-top: 0;
  }
}
.grid-employees-entry .grid-employees-entry--image {
  border-radius: 100%;
  width: 100%;
}
@supports (object-fit: cover) {
  .grid-employees-entry .grid-employees-entry--image {
    object-fit: cover;
  }
  @media (min-width: 576px) {
    .grid-employees-entry .grid-employees-entry--image {
      width: 172px;
      height: 172px;
    }
  }
  @media (max-width: 575px) {
    .grid-employees-entry .grid-employees-entry--image {
      width: 124px;
      height: 124px;
    }
  }
}
.grid-employees-entry .grid-employees-entry--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--title {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-employees-entry .grid-employees-entry--lead {
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #7a7a7a;
  padding-bottom: 8px;
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--lead {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--lead {
    padding-bottom: 4px;
  }
}
.grid-employees-entry .grid-employees-entry--details {
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #be1621;
  margin-bottom: 2px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--details {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1280px) {
  .grid-employees-entry .grid-employees-entry--details {
    margin-bottom: 4px;
  }
}
.grid-employees-entry .grid-employees-entry--details:last-child {
  margin-bottom: 0;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-employees-entry .grid-employees-entry--details:hover, .grid-employees-entry .grid-employees-entry--details:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .grid-employees-entry .grid-employees-entry--details:active {
    color: #98121a;
  }
}
.grid-employees-entry .grid-employees-entry--details-wrapper {
  display: flex;
  flex-direction: column;
}
.grid-employees-entry .grid-employees-entry--link {
  color: #be1621;
  display: flex;
  align-items: center;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-employees-entry .grid-employees-entry--link:hover, .grid-employees-entry .grid-employees-entry--link:focus {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .grid-employees-entry .grid-employees-entry--link:active {
    color: #98121a;
  }
}
@media (max-width: 1279px) {
  .grid-employees-entry .grid-employees-entry--link {
    margin-top: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-employees-entry .grid-employees-entry--link {
    margin-top: 16px;
  }
}
.grid-employees-entry .grid-employees-entry--protrait-label {
  margin-right: 10px;
}
.grid-employees-entry .grid-employees-entry--link-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: -8px;
}
.grid-employees-entry .grid-employees-entry--link-arrow::after, .grid-employees-entry .grid-employees-entry--link-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-employees-entry .grid-employees-entry--link-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-employees-entry .grid-employees-entry--link-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-employees-entry .grid-employees-entry--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-employees-entry .grid-employees-entry--link:hover .grid-employees-entry--link-arrow, .grid-employees-entry .grid-employees-entry--link:focus .grid-employees-entry--link-arrow {
    padding-left: 12px;
  }
}
@media (any-hover: none) {
  .grid-employees-entry .grid-employees-entry--link:active .grid-employees-entry--link-arrow {
    padding-left: 12px;
  }
}

.grid-employees .grid-employees--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-employees .grid-employees--title {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
@media (max-width: 1279px) {
  .grid-employees .grid-employees--title {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-employees .grid-employees--title {
    margin-bottom: 40px;
  }
}

.grid-jobs .grid-jobs--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-jobs .grid-jobs--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-jobs .grid-jobs--list {
  min-height: 1px;
}

.grid-jobs .grid-jobs--entry {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .grid-jobs .grid-jobs--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-jobs .grid-jobs--container .grid-jobs--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-jobs .grid-jobs--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .grid-jobs .grid-jobs--entry {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.grid-jobs .grid-jobs--entry {
  overflow: hidden;
}
@media (min-width: 1024px) {
  .grid-jobs .grid-jobs--entry {
    flex: 0 0 50%;
  }
  html.ie11 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .grid-jobs .grid-jobs--entry {
    flex: 0 0 100%;
  }
  html.ie11 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-jobs .grid-jobs--entry {
    flex-basis: auto;
    width: 100%;
  }
}
.grid-jobs .grid-jobs--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-jobs .grid-jobs--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--title {
    font-size: 2rem;
  }
}
.grid-jobs .grid-jobs--dark-text {
  color: #000000;
  transition: color 200ms ease;
}
.grid-jobs .grid-jobs--entry-link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 24px;
  min-height: 228px;
  background: #f7f7f7;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
  border: 1px solid #ededed;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 280px;
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 280px;
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-jobs .grid-jobs--entry-link {
    min-height: 300px;
    padding: 40px;
  }
}
.ie11 .grid-jobs .grid-jobs--entry-link {
  word-break: break-all;
}

html[data-juventus-theme=brand] .grid-jobs .grid-jobs--entry-link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-jobs .grid-jobs--entry-link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-jobs .grid-jobs--entry-link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-jobs .grid-jobs--entry-link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-jobs .grid-jobs--entry-link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-jobs .grid-jobs--entry-link {
  color: #771469;
}

.grid-jobs .grid-jobs--entry-title,
.grid-jobs .grid-jobs--entry-subtitle {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
  transition: color 200ms ease;
  flex: 0 0 100%;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-title,
.grid-jobs .grid-jobs--entry-subtitle {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-jobs .grid-jobs--entry-subtitle {
  color: #be1621;
}
.grid-jobs .grid-jobs--entry-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background color 200ms ease;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  background: #be1621;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-tag {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
html[data-juventus-theme=brand] .grid-jobs .grid-jobs--entry-tag {
  background: #be1621;
}

html[data-juventus-theme=highschool] .grid-jobs .grid-jobs--entry-tag {
  background: #dd7200;
}

html[data-juventus-theme=economy] .grid-jobs .grid-jobs--entry-tag {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .grid-jobs .grid-jobs--entry-tag {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .grid-jobs .grid-jobs--entry-tag {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-jobs .grid-jobs--entry-tag {
  background: #771469;
}

.grid-jobs .grid-jobs--entry-footer {
  display: flex;
  flex: 0 0 100%;
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .grid-jobs .grid-jobs--entry-footer {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-jobs .grid-jobs--entry-footer {
    padding-top: 40px;
  }
}
.grid-jobs .grid-jobs--entry-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 70px;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-jobs .grid-jobs--entry-arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: 0;
  color: #be1621;
}
.grid-jobs .grid-jobs--entry-arrow::after, .grid-jobs .grid-jobs--entry-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-jobs .grid-jobs--entry-arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-jobs .grid-jobs--entry-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-jobs .grid-jobs--entry-link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-jobs .grid-jobs--entry-link:hover, .grid-jobs .grid-jobs--entry-link:focus {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--entry-tag, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--entry-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--entry-subtitle, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--entry-subtitle {
    color: #ededed;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--dark-text, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--dark-text {
    color: #ffffff;
  }
  .grid-jobs .grid-jobs--entry-link:hover .grid-jobs--entry-arrow, .grid-jobs .grid-jobs--entry-link:focus .grid-jobs--entry-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .grid-jobs .grid-jobs--entry-link:active {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--entry-tag {
    background: #ffffff;
    color: #be1621;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--entry-subtitle {
    color: #ededed;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--dark-text {
    color: #ffffff;
  }
  .grid-jobs .grid-jobs--entry-link:active .grid-jobs--entry-arrow {
    padding-left: 15px;
    color: #ffffff;
  }
}

.grid-offer .grid-offer--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-offer .grid-offer--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-offer .grid-offer--list {
  min-height: 1px;
}

.grid-offer .grid-offer--item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .grid-offer .grid-offer--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-offer .grid-offer--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-offer .grid-offer--container {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-offer .grid-offer--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-offer .grid-offer--container {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1556px) {
  .grid-offer .grid-offer--container {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important;
  }
  .grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
    visibility: visible;
    content: "Nested `grid--container` detected!";
    font-size: 20px;
    line-height: 1.2;
    color: red;
  }
  .grid-offer .grid-offer--list {
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .grid-offer .grid-offer--item {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.grid-offer .grid-offer--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.grid-offer .grid-offer--tags-list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .grid-offer .grid-offer--tags-list {
  min-height: 1px;
}

.grid-offer .grid-offer--tags-item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.grid-offer .grid-offer--container {
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
}
.grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.grid-offer .grid-offer--container .grid-offer--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.grid-offer .grid-offer--tags-list {
  margin-top: -4px;
  margin-left: -4px;
  margin-bottom: -4px;
  margin-right: -4px;
}
.grid-offer .grid-offer--tags-item {
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
}
.grid-offer .grid-offer--item {
  overflow: hidden;
}
.grid-offer .grid-offer--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--title {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .grid-offer .grid-offer--title {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--title {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--title-wrap {
    padding-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .grid-offer .grid-offer--title-wrap {
    padding-bottom: 16px;
  }
}
.grid-offer .grid-offer--link {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 24px;
  min-height: 228px;
  background: #ffffff;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-offer .grid-offer--link {
    min-height: 362px;
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-offer .grid-offer--link {
    min-height: 286px;
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-offer .grid-offer--link {
    min-height: 340px;
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-offer .grid-offer--link {
    min-height: 362px;
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-offer .grid-offer--link {
    min-height: 392px;
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-offer .grid-offer--link {
    min-height: 460px;
    padding: 40px;
  }
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link {
  color: #771469;
}

.grid-offer .grid-offer--link-module {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 24px;
  min-height: 228px;
  background: #f7f7f7;
  border: 1px solid #ededed;
  transition: background 200ms ease, color 200ms ease, border 200ms ease;
  color: #be1621;
}
@media (max-width: 575px) {
  .grid-offer .grid-offer--link-module {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .grid-offer .grid-offer--link-module {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid-offer .grid-offer--link-module {
    min-height: 280px;
    padding: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-offer .grid-offer--link-module {
    min-height: 240px;
    padding: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .grid-offer .grid-offer--link-module {
    min-height: 280px;
    padding: 32px;
  }
}
@media (min-width: 1556px) {
  .grid-offer .grid-offer--link-module {
    min-height: 300px;
    padding: 40px;
  }
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module {
  color: #771469;
}

.grid-offer .grid-offer--item-title,
.grid-offer .grid-offer--item-subtitle {
  transition: color 200ms ease;
}
.grid-offer .grid-offer--item-title {
  flex: 0 0 100%;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--item-title {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
.grid-offer .grid-offer--item-subtitle {
  flex: 0 0 100%;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.28;
  color: #000000;
  color: #be1621;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--item-subtitle {
    font-size: 2.4rem;
    line-height: 1.33;
  }
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--item-subtitle {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--item-subtitle {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--item-subtitle {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--item-subtitle {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--item-subtitle {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--item-subtitle {
  color: #771469;
}

@media (min-width: 1024px) {
  .grid-offer .grid-offer--item {
    flex: 0 0 50%;
  }
  html.ie11 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .grid-offer .grid-offer--item {
    flex: 0 0 100%;
  }
  html.ie11 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .grid-offer .grid-offer--item {
    flex-basis: auto;
    width: 100%;
  }
}
.grid-offer .grid-offer--arrow-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 70px;
  flex-shrink: 0;
  height: 19px;
  margin-bottom: 4px;
}
.grid-offer .grid-offer--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: 0;
}
.grid-offer .grid-offer--arrow::after, .grid-offer .grid-offer--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.grid-offer .grid-offer--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.grid-offer .grid-offer--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.grid-offer .grid-offer--footer {
  display: flex;
  flex: 0 0 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--footer {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .grid-offer .grid-offer--footer {
    padding-top: 40px;
  }
}
.grid-offer .grid-offer--tags {
  flex: 1 1 100%;
}
.grid-offer .grid-offer--dark-text {
  color: #000000;
  transition: color 200ms ease;
}
.grid-offer .grid-offer--tags-tag {
  padding: 4px 12px;
  border-radius: 16px;
  transition: background 200ms ease;
  background: #be1621;
}
html[data-juventus-theme=brand] .grid-offer .grid-offer--tags-tag {
  background: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer .grid-offer--tags-tag {
  background: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer .grid-offer--tags-tag {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer .grid-offer--tags-tag {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer .grid-offer--tags-tag {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--tags-tag {
  background: #771469;
}

.grid-offer .grid-offer--tags-tag-text {
  color: #ffffff;
  transition: color 200ms ease;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .grid-offer .grid-offer--tags-tag-text {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.grid-offer.grid-offer__module .grid-offer--link {
  background: #f7f7f7;
  border: 1px solid #ededed;
  color: #be1621;
}
html[data-juventus-theme=brand] .grid-offer.grid-offer__module .grid-offer--link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .grid-offer.grid-offer__module .grid-offer--link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .grid-offer.grid-offer__module .grid-offer--link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .grid-offer.grid-offer__module .grid-offer--link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .grid-offer.grid-offer__module .grid-offer--link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .grid-offer.grid-offer__module .grid-offer--link {
  color: #771469;
}

.grid-offer .grid-offer--dark-text.grid-offer--dark-text__gray {
  color: #5c5c5c;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand {
  color: #be1621;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool {
  color: #dd7200;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy {
  color: #286ab7;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine {
  color: #1f9285;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering {
  color: #948b85;
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership {
  color: #771469;
}
.grid-offer .grid-offer--link,
.grid-offer .grid-offer--link-module {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link:hover, .grid-offer .grid-offer--link:focus,
.grid-offer .grid-offer--link-module:hover,
.grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=brand] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=brand] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=brand] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #be1621;
  }

  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #dd7200;
    border-color: #dd7200;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #dd7200;
  }

  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=economy] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #286ab7;
    border-color: #286ab7;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=economy] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=economy] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #1f9285;
    border-color: #1f9285;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #1f9285;
  }

  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #948b85;
    border-color: #948b85;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #948b85;
  }

  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:hover, html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:focus,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:hover,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:focus {
    color: #ffffff;
    background: #771469;
    border-color: #771469;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag, html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:hover .grid-offer--tags-tag-text, html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:focus .grid-offer--tags-tag-text,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:hover .grid-offer--tags-tag-text,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:focus .grid-offer--tags-tag-text {
    color: #771469;
  }

  .grid-offer .grid-offer--link:hover .grid-offer--item-subtitle, .grid-offer .grid-offer--link:focus .grid-offer--item-subtitle,
.grid-offer .grid-offer--link-module:hover .grid-offer--item-subtitle,
.grid-offer .grid-offer--link-module:focus .grid-offer--item-subtitle {
    color: #ededed;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--dark-text, .grid-offer .grid-offer--link:focus .grid-offer--dark-text,
.grid-offer .grid-offer--link-module:hover .grid-offer--dark-text,
.grid-offer .grid-offer--link-module:focus .grid-offer--dark-text {
    color: #ffffff;
  }
  .grid-offer .grid-offer--link:hover .grid-offer--arrow, .grid-offer .grid-offer--link:focus .grid-offer--arrow,
.grid-offer .grid-offer--link-module:hover .grid-offer--arrow,
.grid-offer .grid-offer--link-module:focus .grid-offer--arrow {
    padding-left: 15px;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link:active,
.grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #be1621;
    border-color: #be1621;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=brand] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=brand] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #be1621;
  }

  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #dd7200;
    border-color: #dd7200;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=highschool] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=highschool] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #dd7200;
  }

  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #286ab7;
    border-color: #286ab7;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=economy] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=economy] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #1f9285;
    border-color: #1f9285;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medicine] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=medicine] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #1f9285;
  }

  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #948b85;
    border-color: #948b85;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=engineering] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=engineering] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #948b85;
  }

  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:active,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:active {
    color: #ffffff;
    background: #771469;
    border-color: #771469;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:active .grid-offer--tags-tag,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag {
    background: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link:active .grid-offer--tags-tag-text,
html[data-juventus-theme=medical-leadership] .grid-offer .grid-offer--link-module:active .grid-offer--tags-tag-text {
    color: #771469;
  }

  .grid-offer .grid-offer--link:active .grid-offer--item-subtitle,
.grid-offer .grid-offer--link-module:active .grid-offer--item-subtitle {
    color: #ededed;
  }
  .grid-offer .grid-offer--link:active .grid-offer--dark-text,
.grid-offer .grid-offer--link-module:active .grid-offer--dark-text {
    color: #ffffff;
  }
  .grid-offer .grid-offer--link:active .grid-offer--arrow,
.grid-offer .grid-offer--link-module:active .grid-offer--arrow {
    padding-left: 15px;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand .grid-offer--tags-tag {
  background: #be1621;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:focus {
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:focus .grid-offer--tags-tag-text {
    color: #be1621;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:active {
    background: #be1621;
    border-color: #be1621;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__brand:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__brand:active .grid-offer--tags-tag-text {
    color: #be1621;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool .grid-offer--tags-tag {
  background: #dd7200;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:focus {
    background: #dd7200;
    border-color: #dd7200;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:focus .grid-offer--tags-tag-text {
    color: #dd7200;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:active {
    background: #dd7200;
    border-color: #dd7200;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__highschool:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__highschool:active .grid-offer--tags-tag-text {
    color: #dd7200;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy .grid-offer--tags-tag {
  background: #286ab7;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:focus {
    background: #286ab7;
    border-color: #286ab7;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:focus .grid-offer--tags-tag-text {
    color: #286ab7;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:active {
    background: #286ab7;
    border-color: #286ab7;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__economy:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__economy:active .grid-offer--tags-tag-text {
    color: #286ab7;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine .grid-offer--tags-tag {
  background: #1f9285;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:focus {
    background: #1f9285;
    border-color: #1f9285;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:focus .grid-offer--tags-tag-text {
    color: #1f9285;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:active {
    background: #1f9285;
    border-color: #1f9285;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medicine:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medicine:active .grid-offer--tags-tag-text {
    color: #1f9285;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering .grid-offer--tags-tag {
  background: #948b85;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:focus {
    background: #948b85;
    border-color: #948b85;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:focus .grid-offer--tags-tag-text {
    color: #948b85;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:active {
    background: #948b85;
    border-color: #948b85;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__engineering:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__engineering:active .grid-offer--tags-tag-text {
    color: #948b85;
  }
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership .grid-offer--tags-tag,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership .grid-offer--tags-tag {
  background: #771469;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:hover, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:focus,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:hover,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:focus {
    background: #771469;
    border-color: #771469;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:hover .grid-offer--tags-tag-text, .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:focus .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:hover .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:focus .grid-offer--tags-tag-text {
    color: #771469;
  }
}
@media (any-hover: none) {
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:active,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:active {
    background: #771469;
    border-color: #771469;
  }
  .grid-offer .grid-offer--link.grid-offer--link.grid-offer--link__medical-leadership:active .grid-offer--tags-tag-text,
.grid-offer .grid-offer--link-module.grid-offer--link.grid-offer--link__medical-leadership:active .grid-offer--tags-tag-text {
    color: #771469;
  }
}

.image .image--img {
  display: block;
  width: 100%;
  height: auto;
}
.image .image--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .image .image--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .image .image--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .image .image--title {
    font-size: 2rem;
  }
}
.image .image--figcaption {
  color: #5c5c5c;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .image .image--figcaption {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
@media (max-width: 1279px) {
  .image .image--figcaption {
    padding-top: 12px;
  }
}
@media (min-width: 1280px) {
  .image .image--figcaption {
    padding-top: 16px;
  }
}
.image .image--author {
  display: inline-block;
  width: 100%;
  font-size: 1.2rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}

.info-events .info-events--school-name {
  font-size: 1.7rem;
  margin-bottom: 8px;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .info-events .info-events--item:not(:first-child) {
    margin-top: 24px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--item:not(:first-child) {
    margin-top: 32px;
  }
}
.info-events .info-events--link {
  display: block;
  padding: 24px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (min-width: 1280px) {
  .info-events .info-events--link {
    padding: 32px;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link:hover, .info-events .info-events--link:focus {
    color: #ffffff;
  }
  .info-events .info-events--link:hover .info-events--heading, .info-events .info-events--link:focus .info-events--heading {
    color: #ffffff;
  }
  .info-events .info-events--link:hover .info-events--bullet-list, .info-events .info-events--link:focus .info-events--bullet-list {
    color: #f7f7f7;
  }
  .info-events .info-events--link:hover .info-events--arrow, .info-events .info-events--link:focus .info-events--arrow {
    padding-left: 24px;
  }
  .info-events .info-events--link:hover .info-events--wrapper-item, .info-events .info-events--link:focus .info-events--wrapper-item {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link:active {
    color: #ffffff;
  }
  .info-events .info-events--link:active .info-events--heading {
    color: #ffffff;
  }
  .info-events .info-events--link:active .info-events--bullet-list {
    color: #f7f7f7;
  }
  .info-events .info-events--link:active .info-events--arrow {
    padding-left: 24px;
  }
  .info-events .info-events--link:active .info-events--wrapper-item {
    color: #ffffff;
  }
}
.info-events .info-events--wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  color: #000000;
}
.info-events .info-events--wrapper .info-events--wrapper-item {
  display: flex;
  align-items: center;
}
@media (max-width: 1279px) {
  .info-events .info-events--wrapper .info-events--wrapper-item {
    margin-bottom: 8px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--wrapper .info-events--wrapper-item {
    margin-bottom: 8px;
  }
}
@media (min-width: 1024px) {
  .info-events .info-events--wrapper .info-events--wrapper-item {
    padding: 0 8px;
  }
  .info-events .info-events--wrapper .info-events--wrapper-item:first-of-type {
    padding-left: 0;
  }
  .info-events .info-events--wrapper .info-events--wrapper-item:last-of-type {
    padding-right: 0;
  }
}
.info-events .info-events--wrapper .info-events--date {
  min-width: 208px;
}
.info-events .info-events--wrapper .info-events--time {
  min-width: 88px;
}
@media (max-width: 1279px) {
  .info-events .info-events--wrapper .info-events--time-icon, .info-events .info-events--wrapper .info-events--date-icon {
    margin-right: 8px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--wrapper .info-events--time-icon, .info-events .info-events--wrapper .info-events--date-icon {
    margin-right: 8px;
  }
}
@media (min-width: 1024px) {
  .info-events .info-events--wrapper {
    align-items: center;
    flex-flow: row;
  }
  .info-events .info-events--wrapper .info-events--date-wrapper {
    margin-bottom: 0;
  }
}
.info-events .info-events--bullet-list {
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .info-events .info-events--bullet-list {
    margin-top: 24px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--bullet-list {
    margin-top: 32px;
  }
}
.info-events .info-events--bullet-list .info-events--bullet-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  padding-left: 2rem;
}
.info-events .info-events--bullet-list .info-events--bullet-item::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 6px;
  display: inline-block;
  margin-right: 12px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.info-events .info-events--footer {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1279px) {
  .info-events .info-events--footer {
    margin-top: 32px;
  }
}
@media (min-width: 1280px) {
  .info-events .info-events--footer {
    margin-top: 40px;
  }
}
.info-events .info-events--arrow-wrapper {
  display: flex;
  align-items: center;
  width: 93px;
}
.info-events .info-events--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.info-events .info-events--arrow::after, .info-events .info-events--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.info-events .info-events--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.info-events .info-events--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.info-events {
  color: #be1621;
}
.info-events .info-events--school-name {
  color: #be1621;
}
.info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link.info-events--link:hover, .info-events .info-events--link.info-events--link:focus {
    background: #be1621;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link.info-events--link:active {
    background: #be1621;
  }
}
.info-events .info-events--link .info-events--bullet-item::before {
  background-color: #be1621;
}
.info-events .info-events--link .info-events--footer {
  color: #be1621;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link:hover .info-events--footer, .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  .info-events .info-events--link:hover .info-events--date-time-wrapper, .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-events .info-events--link:hover .info-events--bullet-item::before, .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
html[data-juventus-theme=brand] .info-events {
  color: #be1621;
}
html[data-juventus-theme=brand] .info-events .info-events--school-name {
  color: #be1621;
}
html[data-juventus-theme=brand] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link:focus {
    background: #be1621;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link.info-events--link:active {
    background: #be1621;
  }
}
html[data-juventus-theme=brand] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #be1621;
}
html[data-juventus-theme=brand] .info-events .info-events--link .info-events--footer {
  color: #be1621;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=brand] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=brand] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=brand] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=brand] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=brand] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=highschool] .info-events {
  color: #dd7200;
}
html[data-juventus-theme=highschool] .info-events .info-events--school-name {
  color: #dd7200;
}
html[data-juventus-theme=highschool] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link:focus {
    background: #dd7200;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link.info-events--link:active {
    background: #dd7200;
  }
}
html[data-juventus-theme=highschool] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #dd7200;
}
html[data-juventus-theme=highschool] .info-events .info-events--link .info-events--footer {
  color: #dd7200;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=highschool] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=highschool] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=highschool] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=highschool] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=highschool] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=economy] .info-events {
  color: #286ab7;
}
html[data-juventus-theme=economy] .info-events .info-events--school-name {
  color: #286ab7;
}
html[data-juventus-theme=economy] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link:focus {
    background: #286ab7;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link.info-events--link:active {
    background: #286ab7;
  }
}
html[data-juventus-theme=economy] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #286ab7;
}
html[data-juventus-theme=economy] .info-events .info-events--link .info-events--footer {
  color: #286ab7;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=economy] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=economy] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=economy] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=economy] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=economy] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=medicine] .info-events {
  color: #1f9285;
}
html[data-juventus-theme=medicine] .info-events .info-events--school-name {
  color: #1f9285;
}
html[data-juventus-theme=medicine] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link:focus {
    background: #1f9285;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link.info-events--link:active {
    background: #1f9285;
  }
}
html[data-juventus-theme=medicine] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #1f9285;
}
html[data-juventus-theme=medicine] .info-events .info-events--link .info-events--footer {
  color: #1f9285;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=medicine] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medicine] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=medicine] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medicine] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=medicine] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=engineering] .info-events {
  color: #948b85;
}
html[data-juventus-theme=engineering] .info-events .info-events--school-name {
  color: #948b85;
}
html[data-juventus-theme=engineering] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link:focus {
    background: #948b85;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link.info-events--link:active {
    background: #948b85;
  }
}
html[data-juventus-theme=engineering] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #948b85;
}
html[data-juventus-theme=engineering] .info-events .info-events--link .info-events--footer {
  color: #948b85;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=engineering] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=engineering] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=engineering] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=engineering] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=engineering] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

html[data-juventus-theme=medical-leadership] .info-events {
  color: #771469;
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--school-name {
  color: #771469;
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link {
  background: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link:hover, html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link:focus {
    background: #771469;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link.info-events--link:active {
    background: #771469;
  }
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link .info-events--bullet-item::before {
  background-color: #771469;
}
html[data-juventus-theme=medical-leadership] .info-events .info-events--link .info-events--footer {
  color: #771469;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:hover .info-events--footer, html[data-juventus-theme=medical-leadership] .info-events .info-events--link:focus .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:hover .info-events--date-time-wrapper, html[data-juventus-theme=medical-leadership] .info-events .info-events--link:focus .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:active .info-events--footer {
    color: #ffffff;
  }
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:active .info-events--date-time-wrapper {
    color: #ffffff;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:hover .info-events--bullet-item::before, html[data-juventus-theme=medical-leadership] .info-events .info-events--link:focus .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .info-events .info-events--link:active .info-events--bullet-item::before {
    background-color: #ffffff;
  }
}

.intro-teaser {
  background: #be1621;
}
html[data-juventus-theme=brand] .intro-teaser {
  background: #be1621;
}

html[data-juventus-theme=highschool] .intro-teaser {
  background: #dd7200;
}

html[data-juventus-theme=economy] .intro-teaser {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .intro-teaser {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .intro-teaser {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .intro-teaser {
  background: #771469;
}

.intro-teaser .intro-teaser--container {
  z-index: 0;
  position: relative;
  justify-content: center;
}
@media (max-width: 1023px) {
  .intro-teaser .intro-teaser--container {
    justify-content: flex-end;
  }
}
.intro-teaser .intro-teaser--col {
  flex: 0 0 50%;
}
html.ie11 .intro-teaser .intro-teaser--col {
  flex-basis: auto;
  width: 50%;
}

html.ie10 .intro-teaser .intro-teaser--col {
  flex-basis: auto;
  width: 50%;
}

.intro-teaser .intro-teaser--parallax-wrapper {
  position: relative;
  overflow: hidden;
}
.intro-teaser .intro-teaser--row {
  display: flex;
  align-items: center;
}
.intro-teaser .intro-teaser--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 4rem;
  line-height: 1.1;
  color: #000000;
  color: #ffffff;
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--title {
    margin-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .intro-teaser .intro-teaser--title {
    margin-bottom: 24px;
  }
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--title {
    font-size: 3.2rem;
    line-height: 1.125;
  }
}
@media (max-width: 767px) {
  .intro-teaser .intro-teaser--title {
    margin-bottom: 0;
  }
}
.intro-teaser .intro-teaser--lead {
  font-size: 2.2rem;
  line-height: 1.31;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--lead {
    font-size: 2rem;
    line-height: 1.35;
  }
}
@media (max-width: 767px) {
  .intro-teaser .intro-teaser--lead {
    display: none;
  }
}
.intro-teaser .intro-teaser--breadcrumbs {
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 68px;
  font-size: 1.5rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
@media (max-width: 1279px) {
  .intro-teaser .intro-teaser--breadcrumbs {
    height: 52px;
  }
}
@media (max-width: 767px) {
  .intro-teaser .intro-teaser--breadcrumbs {
    height: 56px;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
  color: #ffffff;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 596px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 444px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 340px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 448px;
  }
}
@media (max-width: 575px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    max-width: 214px;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:hover, .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:focus {
    text-decoration: underline;
  }
}
@media (any-hover: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:active {
    text-decoration: underline;
  }
}
@media (max-width: 1023px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link {
    display: none;
  }
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link:last-child {
    display: block;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled:hover, .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled:focus {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item-link-disabled:active {
    text-decoration: none;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list-item {
  display: flex;
  align-items: center;
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-list {
  display: flex;
  align-items: center;
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-icon {
  display: none;
  padding: 0 8px;
  transform: translateY(-1px);
  color: #ffffff;
}
@media (min-width: 1024px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-icon {
    display: inline-block;
  }
}
.intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-back-icon {
  display: inline-block;
  padding: 0 8px;
  transform: translateY(-1px);
  color: #ffffff;
}
@media (min-width: 1024px) {
  .intro-teaser .intro-teaser--breadcrumbs .intro-teaser--breadcrumbs-back-icon {
    display: none;
  }
}
.intro-teaser .intro-teaser--circle {
  border-radius: 50%;
  position: absolute;
  left: 64px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.intro-teaser .intro-teaser--image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}
.intro-teaser .intro-teaser--figure {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.intro-teaser .intro-teaser--figure::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 1023px) {
  .intro-teaser .intro-teaser--figure::after {
    background: rgba(190, 22, 33, 0.5);
  }
  html[data-juventus-theme=brand] .intro-teaser .intro-teaser--figure::after {
    background: rgba(190, 22, 33, 0.5);
  }

  html[data-juventus-theme=highschool] .intro-teaser .intro-teaser--figure::after {
    background: rgba(221, 114, 0, 0.5);
  }

  html[data-juventus-theme=economy] .intro-teaser .intro-teaser--figure::after {
    background: rgba(40, 106, 183, 0.5);
  }

  html[data-juventus-theme=medicine] .intro-teaser .intro-teaser--figure::after {
    background: rgba(31, 146, 133, 0.5);
  }

  html[data-juventus-theme=engineering] .intro-teaser .intro-teaser--figure::after {
    background: rgba(148, 139, 133, 0.5);
  }

  html[data-juventus-theme=medical-leadership] .intro-teaser .intro-teaser--figure::after {
    background: rgba(119, 20, 105, 0.5);
  }
}
.intro-teaser .intro-teaser--arrow-wrapper {
  position: absolute;
  width: 24px;
  transform: rotate(90deg);
}
.intro-teaser .intro-teaser--arrow {
  color: #ffffff;
}
.intro-teaser.intro-teaser__expanded {
  margin-top: -88px;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow-wrapper {
  bottom: 124px;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 72px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow::after, .intro-teaser.intro-teaser__expanded .intro-teaser--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--container {
    padding-bottom: 196px;
  }
}
.intro-teaser.intro-teaser__expanded .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__expanded .intro-teaser--container {
  min-height: 600px;
  height: 100vh;
}
.intro-teaser.intro-teaser__expanded .intro-teaser--circle {
  width: 580px;
  height: 580px;
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--circle {
    width: 520px;
    height: 520px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--circle {
    width: 440px;
    height: 440px;
  }
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__expanded .intro-teaser--circle {
    display: none;
  }
}
.intro-teaser.intro-teaser__small {
  margin-top: -88px;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow-wrapper {
  bottom: 108px;
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__small .intro-teaser--container {
    padding-bottom: 152px;
  }
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow::after, .intro-teaser.intro-teaser__small .intro-teaser--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.intro-teaser.intro-teaser__small .intro-teaser--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
  /* the use of fixed height values is due to ie11 support */
  height: 600px;
  /* notice the use of min-height on mobile in order to avoid overflow issues */
}
@media (min-width: 1556px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    height: 40vw;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    height: 480px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    min-height: 700px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    min-height: 480px;
  }
}
@media (max-width: 575px) {
  .intro-teaser.intro-teaser__small .intro-teaser--parallax-wrapper,
.intro-teaser.intro-teaser__small .intro-teaser--container {
    min-height: 532px;
  }
}
.intro-teaser.intro-teaser__small .intro-teaser--circle {
  left: 34px;
  top: 48%;
  width: 400px;
  height: 400px;
  transform: translate(-50%, -50%);
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .intro-teaser.intro-teaser__small .intro-teaser--circle {
    top: 45%;
    width: 330px;
    height: 330px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .intro-teaser.intro-teaser__small .intro-teaser--circle {
    top: 45%;
    width: 260px;
    height: 260px;
  }
}
@media (max-width: 1023px) {
  .intro-teaser.intro-teaser__small .intro-teaser--circle {
    display: none;
  }
}

.lead .lead--text {
  font-size: 2.2rem;
  line-height: 1.31;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .lead .lead--text {
    font-size: 2rem;
    line-height: 1.35;
  }
}

.main-footer {
  font-size: 1.5rem;
  line-height: 1.4;
}
@media (max-width: 1023px) {
  .main-footer {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
@media (min-width: 1024px) {
  .main-footer {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.main-footer .main-footer--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.main-footer .main-footer--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .main-footer .main-footer--list {
  min-height: 1px;
}

.main-footer .main-footer--list-item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.main-footer .main-footer--container {
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  padding-right: 16px;
}
.main-footer .main-footer--container .main-footer--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.main-footer .main-footer--container .main-footer--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.main-footer .main-footer--list {
  margin-top: -4px;
  margin-left: -8px;
  margin-bottom: -4px;
  margin-right: -8px;
}
.main-footer .main-footer--list-item {
  padding-top: 4px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-right: 8px;
}
.main-footer .main-footer--left,
.main-footer .main-footer--right {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .main-footer .main-footer--left,
.main-footer .main-footer--right {
    flex: 0 0 50%;
  }
  html.ie11 .main-footer .main-footer--left,
html.ie11 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .main-footer .main-footer--left,
html.ie10 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .main-footer .main-footer--left,
.main-footer .main-footer--right {
    flex: 0 0 100%;
  }
  html.ie11 .main-footer .main-footer--left,
html.ie11 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .main-footer .main-footer--left,
html.ie10 .main-footer .main-footer--right {
    flex-basis: auto;
    width: 100%;
  }
}
.main-footer .main-footer--left {
  flex: 0 0 auto;
}
.main-footer .main-footer--right {
  flex-grow: 1;
}
@media (min-width: 1024px) {
  .main-footer .main-footer--right {
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .main-footer .main-footer--right {
    flex-direction: column;
    align-items: flex-end;
  }
}
@media (max-width: 767px) {
  .main-footer .main-footer--right {
    flex-direction: column;
    align-items: flex-start;
  }
}
.main-footer .main-footer--link {
  color: #5c5c5c;
  transition: color 200ms ease;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-footer .main-footer--link:hover, .main-footer .main-footer--link:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .main-footer .main-footer--link:active {
    color: #000000;
  }
}
.main-footer .main-footer--copyright {
  color: #bfbfbf;
}
@media (min-width: 1024px) {
  .main-footer .main-footer--copyright {
    margin-left: 40px;
  }
}
@media (max-width: 1023px) {
  .main-footer .main-footer--copyright {
    margin-top: 12px;
  }
}
.main-footer .main-footer--link.main-footer--link__active {
  color: #000000;
}
.main-footer .main-footer--link.main-footer--link__social-icon {
  color: #7a7a7a;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-footer .main-footer--link.main-footer--link__social-icon:hover, .main-footer .main-footer--link.main-footer--link__social-icon:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .main-footer .main-footer--link.main-footer--link__social-icon:active {
    color: #000000;
  }
}
@media (max-width: 767px) {
  .main-footer .main-footer--list.main-footer--list__social {
    margin-bottom: 12px;
  }
}

.main-header {
  z-index: 13;
  position: relative;
}
.main-header .main-header--row {
  align-items: center;
  justify-content: space-between;
  height: 88px;
}
@media (min-width: 1024px) {
  .main-header .main-header--logo {
    flex: 0 0 33.3333333333%;
  }
  html.ie11 .main-header .main-header--logo {
    flex-basis: auto;
    width: 33.3333333333%;
  }

  html.ie10 .main-header .main-header--logo {
    flex-basis: auto;
    width: 33.3333333333%;
  }
}
@media (max-width: 1023px) {
  .main-header .main-header--logo {
    flex: 0 0 66.6666666667%;
  }
  html.ie11 .main-header .main-header--logo {
    flex-basis: auto;
    width: 66.6666666667%;
  }

  html.ie10 .main-header .main-header--logo {
    flex-basis: auto;
    width: 66.6666666667%;
  }
}
.main-header .main-header--menu {
  flex: 0 0 auto;
  margin-right: 0;
  margin-left: auto;
}
@media (max-width: 1023px) {
  .main-header .main-header--menu {
    display: none;
  }
}
.main-header .main-header--hamburger {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 33.3333333333%;
}
html.ie11 .main-header .main-header--hamburger {
  flex-basis: auto;
  width: 33.3333333333%;
}

html.ie10 .main-header .main-header--hamburger {
  flex-basis: auto;
  width: 33.3333333333%;
}

@media (min-width: 1024px) {
  .main-header .main-header--hamburger {
    display: none;
  }
}
.main-header .main-header--contact.main-header--contact.main-header--contact {
  display: flex;
  justify-content: flex-end;
  padding-left: 28px;
}
@media (max-width: 1023px) {
  .main-header .main-header--contact.main-header--contact.main-header--contact {
    display: none;
  }
}
.main-header .main-header--contact-link {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
  line-height: 1.26;
  color: #000000;
}
@media (max-width: 1279px) {
  .main-header .main-header--contact-link {
    font-size: 1.8rem;
  }
}
.main-header.main-header__dark .main-header--wrap {
  background: #ffffff;
}
.main-header.main-header__dark .main-header--wrap::after {
  background: #ffffff;
}
.main-header.main-header__dark .main-header--contact-link {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-header.main-header__dark .main-header--contact-link:hover, .main-header.main-header__dark .main-header--contact-link:focus {
    color: #901119;
  }
}
@media (any-hover: none) {
  .main-header.main-header__dark .main-header--contact-link:active {
    color: #901119;
  }
}
html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link {
  color: #be1621;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #901119;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=brand] .main-header.main-header__dark .main-header--contact-link:active {
    color: #901119;
  }
}

html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link {
  color: #dd7200;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #aa5800;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=highschool] .main-header.main-header__dark .main-header--contact-link:active {
    color: #aa5800;
  }
}

html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link {
  color: #286ab7;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #1f528d;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=economy] .main-header.main-header__dark .main-header--contact-link:active {
    color: #1f528d;
  }
}

html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link {
  color: #1f9285;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #16685f;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medicine] .main-header.main-header__dark .main-header--contact-link:active {
    color: #16685f;
  }
}

html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link {
  color: #948b85;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #7b716b;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=engineering] .main-header.main-header__dark .main-header--contact-link:active {
    color: #7b716b;
  }
}

html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link {
  color: #771469;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link:hover, html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link:focus {
    color: #4b0d42;
  }
}
@media (any-hover: none) {
  html[data-juventus-theme=medical-leadership] .main-header.main-header__dark .main-header--contact-link:active {
    color: #4b0d42;
  }
}

.main-header.main-header__light .main-header--contact-link {
  color: #ffffff;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-header.main-header__light .main-header--contact-link:hover, .main-header.main-header__light .main-header--contact-link:focus {
    color: #ededed;
  }
}
@media (any-hover: none) {
  .main-header.main-header__light .main-header--contact-link:active {
    color: #ededed;
  }
}

.publication-detail--publication-info-container {
  display: flex;
}
.publication-detail--publication-info-container .publication-detail--publication-info {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .publication-detail--publication-info-container .publication-detail--publication-info {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.publication-detail--publication-info-container .publication-detail--publication-info:first-child::after {
  content: "•";
  position: relative;
  margin-left: 5px;
  color: #be1621;
}
.publication-detail--publication-info-container .publication-detail--publication-info:last-child {
  margin-left: 10px;
}

.publication-detail--author-box {
  display: flex;
  align-items: center;
  border: solid 1px #ededed;
  border-left: none;
  border-right: none;
  padding: 32px 0;
  margin: 20px 0 0;
}
@media (min-width: 1024px) {
  .publication-detail--author-box {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1023px) {
  .publication-detail--author-box {
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.publication-detail--author-box .publication-detail--author-box-image {
  border-radius: 100%;
}
@media (max-width: 1023px) {
  .publication-detail--author-box .publication-detail--author-box-image {
    align-self: flex-start;
  }
}
@supports (object-fit: cover) {
  .publication-detail--author-box .publication-detail--author-box-image {
    object-fit: cover;
  }
  @media (min-width: 576px) {
    .publication-detail--author-box .publication-detail--author-box-image {
      width: 172px;
      height: 172px;
    }
  }
  @media (max-width: 575px) {
    .publication-detail--author-box .publication-detail--author-box-image {
      width: 124px;
      height: 124px;
    }
  }
}
@media (min-width: 1024px) {
  .publication-detail--author-box .publication-detail--author-box-container {
    margin-left: 20px;
  }
}
@media (max-width: 1023px) {
  .publication-detail--author-box .publication-detail--author-box-container {
    margin-top: 32px;
  }
}
.publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author {
    font-size: 2rem;
  }
}
.publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author-description {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #7a7a7a;
}
@media (max-width: 1279px) {
  .publication-detail--author-box .publication-detail--author-box-container .publication-detail--author-box-author-description {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}

.publication-detail--sub-navigation {
  display: flex;
  margin-top: 30px;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.publication-detail--sub-navigation .publication-detail--exit-arrow-label {
  color: #be1621;
  font-weight: 500;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 20px;
  margin-bottom: 4px;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  height: 1px;
  width: 32px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  color: #be1621;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow::after, .publication-detail--sub-navigation .publication-detail--exit-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow::after {
  transform: translate(-4px, -50%) rotate(-45deg);
  transition: left 200ms ease;
  border-left: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 8px;
  height: 8px;
}
.publication-detail--sub-navigation .publication-detail--exit-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(-5px, -50%);
  transition: width 200ms ease, left 200ms ease;
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow {
    color: #98121a;
  }
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow::after, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow::after {
    left: -15px;
  }
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow::before, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .publication-detail--sub-navigation:hover .publication-detail--exit-arrow-label, .publication-detail--sub-navigation:focus .publication-detail--exit-arrow-label {
    color: #98121a;
  }
}
@media (any-hover: none) {
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow {
    color: #98121a;
  }
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow::after {
    left: -15px;
  }
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow::before {
    left: -15px;
    width: calc(100% + 15px);
  }
  .publication-detail--sub-navigation:active .publication-detail--exit-arrow-label {
    color: #98121a;
  }
}

.quotes {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (max-width: 1279px) {
  .quotes {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .quotes {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.quotes::before, .quotes::after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  height: 1px;
  width: 48px;
  transform: translate(-50%, 0);
  background: #be1621;
}
html[data-juventus-theme=brand] .quotes::before, html[data-juventus-theme=brand] .quotes::after {
  background: #be1621;
}

html[data-juventus-theme=highschool] .quotes::before, html[data-juventus-theme=highschool] .quotes::after {
  background: #dd7200;
}

html[data-juventus-theme=economy] .quotes::before, html[data-juventus-theme=economy] .quotes::after {
  background: #286ab7;
}

html[data-juventus-theme=medicine] .quotes::before, html[data-juventus-theme=medicine] .quotes::after {
  background: #1f9285;
}

html[data-juventus-theme=engineering] .quotes::before, html[data-juventus-theme=engineering] .quotes::after {
  background: #948b85;
}

html[data-juventus-theme=medical-leadership] .quotes::before, html[data-juventus-theme=medical-leadership] .quotes::after {
  background: #771469;
}

.quotes::before {
  top: 0;
}
.quotes::after {
  bottom: 0;
}
.quotes .quotes--quote {
  font-size: 3.2rem;
  line-height: 1.12;
  font-family: "Argesta", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic;
  color: #be1621;
}
@media (max-width: 1279px) {
  .quotes .quotes--quote {
    font-size: 2.8rem;
    line-height: 1.14;
  }
}
html[data-juventus-theme=brand] .quotes .quotes--quote {
  color: #be1621;
}

html[data-juventus-theme=highschool] .quotes .quotes--quote {
  color: #dd7200;
}

html[data-juventus-theme=economy] .quotes .quotes--quote {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .quotes .quotes--quote {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .quotes .quotes--quote {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .quotes .quotes--quote {
  color: #771469;
}

.quotes .quotes--quote::after {
  content: "»";
}
.quotes .quotes--quote::before {
  content: "«";
}
.quotes .quotes--author {
  color: #5c5c5c;
  font-size: 1.5rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .quotes .quotes--author {
    margin-top: 12px;
  }
}
@media (min-width: 1280px) {
  .quotes .quotes--author {
    margin-top: 16px;
  }
}
.quotes .quotes--author-col {
  flex: 0 0 66.6666666667%;
}
html.ie11 .quotes .quotes--author-col {
  flex-basis: auto;
  width: 66.6666666667%;
}

html.ie10 .quotes .quotes--author-col {
  flex-basis: auto;
  width: 66.6666666667%;
}

.richtext b, .richtext strong {
  color: #1f1f1f;
}
.richtext p {
  color: #5c5c5c;
}
@media (max-width: 1279px) {
  .richtext > p:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .richtext > p:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (max-width: 1279px) {
  .richtext > p:not(:last-child) + p {
    margin-top: -16px;
  }
}
@media (min-width: 1280px) {
  .richtext > p:not(:last-child) + p {
    margin-top: -24px;
  }
}
@media (max-width: 1279px) {
  .richtext ul:not(:last-child), .richtext ol:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .richtext ul:not(:last-child), .richtext ol:not(:last-child) {
    margin-bottom: 40px;
  }
}
.richtext p:last-child, .richtext ul:last-child, .richtext ol:last-child {
  margin-bottom: 0;
}
@media (min-width: 1555px) {
  .richtext ul:not(.start-dates-entry--list), .richtext ol {
    margin-left: -16px;
  }
}
@media (max-width: 1555px) {
  .richtext ul:not(.start-dates-entry--list), .richtext ol {
    margin-left: -12px;
  }
}

.schools-teaser {
  margin-bottom: 36px;
}
.schools-teaser .schools-teaser--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto;
}
.schools-teaser .schools-teaser--list {
  display: flex;
  flex-flow: row wrap;
}
html.ie11 .schools-teaser .schools-teaser--list {
  min-height: 1px;
}

.schools-teaser .schools-teaser--item {
  position: relative;
  min-height: 1px;
  max-width: 100%;
}
.schools-teaser .schools-teaser--container {
  padding-top: 2px;
  padding-left: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
}
.schools-teaser .schools-teaser--container .schools-teaser--container:not(.u-iknowwhatimdoing) {
  visibility: hidden !important;
}
.schools-teaser .schools-teaser--container .schools-teaser--container:not(.u-iknowwhatimdoing)::before {
  visibility: visible;
  content: "Nested `grid--container` detected!";
  font-size: 20px;
  line-height: 1.2;
  color: red;
}
.schools-teaser .schools-teaser--list {
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
}
.schools-teaser .schools-teaser--item {
  padding-top: 1px;
  padding-left: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
}
@media (max-width: 1555px) {
  .schools-teaser {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .schools-teaser {
    margin-bottom: 24px;
  }
}
.schools-teaser .schools-teaser--item {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .schools-teaser .schools-teaser--item {
    flex: 0 0 20%;
  }
  html.ie11 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 20%;
  }

  html.ie10 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 20%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--item {
    flex: 0 0 33.3333333333%;
  }
  html.ie11 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 33.3333333333%;
  }

  html.ie10 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 33.3333333333%;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--item {
    flex: 0 0 100%;
  }
  html.ie11 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .schools-teaser .schools-teaser--item {
    flex-basis: auto;
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--item:nth-child(4n), .schools-teaser .schools-teaser--item:nth-child(5n) {
    flex: 0 0 50%;
  }
  html.ie11 .schools-teaser .schools-teaser--item:nth-child(4n), html.ie11 .schools-teaser .schools-teaser--item:nth-child(5n) {
    flex-basis: auto;
    width: 50%;
  }

  html.ie10 .schools-teaser .schools-teaser--item:nth-child(4n), html.ie10 .schools-teaser .schools-teaser--item:nth-child(5n) {
    flex-basis: auto;
    width: 50%;
  }
}
@media (min-width: 1556px) {
  .schools-teaser .schools-teaser--item {
    height: calc(100vh - 88px - 36px);
  }
}
@media (min-width: 1280px) {
  .schools-teaser .schools-teaser--item {
    height: calc(100vh - 88px - 32px);
    min-height: 600px;
    max-height: 1024px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--item {
    height: calc(50vh - 22px - 32px);
    min-height: 320px;
    max-height: 500px;
  }
}
.schools-teaser .schools-teaser--figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.schools-teaser .schools-teaser--figure::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  transition: background 200ms ease;
}
.schools-teaser .schools-teaser--image {
  /* stylelint-disable */
  font-family: "object-fit: cover;";
  /* stylelint-enable */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100);
  transition: filter 200ms ease;
}
.schools-teaser .schools-teaser--link {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.schools-teaser .schools-teaser--text {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: #000000;
  padding: 0 24px 12px;
  width: 100%;
}
@media (min-width: 1556px) {
  .schools-teaser .schools-teaser--text {
    margin-bottom: 80px;
    height: 266px;
    padding: 0 24px 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .schools-teaser .schools-teaser--text {
    margin-bottom: 80px;
    height: 310px;
    padding: 0 24px 12px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px), (min-width: 768px) and (max-width: 1023px) {
  .schools-teaser .schools-teaser--text {
    height: 198px;
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--text {
    align-content: flex-end;
    padding-bottom: 0;
    height: 128px;
  }
}
.schools-teaser .schools-teaser--description,
.schools-teaser .schools-teaser--title {
  flex: 0 0 100%;
  width: 100%;
  transition: transform 200ms ease;
}
.schools-teaser .schools-teaser--description {
  color: #5c5c5c;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--description {
    display: none;
  }
}
.schools-teaser .schools-teaser--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 300;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 1.375;
  color: #000000;
  color: #000000;
}
@media (max-width: 1279px) {
  .schools-teaser .schools-teaser--title {
    font-size: 2.8rem;
    line-height: 1.48;
  }
}
@media (min-width: 1280px) and (max-width: 1555px) {
  .schools-teaser .schools-teaser--title {
    display: flex;
    align-items: flex-end;
    min-height: 88px;
    text-align: start;
  }
}
@media (min-width: 768px) {
  .schools-teaser .schools-teaser--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--title {
    margin-bottom: 16px;
  }
}
.schools-teaser .schools-teaser--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 32px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  position: absolute;
  bottom: 8px;
}
.schools-teaser .schools-teaser--arrow::after, .schools-teaser .schools-teaser--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.schools-teaser .schools-teaser--arrow::after {
  transform: translate(4px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 8px;
  height: 8px;
}
.schools-teaser .schools-teaser--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(5px, -50%);
}
@media (min-width: 768px) {
  .schools-teaser .schools-teaser--arrow {
    transform: translate(-20px, 0);
    left: 24px;
    width: 160px;
  }
}
@media (max-width: 767px) {
  .schools-teaser .schools-teaser--arrow {
    left: calc(100% - 110px);
    bottom: 32px;
    width: 54px;
  }
}
.schools-teaser .schools-teaser--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__brand .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__brand .schools-teaser--figure::after {
    background: rgba(190, 22, 33, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__highschool .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__highschool .schools-teaser--figure::after {
    background: rgba(221, 114, 0, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__economy .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__economy .schools-teaser--figure::after {
    background: rgba(40, 106, 183, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__medicine .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__medicine .schools-teaser--figure::after {
    background: rgba(31, 146, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__engineering .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__engineering .schools-teaser--figure::after {
    background: rgba(148, 139, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:hover.schools-teaser--link__medical-leadership .schools-teaser--figure::after, .schools-teaser .schools-teaser--link:focus.schools-teaser--link__medical-leadership .schools-teaser--figure::after {
    background: rgba(119, 20, 105, 0.6);
  }
}
@media (any-hover: none) {
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__brand .schools-teaser--figure::after {
    background: rgba(190, 22, 33, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__highschool .schools-teaser--figure::after {
    background: rgba(221, 114, 0, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__economy .schools-teaser--figure::after {
    background: rgba(40, 106, 183, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__medicine .schools-teaser--figure::after {
    background: rgba(31, 146, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__engineering .schools-teaser--figure::after {
    background: rgba(148, 139, 133, 0.6);
  }
  .schools-teaser .schools-teaser--link:active.schools-teaser--link__medical-leadership .schools-teaser--figure::after {
    background: rgba(119, 20, 105, 0.6);
  }
}
.schools-teaser .schools-teaser--link {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .schools-teaser .schools-teaser--link:hover .schools-teaser--title,
.schools-teaser .schools-teaser--link:hover .schools-teaser--description,
.schools-teaser .schools-teaser--link:hover .schools-teaser--text, .schools-teaser .schools-teaser--link:focus .schools-teaser--title,
.schools-teaser .schools-teaser--link:focus .schools-teaser--description,
.schools-teaser .schools-teaser--link:focus .schools-teaser--text {
    color: #ffffff;
  }
  .schools-teaser .schools-teaser--link:hover .schools-teaser--image, .schools-teaser .schools-teaser--link:focus .schools-teaser--image {
    filter: grayscale(0);
  }
}
@media (any-hover: hover) and (min-width: 768px), (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
  .schools-teaser .schools-teaser--link:hover .schools-teaser--title, .schools-teaser .schools-teaser--link:focus .schools-teaser--title {
    transform: translate(0, -24px);
  }
  .schools-teaser .schools-teaser--link:hover .schools-teaser--description, .schools-teaser .schools-teaser--link:focus .schools-teaser--description {
    transform: translate(0, -32px);
  }
  .schools-teaser .schools-teaser--link:hover .schools-teaser--arrow, .schools-teaser .schools-teaser--link:focus .schools-teaser--arrow {
    padding-left: 12px;
  }
}
@media (any-hover: hover) and (max-width: 767px), (-ms-high-contrast: active) and (max-width: 767px), (-ms-high-contrast: none) and (max-width: 767px) {
  .schools-teaser .schools-teaser--link:hover .schools-teaser--arrow, .schools-teaser .schools-teaser--link:focus .schools-teaser--arrow {
    padding-left: 18px;
  }
}
@media (any-hover: none) {
  .schools-teaser .schools-teaser--link:active .schools-teaser--title,
.schools-teaser .schools-teaser--link:active .schools-teaser--description,
.schools-teaser .schools-teaser--link:active .schools-teaser--text {
    color: #ffffff;
  }
  .schools-teaser .schools-teaser--link:active .schools-teaser--image {
    filter: grayscale(0);
  }
}
@media (any-hover: none) and (min-width: 768px) {
  .schools-teaser .schools-teaser--link:active .schools-teaser--title {
    transform: translate(0, -24px);
  }
  .schools-teaser .schools-teaser--link:active .schools-teaser--description {
    transform: translate(0, -32px);
  }
  .schools-teaser .schools-teaser--link:active .schools-teaser--arrow {
    padding-left: 12px;
  }
}
@media (any-hover: none) and (max-width: 767px) {
  .schools-teaser .schools-teaser--link:active .schools-teaser--arrow {
    padding-left: 18px;
  }
}

.start-dates-entry .start-dates-entry--list {
  padding-left: 0;
}
.start-dates-entry .start-dates-entry--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
}
@media (max-width: 1279px) {
  .start-dates-entry .start-dates-entry--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .start-dates-entry .start-dates-entry--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .start-dates-entry .start-dates-entry--title {
    font-size: 2rem;
  }
}

.sticky-panel {
  position: sticky;
  top: 0;
}
@media (max-width: 1279px) {
  .sticky-panel {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .sticky-panel {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .sticky-panel {
    padding-bottom: 0;
  }
}
.has-anchor-navigation .sticky-panel {
  padding-top: 0;
  top: 56px;
}
@media (max-width: 767px) {
  .has-anchor-navigation .sticky-panel {
    margin-top: 32px;
  }
}
@media (max-width: 1279px) {
  .has-anchor-navigation .sticky-panel {
    top: 48px;
  }
}

.teaser-contact .teaser-contact--text {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--text {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--text {
    padding-bottom: 32px;
  }
}
@media (min-width: 1280px) {
  .teaser-contact .teaser-contact--text {
    padding-bottom: 40px;
  }
}
.teaser-contact .teaser-contact--figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teaser-contact .teaser-contact--figcaption {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--figcaption {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-contact .teaser-contact--image {
  display: block;
  object-fit: cover;
  border-radius: 100%;
  height: 160px;
  width: 160px;
}
@media (max-width: 1279px) {
  .teaser-contact .teaser-contact--image {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .teaser-contact .teaser-contact--image {
    margin-bottom: 16px;
  }
}
.teaser-contact .teaser-contact--list {
  margin-top: 32px;
}
.teaser-contact .teaser-contact--list-short {
  margin-top: 0;
}

.teaser-event .teaser-event--title {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-event .teaser-event--title {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-event .teaser-event--description {
  color: #7a7a7a;
  padding-bottom: 16px;
  font-size: 1.7rem;
  line-height: 1.41;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .teaser-event .teaser-event--description {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
.teaser-event .teaser-event--description:last-of-type {
  padding-bottom: 0;
}
.teaser-event .teaser-event--description-value {
  font-size: 1.2rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
.teaser-event .teaser-event--list {
  margin-top: 24px;
}

.teaser-news .teaser-news--image {
  display: block;
  width: 100%;
}
@media (max-width: 1279px) {
  .teaser-news .teaser-news--image {
    padding-bottom: 16px;
  }
}
@media (min-width: 1280px) {
  .teaser-news .teaser-news--image {
    padding-bottom: 24px;
  }
}
.teaser-news .teaser-news--pubdate {
  color: #1f1f1f;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 1279px) {
  .teaser-news .teaser-news--pubdate {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-news .teaser-news--text {
  color: #7a7a7a;
}
.teaser-news .teaser-news--cta {
  margin-top: 32px;
}

.teaser-subscription .teaser-subscription--text {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
}
@media (max-width: 1279px) {
  .teaser-subscription .teaser-subscription--text {
    font-size: 1.4rem;
    line-height: 1.42;
  }
}
.teaser-subscription .teaser-subscription--list {
  margin-top: 32px;
}

.text-link {
  display: flex;
  align-items: center;
  line-height: 24px;
  color: #be1621;
  font-size: 1.7rem;
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
}
html[data-juventus-theme=brand] .text-link {
  color: #be1621;
}

html[data-juventus-theme=highschool] .text-link {
  color: #dd7200;
}

html[data-juventus-theme=economy] .text-link {
  color: #286ab7;
}

html[data-juventus-theme=medicine] .text-link {
  color: #1f9285;
}

html[data-juventus-theme=engineering] .text-link {
  color: #948b85;
}

html[data-juventus-theme=medical-leadership] .text-link {
  color: #771469;
}

.text-link .text-link--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
}
.text-link .text-link--arrow::after, .text-link .text-link--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.text-link .text-link--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.text-link .text-link--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.text-link--narrow .text-link--arrow {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 1px;
  width: 48px;
  transition: padding-left 200ms ease;
  box-sizing: content-box;
  margin-left: -8px;
}
.text-link--narrow .text-link--arrow::after, .text-link--narrow .text-link--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.text-link--narrow .text-link--arrow::after {
  transform: translate(6px, -50%) rotate(45deg);
  border-right: 1px solid currentColor;
  border-top: 1px solid currentColor;
  width: 12px;
  height: 12px;
}
.text-link--narrow .text-link--arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translate(7px, -50%);
}
.text-link .text-link--arrow-wrap {
  display: flex;
  align-items: center;
  width: 93px;
}
.text-link.text-link__base {
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .text-link.text-link__base:hover .text-link--arrow, .text-link.text-link__base:focus .text-link--arrow {
    padding-left: 24px;
  }
}
@media (any-hover: none) {
  .text-link.text-link__base:active .text-link--arrow {
    padding-left: 24px;
  }
}
.text-link.text-link__sidebar {
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
.text-link.text-link__sidebar .text-link--arrow-wrap {
  width: 80px;
}
@media screen and (min-width: 1280px) and (max-width: 1556px) {
  .text-link.text-link__sidebar .text-link--text {
    max-width: 136px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .text-link.text-link__sidebar .text-link--text {
    max-width: 160px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .text-link.text-link__sidebar .text-link--text {
    max-width: 144px;
  }
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .text-link.text-link__sidebar:hover, .text-link.text-link__sidebar:focus {
    color: #98121a;
  }
  html[data-juventus-theme=brand] .text-link.text-link__sidebar:hover, html[data-juventus-theme=brand] .text-link.text-link__sidebar:focus {
    color: #98121a;
  }

  html[data-juventus-theme=highschool] .text-link.text-link__sidebar:hover, html[data-juventus-theme=highschool] .text-link.text-link__sidebar:focus {
    color: #b15b00;
  }

  html[data-juventus-theme=economy] .text-link.text-link__sidebar:hover, html[data-juventus-theme=economy] .text-link.text-link__sidebar:focus {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .text-link.text-link__sidebar:hover, html[data-juventus-theme=medicine] .text-link.text-link__sidebar:focus {
    color: #19756a;
  }

  html[data-juventus-theme=engineering] .text-link.text-link__sidebar:hover, html[data-juventus-theme=engineering] .text-link.text-link__sidebar:focus {
    color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .text-link.text-link__sidebar:hover, html[data-juventus-theme=medical-leadership] .text-link.text-link__sidebar:focus {
    color: #570f4d;
  }

  .text-link.text-link__sidebar:hover .text-link--arrow, .text-link.text-link__sidebar:focus .text-link--arrow {
    padding-left: 24px;
  }
}
@media (any-hover: none) {
  .text-link.text-link__sidebar:active {
    color: #98121a;
  }
  html[data-juventus-theme=brand] .text-link.text-link__sidebar:active {
    color: #98121a;
  }

  html[data-juventus-theme=highschool] .text-link.text-link__sidebar:active {
    color: #b15b00;
  }

  html[data-juventus-theme=economy] .text-link.text-link__sidebar:active {
    color: #286ab7;
  }

  html[data-juventus-theme=medicine] .text-link.text-link__sidebar:active {
    color: #19756a;
  }

  html[data-juventus-theme=engineering] .text-link.text-link__sidebar:active {
    color: #766f6a;
  }

  html[data-juventus-theme=medical-leadership] .text-link.text-link__sidebar:active {
    color: #570f4d;
  }

  .text-link.text-link__sidebar:active .text-link--arrow {
    padding-left: 24px;
  }
}
.text-link.text-link__sidebar-text {
  color: #1f1f1f;
}
.text-link.text-link__secondary.text-link__secondary.text-link__secondary {
  color: #1f1f1f;
  /* one or more available input mechanism(s) can hover over elements with ease */
  /* one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms */
}
@media (any-hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .text-link.text-link__secondary.text-link__secondary.text-link__secondary:hover, .text-link.text-link__secondary.text-link__secondary.text-link__secondary:focus {
    color: #000000;
  }
}
@media (any-hover: none) {
  .text-link.text-link__secondary.text-link__secondary.text-link__secondary:active {
    color: #000000;
  }
}
@media (max-width: 1279px) {
  .text-link.text-link__top-spacing {
    padding-top: 12px;
  }
}
@media (min-width: 1280px) {
  .text-link.text-link__top-spacing {
    padding-top: 16px;
  }
}
@media (max-width: 1279px) {
  .text-link.text-link__top-spacing:first-of-type {
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .text-link.text-link__top-spacing:first-of-type {
    padding-top: 40px;
  }
}
@media (max-width: 1279px) {
  .text-link.text-link__bottom-spacing {
    padding-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .text-link.text-link__bottom-spacing {
    padding-bottom: 16px;
  }
}

.ui-accordion .ui-accordion--head {
  cursor: pointer;
}
.ui-accordion .ui-accordion--body {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 200ms ease;
}
.ui-accordion.ui-accordion__is-open .ui-accordion--body {
  opacity: 1;
}
.ui-accordion.ui-accordion__is-animating .ui-accordion--body {
  transition: opacity 200ms ease, max-height 200ms ease;
}

.video {
  flex: 0 0 66.6666666667%;
}
@media (max-width: 1023px) {
  .video {
    flex: 0 0 100%;
  }
  html.ie11 .video {
    flex-basis: auto;
    width: 100%;
  }

  html.ie10 .video {
    flex-basis: auto;
    width: 100%;
  }
}
html.ie11 .video {
  flex-basis: auto;
  width: 66.6666666667%;
}

html.ie10 .video {
  flex-basis: auto;
  width: 66.6666666667%;
}

.video .video--title {
  font-family: "SuisseIntl", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.31;
  color: #000000;
}
@media (max-width: 1279px) {
  .video .video--title {
    margin-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  .video .video--title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1279px) {
  .video .video--title {
    font-size: 2rem;
  }
}
.video .video--wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
}
.video .video--iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grecaptcha-badge {
  margin-bottom: 24px;
}