.grid-annual-report {
    // Vars
    $arrow-size: 12px;
    // Support

    // Module

    & {
        & {
            // item grid
            @include grid--root((row: 'list', cols: 'item'));

            @include mq('>md') {
                margin-left: -15px;
                margin-right: -15px;
            }
        }

        .grid-annual-report--item {
            @include grid--span((ratio: 6 / 12, mq:'>md'));
            @include grid--span((ratio: 12 / 12, mq: 'md'));

            @include mq('>md') {
                padding-left: 15px;
                padding-right: 15px;
                margin-bottom: 32px;
            }

            height: 100%;
            margin-bottom: 24px;
            max-width: 376px;
        }

        .grid-annual-report--link {
            $responsive-grid-properties: (
                'xs': (min-height: 390px),
                'sm': (min-height: 400px),
                'md': (min-height: 400px),
                'lg': (min-height: 410px),
                'xl': (min-height: 423px),
                'xxl': (min-height: 423px),
            );

            @each $mq, $props in $responsive-grid-properties {
                @include mq($mq) {
                    min-height: map-get($props, 'min-height');
                    padding: map-get($props, 'padding');
                }
            }

            display: flex;
            flex-wrap: wrap;
            height: 100%;
            min-height: 228px;
            background: $c--monochrome-light-gray;

            transition: transition-props($trs--base, background, color, border);

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }

        .grid-annual-report--item-image {
            width: 100%;
            object-fit: cover;
            height: 100%;

            @include mq('>md') {
                max-height: 250px;
            }

            @include mq('<=sm') {
                max-height: 218px;
            }
        }

        .grid-annual-report--item-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 24px;
            width: 100%;
            border: 1px solid $c--monochrome-gray;
            border-top: none;

            @include mq('>md') {
                min-height: 172px;
            }

            @include mq('<=sm') {
                min-height: 172px;
            }

            & .grid-annual-report--item-title {
                @include type--h4;
                color: $c--themes-brand;
            }

            .grid-annual-report--item-arrow-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-shrink: 0;
                height: 19px;
                margin-bottom: 4px;
            }

            .grid-annual-report--item-arrow {
                @include animated-arrow($arrow-size, 4);
                position: relative;
                left: -10px;
                margin-left: 0;
                color: $c--themes-brand;
            }
        }
    }

    // Facets

    // States
    .grid-annual-report--link {
        @include has-focus {
            background: $c--themes-brand;

            .grid-annual-report--item-text {
                border: 1px solid transparent;
                border-top: none;
            }

            .grid-annual-report--item-title {
                color: $c--monochrome-white;
            }

            .grid-annual-report--item-arrow {
                color: $c--monochrome-white;
            }
        }
    }
}
