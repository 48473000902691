.quotes {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            display: flex;
            justify-content: center;
            text-align: center;

            @include responsive-vertical-spacing('large') using($spacing) {
                padding: {
                    top: $spacing;
                    bottom: $spacing;
                }
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                display: block;
                height: 1px;
                width: 48px;
                transform: translate(-50%, 0);

                @include style-all-root-themes using($color) {
                    background: $color;
                }
            }

            &::before {
                top: 0;
            }

            &::after {
                bottom: 0;
            }
        }

        .quotes--quote {
            @include type--quotes;

            @include style-all-root-themes using($color) {
                color: $color;
            }

            &::after {
                content: '\00BB';
            }

            &::before {
                content: '\00AB';
            }
        }

        .quotes--author {
            color: $c--monochrome-dark-gray;

            @include responsive-vertical-spacing('x-small') using($spacing) {
                margin: {
                    top: $spacing;
                }
            }

            @include type--text-caption-big;
        }

        .quotes--author-col {
            @include grid--span((ratio: 4 / 6));
        }
    }

    // Facets

    // States
}
