////
/// List styles (ul, ol)
////

@mixin type--list {
    padding-left: 12px;
    list-style: none;
    color: $c--monochrome-dark-gray;

    @include media('>=xl') {
        padding-left: 16px;
    }

    > li {
        position: relative;
        display: inline-block;
        width: 100%;
        padding-left: 2.6rem;

        > * { // stylelint-disable selector-max-universal, plugin/selector-bem-pattern
            margin-bottom: 0;
        }

        ~ li {
            margin-top: $sz--list-vertical-spacing;
        }

        > ul, ol {
            margin-top: $sz--list-vertical-spacing;
        }

        &::before {
            position: absolute;
            top: 0;
            right: 100%;
            display: inline-block;
            width: 8px;
            height: auto;
            font-size: inherit;
            padding: 0;
        }
    }
}

@mixin type--ul {
    @include type--list;

    > li {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            transform: translateY(-50%);
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            text-align: right;

            @include style-all-root-themes using($color) {
                background-color: $color;
            }
        }
    }
}

@mixin type--ol {
    @include type--list;
    counter-reset: li;

    > li {
        &::before {
            content: counter(li) '.';
            counter-increment: li;
            text-align: right;
            transform: translateX(100%);
            width: auto;

            @include ff--base-medium;

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }
    }
}

@mixin type--ul-check {
    @include type--list;

    > li {
        position: relative;
    }
}
