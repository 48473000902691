.two-cols {
    .grid-articles {
        .grid-articles--tile {
            @include grid--span((ratio: 6 / 12, mq:('>xl', 'xl', 'lg')));
            @include grid--span((ratio: 12 / 12, mq: ('md', '<=sm')));
        }
    }
}

.grid-articles {
    // Vars
    $arrow-size: 12px;

    // Support

    // Module
    width: 100%;

    & {
        & {
            // item grid
            @include grid--root((row: 'list', cols: 'tile'));

            @each $value in $grid--base-spacing {
                $gutter: map-get($value, 'gutter');
                $mq: map-get($value, 'mq');

                @include grid--space((gutter: ($gutter $gutter), row: 'list', cols: 'tile', mq: $mq));
            }
        }

        .grid-articles--list {
            grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
            grid-auto-rows: 1fr;
        }

        .grid-articles--tile {
            overflow: hidden;

            @include grid--span((ratio: 4 / 12, mq:('>xl', 'xl')));
            @include grid--span((ratio: 6 / 12, mq:('lg', 'md')));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .grid-articles--tile-title {
            @include type--h4;
            color: $c--monochrome-black;
        }

        .grid-articles--dark-text {
            color: $c--monochrome-black;
            transition: color $trs--base;
        }

        .grid-articles--tile-link {
            $responsive-grid-properties: (
                'xs': (min-height: 320px, padding: 24px),
                'sm': (min-height: 320px, padding: 24px),
                'md': (min-height: 350px, padding: 24px),
                'lg': (min-height: 350px, padding: 24px),
                'xl': (min-height: 350px, padding: 32px),
                'xxl': (min-height: 370px, padding: 40px),
            );

            display: flex;
            flex-wrap: wrap;
            height: 100%;
            width: 100%;
            background: $c--monochrome-light-gray;
            overflow: hidden;
            word-break: break-word;
            hyphens: auto;

            @include at-root('.ie11') {
                word-break: break-all;
            }

            transition: transition-props($trs--base, background, color, border);

            @include style-all-root-themes using($color) {
                color: $color;
            }

            .grid-articles--tile-container,
            .grid-articles--tile-container-without-image {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                border: 1px solid $c--monochrome-gray;

                @each $mq, $props in $responsive-grid-properties {
                    @include mq($mq) {
                        padding: map-get($props, 'padding');
                    }
                }
            }

            .grid-articles--tile-container {
                height: calc(100% - 250px);
                border-style: none solid solid;

                @include mq('xl') {
                    height: calc(100% - 214px);
                }

                @include mq('md') {
                    height: calc(100% - 192px);
                }
            }

            .grid-articles--tile-container-without-image {
                height: 100%;
            }

            .grid-articles--tile-figure {
                height: 250px;
                width: 100%;
                overflow: hidden;

                @include mq('xl') {
                    height: 214px;
                }

                @include mq('md') {
                    height: 192px;
                }
            }

            .grid-articles--tile-image {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .grid-articles--tile-title,
            .grid-articles--tile-subtitle {
                @include type--h3(false);
                transition: color $trs--base;
                flex: 0 0 100%;
            }

            .grid-articles--tile-subtitle {
                @include type--h4(false);
                color: $c--monochrome-med-gray;
                font-weight: 500;
            }

            .grid-articles--tile-article-info-container,
            .grid-articles--tile-article-info-container-with-margin {
                display: flex;

                .grid-articles--tile-article-info {
                    position: relative;
                    color: $c--monochrome-med-black;

                    &:first-child::after {
                        content: '•';
                        position: relative;
                        margin-left: 5px;
                        color: $c--themes-brand;
                    }

                    &:last-child {
                        margin-left: 8px;
                    }
                }
            }

            .grid-articles--tile-article-info-container-with-margin {
                margin-top: 30px;
            }

            .grid-articles--tile-tag {
                padding: 4px 12px;
                border-radius: 16px;
                transition: background color $trs--base;
                color: $c--monochrome-white;
                background: $c--themes-brand;

                @include type--paragraph02;

                &:hover {
                    background: $c--monochrome-white;
                    color: $c--themes-brand;
                }
            }

            .grid-articles--tile-footer {
                display: flex;
                flex: 0 0 100%;
                align-items: flex-end;
                justify-content: space-between;

                @include responsive-vertical-spacing('large') using($sizing) {
                    padding-top: $sizing;
                }
            }

            .grid-articles--tile-arrow-wrapper {
                display: flex;
                align-items: center;
                flex-basis: 70px;
                flex-shrink: 0;
                height: 19px;
                margin-bottom: 4px;
            }

            .grid-articles--tile-arrow {
                @include animated-arrow($arrow-size, 4);
                margin-left: 0;
                color: $c--themes-brand;
            }
        }

        .grid-articles--button-load {
            margin-top: 50px;
            padding: 16px 24px;
            border: 1px solid $c--themes-brand;
            color: $c--themes-brand;
            font-weight: $fw--medium;
            transition: color $trs--base;
            justify-self: center;
            cursor: pointer;
        }

        // Facets

        // States
        .grid-articles--tile-link {
            @include has-focus {
                color: $c--monochrome-white;
                background: $c--themes-brand;

                .grid-articles--tile-container,
                .grid-articles--tile-container-without-image {
                    border: 1px solid transparent;
                }

                .grid-articles--tile-container {
                    border-style: none solid solid;
                }

                .grid-articles--tile-tag {
                    background: $c--monochrome-white;
                    color: $c--themes-brand;
                }

                .grid-articles--tile-subtitle {
                    color: $c--monochrome-white;
                }

                .grid-articles--dark-text {
                    color: $c--monochrome-white;
                }

                .grid-articles--tile-article-info {
                    color: $c--monochrome-white;

                    &:first-child::after {
                        color: $c--monochrome-white;
                    }
                }

                .grid-articles--tile-arrow {
                    @include animated-arrow-hover(15px);
                    color: $c--monochrome-white;
                }
            }
        }

        .grid-articles--button-load {
            @include has-focus {
                background: $c--themes-brand;
                color: $c--monochrome-white;
            }
        }
    }
}
