.richtext {
    // Vars

    // Support

    // Module
    & {
        b, strong {
            color: $c--monochrome-med-black;
        }

        p {
            color: $c--monochrome-dark-gray;
        }

        > p:not(:last-child) {
            @include responsive-vertical-spacing('large') using($margin) {
                margin-bottom: $margin;
            }

            + p {
                @include responsive-vertical-spacing('small') using($margin) {
                    margin-top: -$margin;
                }
            }
        }

        ul, ol {
            &:not(:last-child) {
                @include responsive-vertical-spacing('large') using($margin) {
                    margin-bottom: $margin;
                }
            }
        }

        p, ul, ol {
            &:last-child {
                margin-bottom: 0;
            }
        }

        ul:not(.start-dates-entry--list), ol {
            @include media('>=xl') {
                margin-left: -16px;
            }

            @include media('<=xl') {
                margin-left: -12px;
            }
        }
    }

    // Facets

    // States
}
