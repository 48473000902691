/// Monochrome special decoration colors
///
/// Blacks
///
$c--monochrome-black: #000000;
$c--monochrome-med-black: #1f1f1f;
$c--monochrome-light-black: #3d3d3d;
$c--monochrome-dark-gray: #5c5c5c;
$c--monochrome-med-gray: #7a7a7a;

/// Whites
///
$c--monochrome-dark-silver: #bfbfbf;
$c--monochrome-silver: #d1d1d1;
$c--monochrome-light-silver: #e0e0e0;
$c--monochrome-gray: #ededed;
$c--monochrome-light-gray: #f7f7f7;
$c--monochrome-white: #ffffff;

$monochromes: (
    'black': $c--monochrome-black,
    'med-black': $c--monochrome-med-black,
    'light-black': $c--monochrome-light-black,
    'dark-gray': $c--monochrome-dark-gray,
    'med-gray': $c--monochrome-med-gray,
    'dark-silver': $c--monochrome-dark-silver,
    'silver': $c--monochrome-silver,
    'light-silver': $c--monochrome-light-silver,
    'gray': $c--monochrome-gray,
    'light-gray': $c--monochrome-light-gray,
    'white': $c--monochrome-white,
);

/// Themes
///
/// Primary
///
$c--themes-brand: #be1621;
$c--themes-brand-dark: #98121a;
$c--themes-brand-contrast: #240507;

/// Secondary
///
$c--themes-highschool: #dd7200;
$c--themes-highschool-dark: #b15b00;
$c--themes-highschool-contrast: #522a00;

$c--themes-economy: #286ab7;
$c--themes-economy-dark: #205592;
$c--themes-economy-contrast: #0b1d33;

$c--themes-medicine: #1f9285;
$c--themes-medicine-dark: #19756a;
$c--themes-medicine-contrast: #092a26;

$c--themes-engineering: #948b85;
$c--themes-engineering-dark: #766f6a;
$c--themes-engineering-contrast: #46423f;

$c--themes-medical-leadership: #771469;
$c--themes-medical-leadership-dark: #570f4d;
$c--themes-medical-leadership-contrast: #090208;


$themes: (
    'brand': ('default': $c--themes-brand, 'dark': $c--themes-brand-dark, 'contrast': $c--themes-brand-contrast),
    'highschool': ('default': $c--themes-highschool, 'dark': $c--themes-highschool-dark, 'contrast': $c--themes-highschool-contrast),
    'economy': ('default': $c--themes-economy, 'dark': $c--themes-economy, 'contrast': $c--themes-economy-contrast),
    'medicine': ('default': $c--themes-medicine, 'dark': $c--themes-medicine-dark, 'contrast': $c--themes-medicine-contrast),
    'engineering': ('default': $c--themes-engineering, 'dark': $c--themes-engineering-dark, 'contrast': $c--themes-engineering-contrast),
    'medical-leadership': ('default': $c--themes-medical-leadership, 'dark': $c--themes-medical-leadership-dark, 'contrast': $c--themes-medical-leadership-contrast),
);

$background-colors-themes: ('light': $c--monochrome-white, 'dark': $c--monochrome-light-gray);

$themes-default: (
    'brand': $c--themes-brand,
    'highschool': $c--themes-highschool,
    'economy': $c--themes-economy,
    'medicine': $c--themes-medicine,
    'engineering': $c--themes-engineering,
    'medical-leadership': $c--themes-medical-leadership,
);

/// Single purpose colors
///
$c--label-text: #222222;
$c--label-selection: null;

$labels: (
    'text': $c--label-text,
    'selection': $c--label-selection,
);

/// Signal colors
///
$c--signal-info: #0000ff;
$c--signal-error: #ff0c3e;
$c--signal-warning: #ff9800;
$c--signal-success: #4caf62;

$signals: (
    'info': $c--signal-info,
    'error': $c--signal-error,
    'warning': $c--signal-warning,
    'success': $c--signal-success,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    themes: $themes-default,
    label: $labels,
    signal: $signals,
);
