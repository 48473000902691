$sz--mobile-header: 84px;

.built-form {
    // Vars

    // Support

    // Module
    & {
        @include mq('<=sm') {
            &::before {
                content: '';
                display: block;
                height: $sz--mobile-header;
                margin-top: $sz--mobile-header * -1;
                visibility: hidden;
            }
        }

        .built-form--col {
            @include grid--span((ratio: 7 / 12, mq: '>lg'));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 12 / 12, mq: 'md'));
            @include grid--span((ratio: 6 / 6, mq: '<=sm'));
        }

        .built-form--lead {
            @include type--lead;
            color: $c--monochrome-dark-gray;

            @include responsive-vertical-spacing('large') using($spacing) {
                margin-bottom: $spacing;
            }
        }

        .built-form--anchor {
            @include type--text-label;

            @each $theme, $color in $themes-default {
                &.built-form--anchor__#{$theme} {
                    color: $color;
                }
            }
        }
    }

    // Facets

    // States
}
