/// Secondary
///
@mixin btn--secondary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--secondary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--secondary-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': true, 'active': true)) {
            @include btn--secondary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': true)) {
            @include btn--secondary-disabled;
        }
    }
}

/// Base
///
@mixin btn--secondary-base {
    background-color: transparent;

    @include style-all-root-themes using($color) {
        color: $color;
        border-color: $color;
    }
}

/// Focus
///
@mixin btn--secondary-focus {
    background: $c--monochrome-light-gray;

    @include style-all-root-themes('dark') using($color) {
        color: $color;
        border-color: $color;
    }
}

/// Active
///
@mixin btn--secondary-active {
    //
}

/// Disabled
///
@mixin btn--secondary-disabled {
    @include style-all-root-themes('dark') using($color) {
        color: $c--monochrome-dark-silver;
        border-color: $c--monochrome-gray;
    }
}
