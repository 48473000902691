.main-header {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index(main-header);
            position: relative;
        }

        .main-header--row {
            align-items: center;
            justify-content: space-between;
            height: $main-header-height;
        }

        .main-header--logo {
            @include grid--span((ratio: 4 / 12, mq: '>md'));
            @include grid--span((ratio: 8 / 12, mq: '<=md'));
        }

        .main-header--menu {
            flex: 0 0 auto;
            margin-right: 0;
            margin-left: auto;

            @include mq('<=md') {
                display: none;
            }
        }

        .main-header--hamburger {
            display: flex;
            justify-content: flex-end;

            @include grid--span((ratio: 4 / 12));

            @include mq('>md') {
                display: none;
            }
        }

        .main-header--contact.main-header--contact.main-header--contact {
            display: flex;
            justify-content: flex-end;
            padding-left: 28px;

            @include mq('<=md') {
                display: none;
            }
        }

        .main-header--contact-link {
            @include type--h5(false);
        }
    }

    // Facets
    & {
        &.main-header__dark {
            .main-header--wrap {
                background: $c--monochrome-white;

                &::after {
                    background: $c--monochrome-white;
                }
            }

            .main-header--contact-link {
                @include style-all-root-themes using($color) {
                    color: $color;

                    @include has-focus {
                        color: darken($color, 10%);
                    }
                }
            }
        }

        &.main-header__light {
            .main-header--contact-link {
                color: $c--monochrome-white;

                @include has-focus {
                    color: $c--monochrome-gray;
                }
            }
        }
    }

    // States
}
