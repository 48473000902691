.wizard-finished--header {
    @include type--quotes;
    font-size: 4rem;
    line-height: 1.1;

    @include mq('<=lg') {
        font-size: 3.2rem;
        line-height: 1.125;
    }
}

.wizard-finished--sub-header {
    display: flex;
    align-items: center;
}

.wizard-finished--image {
    display: block;
    max-width: 100%;
    margin-bottom: 32px;
}

.wizard-finished--sub-header-icon {
    display: block;
    margin-right: 8px;
    color: $c--signal-success;
}

.wizard-finished--text {
    color: $c--monochrome-med-gray;

    @include responsive-vertical-spacing('small') using($spacing) {
        margin-bottom: $spacing;
    }
}
