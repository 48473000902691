.chart--container-donut {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    @include mq('<=md') {
        grid-template-columns: 1fr;

        .chart--container-donut-content:not(:last-child) {
            margin-bottom: 32px;
        }
    }
}

// Vertical bar chart
.chart--container-bar-vertical {
    & {
        .chart--container-bar-vertical-content {
            overflow-x: scroll;
        }
    }
}

// Vertical bar stacked chart
.chart--container-bar-vertical-stacked {
    & {
        .chart--container-bar-vertical-stacked-content {
            overflow-x: scroll;
        }
    }
}

// Horizontal bar stacked chart
.chart--container-bar-horizontal-stacked {
    & {
        .chart--container-bar-horizontal-stacked-content {
            overflow-x: scroll;
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chart--container-bar-vertical-content::-webkit-scrollbar,
.chart--container-bar-vertical-stacked-content::-webkit-scrollbar,
.chart--container-bar-horizontal-stacked-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
.chart--container-bar-vertical-content,
.chart--container-bar-vertical-stacked-content,
.chart--container-bar-horizontal-stacked-content {
    scrollbar-width: none;
}
