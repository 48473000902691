/// Font-styles, usable for project-wide styling of text instances
///
@mixin ff--base-light($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--light;
    font-style: normal;
}

@mixin ff--base-light-italic($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--light;
    font-style: italic;
}

@mixin ff--base-medium($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--medium;
    font-style: normal;
}

@mixin ff--base-medium-italic($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--medium;
    font-style: italic;
}

@mixin ff--base-regular($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--regular;
    font-style: normal;
}

@mixin ff--base-regular-italic($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--regular;
    font-style: italic;
}

@mixin ff--base-bold($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--bold;
    font-style: normal;
}

@mixin ff--base-bold-italic($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--bold;
    font-style: italic;
}

@mixin ff--base-quotes($ff-definition: $ff--serif-stack) {
    font-family: $ff-definition;
    font-weight: $fw--regular;
    font-style: italic;
}
