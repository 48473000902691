.multi-logo {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            align-items: center;
        }

        .multi-logo--image {
            width: 100%;
            max-width: 220px;

            @include mq('<=lg') {
                max-width: 200px;
            }
        }
    }

    // Facets

    // States
}
