$spacings-by-mq: (
    '<=lg': (
        // notice how on small devices these values are downscaled
        'xx-large': map-get($vertical-spacings, 'x-large'),
        'x-large': map-get($vertical-spacings, 'large'),
        'large': map-get($vertical-spacings, 'medium'),
        'medium': map-get($vertical-spacings, 'small'),
        'small': map-get($vertical-spacings, 'x-small'),
        'x-small': map-get($vertical-spacings, 'xx-small'),
        'xx-small': map-get($vertical-spacings, 'xx-small'),
        'xxx-small': map-get($vertical-spacings, 'xxx-small'),
    ),
    '>lg': (
        'xx-large': map-get($vertical-spacings, 'xx-large'),
        'x-large': map-get($vertical-spacings, 'x-large'),
        'large': map-get($vertical-spacings, 'large'),
        'medium': map-get($vertical-spacings, 'medium'),
        'small': map-get($vertical-spacings, 'small'),
        'x-small': map-get($vertical-spacings, 'x-small'),
        'xx-small': map-get($vertical-spacings, 'xxx-small'),
        'xxx-small': map-get($vertical-spacings, 'xxx-small'),
    ),
);

/**
 * Mixin allowing a responsive use of the vertical spacings
 *
 * @example
 * @include responsive-vertical-spacing('medium') using ($spacing) {
 *     margin-top: $spacing;
 * }
 */
@mixin responsive-vertical-spacing($vertical-spacing) {
    @each $mq, $spacings in $spacings-by-mq {
        @include mq($mq) {
            @content(map-get($spacings, $vertical-spacing));
        }
    }
}
