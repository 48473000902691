.publication-detail--publication-info-container {
    display: flex;

    .publication-detail--publication-info {
        position: relative;

        @include type--paragraph02;

        &:first-child::after {
            content: '•';
            position: relative;
            margin-left: 5px;
            color: $c--themes-brand;
        }

        &:last-child {
            margin-left: 10px;
        }
    }
}

.publication-detail--author-box {
    display: flex;
    align-items: center;
    border: solid 1px $c--monochrome-gray;
    border-left: none;
    border-right: none;
    padding: 32px 0;
    margin: 20px 0 0;

    @include mq('>md') {
        flex-wrap: nowrap;
    }

    @include mq('<=md') {
        flex-direction: column;
        flex-wrap: wrap;
    }


    & {
        .publication-detail--author-box-image {
            border-radius: 100%;

            @include mq('<=md') {
                align-self: flex-start;
            }

            @supports (object-fit: cover) {
                object-fit: cover;

                @include mq('>xs') {
                    width: 172px;
                    height: 172px;
                }

                @include mq('<=xs') {
                    width: 124px;
                    height: 124px;
                }
            }
        }

        .publication-detail--author-box-container {
            @include mq('>md') {
                margin-left: 20px;
            }

            @include mq('<=md') {
                margin-top: 32px;
            }

            & {
                .publication-detail--author-box-author {
                    @include type--h4(false);
                    color: $c--monochrome-black;
                }

                .publication-detail--author-box-author-description {
                    @include type--paragraph02;
                    color: $c--monochrome-med-gray;
                }
            }
        }
    }
}

.publication-detail--sub-navigation {
    // Vars
    $arrow-size: 8px;

    // Support
    display: flex;
    margin-top: 30px;

    // Module
    & {
        .publication-detail--exit-arrow-label {
            color: $c--themes-brand;
            font-weight: 500;
        }

        .publication-detail--exit-arrow-wrapper {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            height: 20px;
            margin-bottom: 4px;
        }

        .publication-detail--exit-arrow {
            @include animated-arrow-reverse($arrow-size, 4);
            color: $c--themes-brand;
        }
    }

    // Facets

    // States
    @include has-focus {
        .publication-detail--exit-arrow {
            @include animated-arrow-reverse-hover(15px);
            color: $c--themes-brand-dark;
        }

        .publication-detail--exit-arrow-label {
            color: $c--themes-brand-dark;
        }
    }
}
