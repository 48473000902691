.anchor-placeholder {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
        }

        .anchor-placeholder--title {
            @include type--text-label;

            @include style-all-root-themes using($color) {
                color: $color;
            }
        }


        .anchor-placeholder--hook {
            position: absolute;
            left: 0;

            @include responsive-vertical-spacing('large') using($spacing) {
                top: ($spacing + $anchor-navigation-height) * -1;
            }
        }
    }

    // Facets
    & {
        @each $name, $palette in $themes {
            &.anchor-placeholder__#{$name} {
                .anchor-placeholder--title {
                    color: map-get($palette, 'default');
                }
            }
        }
    }

    // States
}
