.wizard-radio-tags {
    $sz--form-fieldset-gutter: (80px 24px);
    $sz--form-fieldset-gutter-small: (40px 24px);

    @include mq('>lg') {
        padding-top: 40px;
    }

    @include mq('<=lg') {
        padding-top: 20px;
    }

    &.wizard-radio-tags__no-spacing {
        padding-top: 0;
    }

    &.wizard-radio-tags__bottom-spacing {
        padding-bottom: 16px;
    }
}

.wizard-radio-tags__messages {
    display: flex;
    justify-content: flex-end;

    @include responsive-vertical-spacing('x-small') using($spacing) {
        margin-bottom: $spacing;
    }
}
