.info-events {
    // Vars
    $arrow-size: 12px;
    $arrow-width: 93px;

    // Support

    // Module
    & {
        .info-events--school-name {
            @include type--text-label;
        }

        .info-events--item:not(:first-child) {
            @include responsive-vertical-spacing('medium') using($spacing) {
                margin-top: $spacing;
            }
        }

        .info-events--link {
            display: block;
            padding: 24px;

            @include mq('>lg') {
                padding: 32px;
            }

            @include has-focus {
                color: $c--monochrome-white;

                .info-events--heading {
                    color: $c--monochrome-white;
                }


                .info-events--bullet-list {
                    color: $c--monochrome-light-gray;
                }

                .info-events--arrow {
                    @include animated-arrow-hover;
                }

                .info-events--wrapper-item {
                    color: $c--monochrome-white;
                }
            }
        }

        .info-events--wrapper {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            color: $c--monochrome-black;

            .info-events--wrapper-item {
                display: flex;
                align-items: center;

                @include responsive-vertical-spacing('xxx-small') using($spacing) {
                    margin-bottom: $spacing;
                }

                @include mq('>md') {
                    padding: 0 8px;

                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            .info-events--date {
                min-width: 208px;
            }

            .info-events--time {
                min-width: 88px;
            }

            @include responsive-vertical-spacing('xxx-small') using($spacing) {
                .info-events--time-icon, .info-events--date-icon {
                    margin-right: $spacing;
                }
            }

            @include mq('>md') {
                align-items: center;
                flex-flow: row;

                .info-events--date-wrapper {
                    margin-bottom: 0;
                }
            }
        }

        .info-events--bullet-list {
            color: $c--monochrome-dark-gray;

            @include responsive-vertical-spacing('medium') using($spacing) {
                margin-top: $spacing;
            }

            .info-events--bullet-item {
                position: relative;
                display: flex;
                align-items: center;
                padding-bottom: 8px;
                padding-left: 2rem;

                &::before {
                    content: ' ';
                    position: absolute;
                    left: 0;
                    top: 6px;
                    display: inline-block;
                    margin-right: 12px;
                    border-radius: 50%;

                    @include size(8px);
                }
            }
        }

        .info-events--footer {
            display: flex;
            justify-content: flex-end;

            @include responsive-vertical-spacing('large') using($spacing) {
                margin-top: $spacing;
            }
        }

        .info-events--arrow-wrapper {
            display: flex;
            align-items: center;
            width: $arrow-width;
        }

        .info-events--arrow {
            @include animated-arrow($arrow-size, 4);
        }
    }

    // Facets
    & {
        @include style-all-root-themes using($color) {
            color: $color;

            .info-events--school-name {
                color: $color;
            }

            .info-events--link {
                &.info-events--link {
                    background: $c--monochrome-white;

                    @include has-focus {
                        background: $color;
                    }
                }

                .info-events--bullet-item {
                    &::before {
                        background-color: $color;
                    }
                }

                .info-events--footer {
                    color: $color;
                }

                @include has-focus {
                    .info-events--footer {
                        color: $c--monochrome-white;
                    }

                    .info-events--date-time-wrapper {
                        color: $c--monochrome-white;
                    }
                }

                @include has-focus {
                    .info-events--bullet-item {
                        &::before {
                            background-color: $c--monochrome-white;
                        }
                    }
                }
            }
        }
    }

    // States
}
