.start-dates-entry {
    // Vars

    // Support

    // Module
    & {
        .start-dates-entry--list {
            padding-left: 0;
        }

        .start-dates-entry--title {
            @include type--h4;
        }
    }

    // Facets

    // States
}
