/// Field styles
///
@mixin ui-form-field--primary-file($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--border-width) {
    .ui-form-field--input-wrapper {
        position: relative;
    }

    .ui-form-field--empty-label {
        @include is-visible(false, $trs--base-speed);
        pointer-events: none;
        z-index: 1;
        position: relative;
        padding: $sz--padding-top $sz--padding-x $sz--padding-bottom;
        color: $c--monochrome-med-gray;
        text-align: center;
    }

    .ui-form-field--empty-btn {
        @include is-visible(false, $trs--base-speed);
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 20px;
        padding: 12px;
        transform: translate(0, -50%);
        background-color: $c--monochrome-white;
        cursor: pointer;
    }

    .ui-form-field--input {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: ($sz--padding-top * 1.75) $sz--padding-x ($sz--padding-bottom * 1.75) 80px;
        cursor: pointer;

        // stylelint-disable-next-line selector-pseudo-element-no-unknown
        &::file-selector-button,
        &::-webkit-file-upload-button {
            appearance: none;
            visibility: hidden;
            width: 0;
            padding: 0;
            border: none;
            user-select: none;
        }
    }

    &.ui-form-field__is-filled {
        .ui-form-field--empty-btn {
            @include is-visible(true, $trs--base-speed);
        }

        .ui-form-field--input {
            border-style: solid;
        }
    }

    &.ui-form-field__is-empty {
        .ui-form-field--empty-label {
            @include is-visible(true, $trs--base-speed);
        }

        .ui-form-field--input {
            color: transparent;
        }
    }

    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--primary-file-input-base;
        }

        // Focus
        @include ui-form-field--apply-states(('disabled': false, 'focus': true)) {
            @include ui-form-field--primary-file-input-focus;
        }

        // Disabled
        @include ui-form-field--apply-states(('disabled': true, 'focus': default)) {
            @include ui-form-field--primary-file-input-disabled;
        }

        // Error
        @include ui-form-field--apply-states(('error': true, 'focus': default)) {
            @include ui-form-field--primary-file-input-error;
        }
    }
}

/// Base
///
@mixin ui-form-field--primary-file-input-base {
    .ui-form-field--input {
        color: $c--label-text;
        background-color: $c--monochrome-light-gray;
        border-color: $c--monochrome-med-gray;
        border-style: dashed;

        @include placeholder {
            color: $c--monochrome-light-gray;
        }
    }
}

/// Focus
///
@mixin ui-form-field--primary-file-input-focus {
    .ui-form-field--title-label {
        color: $c--monochrome-black;
    }

    .ui-form-field--empty-label {
        color: $c--monochrome-black;
    }

    .ui-form-field--input {
        border-color: $c--monochrome-black;
        border-style: solid;
    }
}

/// Disabled
///
@mixin ui-form-field--primary-file-input-disabled {
    .ui-form-field--title-label {
        @include is-selectable(false);
        cursor: default;
    }

    .ui-form-field--input {
        @include is-selectable(false);
        color: $c--monochrome-dark-silver;
        background-color: $c--monochrome-gray;
        border-color: $c--monochrome-light-gray;
        border-style: solid;
        cursor: default;

        @include placeholder {
            color: transparent;
        }
    }
}

/// Error
///
@mixin ui-form-field--primary-file-input-error {
    .ui-form-field--input {
        border-color: $c--signal-error;
        border-style: solid;
    }

    .ui-form-field--title-label {
        color: $c--signal-error;
    }
}
