$sz--list-icon-width: 8px;
$sz--list-icon-gutter: 8px;
$sz--list-vertical-spacing: 8px;

$sz--border-radius-base: 0;
$sz--border-width-base: 2px;
$anchor-navigation-height: 30px;

$main-header-height: 88px;

$schools-teaser-padding-size-medium: 32px;
$schools-teaser-padding-size-big: 36px;

// Grid spacings
$grid--base-container-sizes: (
    (mq: 'xs', container-size: none),
    (mq: 'sm', container-size: 512px),
    (mq: 'md', container-size: $screen-md-min),
    (mq: 'lg', container-size: $screen-lg-min),
    (mq: 'xl', container-size: $screen-xl-min),
    (mq: 'xxl', container-size: $screen-xxl-min),
);
$grid--base-spacing: (
    (mq: 'xs', gutter: 16px, edge: 24px),
    (mq: 'sm', gutter: 16px, edge: 24px),
    (mq: 'md', gutter: 20px, edge: 34px),
    (mq: 'lg', gutter: 24px, edge: 30px),
    (mq: 'xl', gutter: 32px, edge: 36px),
    (mq: 'xxl', gutter: 40px, edge: 40px),
);

$grid--wide-container-sizes: $grid--base-container-sizes;
$grid--wide-spacing: (
    (mq: 'xs', gutter: 16px, edge: 16px),
    (mq: 'sm', gutter: 16px, edge: 18px),
    (mq: 'md', gutter: 20px, edge: 22px),
    (mq: 'lg', gutter: 24px, edge: 24px),
    (mq: 'xl', gutter: 32px, edge: 24px),
    (mq: 'xxl', gutter: 40px, edge: 28px),
);

// Spacing variables
// These spacings are defined on figma in the styleguide and here are semantically grouped
$vertical-spacings: (
    'xx-large': 120px,
    'x-large': 80px,
    'large': 40px,
    'medium': 32px,
    'small': 24px,
    'x-small': 16px,
    'xx-small': 12px,
    'xxx-small': 8px,
);
