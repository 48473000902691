/// Primary
///
@mixin btn--primary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--primary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--primary-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': true, 'active': true)) {
            @include btn--primary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true)) {
            @include btn--primary-disabled;
        }
    }
}

/// Base
///
@mixin btn--primary-base {
    color: $c--monochrome-white;
    border-color: transparent;

    @include style-all-root-themes using($color) {
        background-color: $color;
    }
}

/// Focus
///
@mixin btn--primary-focus {
    @include style-all-root-themes('dark') using($color) {
        background-color: $color;
    }
}

/// Active
///
@mixin btn--primary-active {
    //
}

/// Disabled
///
@mixin btn--primary-disabled {
    @include style-all-root-themes('dark') using($color) {
        color: $c--monochrome-dark-silver;
        background-color: $c--monochrome-gray;
    }
}
