.article-detail {
    // Vars

    // Support
    display: flex;
    flex-direction: column;
    width: 100%;

    // Module
    & {
        & {
            // item grid
            @include grid--root((row: 'list', cols: 'tile'));

            @each $value in $grid--base-spacing {
                $gutter: map-get($value, 'gutter');
                $mq: map-get($value, 'mq');

                @include grid--space((gutter: ($gutter $gutter), row: 'list', cols: 'tile', mq: $mq));
            }
        }

        .article-detail--two-cols {
            width: 66%;

            @media (max-width: 1024px) {
                width: 100%;
            }
        }

        .article-detail--title {
            @include type--h1;
        }

        .article-detail--lead {
            color: $c--monochrome-dark-gray;
        }

        .article-detail--info {
            display: flex;
            align-items: center;
            padding: 18px 0 40px;

            .article-detail--article-info {
                display: flex;

                .article-detail--article-author {
                    margin-right: 8px;
                    color: $c--monochrome-med-black;

                    &::after {
                        content: '•';
                        margin: 0 5px;
                        color: $c--themes-brand;
                    }
                }

                .article-detail--article-published {
                    color: $c--monochrome-med-black;
                }
            }

            .article-detail--article-tag {
                padding: 4px 12px;
                border-radius: 16px;
                transition: background color $trs--base;
                color: $c--monochrome-white;
                background: $c--themes-brand;
                margin-right: 30px;

                @include type--paragraph02;
            }
        }

        .article-detail--image-container {
            width: 100%;

            @media (max-width: 1024px) {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 100vw;
                max-width: none;
                overflow-x: hidden;
            }
        }

        .article-detail--image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .article-detail--image-credits {
            margin-top: 10px;
            font-size: 12px;
            color: $c--monochrome-med-gray;
        }

        .article-detail--richtext {
            margin-top: 65px;

            /* stylelint-disable plugin/selector-bem-pattern */
            & {
                h1,
                h2,
                h3 {
                    margin-top: 65px;
                }

                .image {
                    margin: 65px 0;
                }
            }
            /* stylelint-enable plugin/selector-bem-pattern */
        }

        .article-detail--related-articles {
            margin-top: 80px;
        }
    }

    // Facets

    // States
}

.article-detail--sub-navigation {
    // Vars
    $arrow-size: 8px;

    // Support
    display: flex;
    margin-top: 30px;

    // Module
    & {
        .article-detail--exit-arrow-label {
            color: $c--themes-brand;
            font-weight: 500;
        }

        .article-detail--exit-arrow-wrapper {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            height: 20px;
            margin-bottom: 4px;
        }

        .article-detail--exit-arrow {
            @include animated-arrow-reverse($arrow-size, 4);
            color: $c--themes-brand;
        }
    }

    // Facets

    // States
    @include has-focus {
        .article-detail--exit-arrow {
            @include animated-arrow-reverse-hover(15px);
            color: $c--themes-brand-dark;
        }

        .article-detail--exit-arrow-label {
            color: $c--themes-brand-dark;
        }
    }
}

